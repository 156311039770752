import { reviewFormValidator } from "@redotech/redo-model/form-builder-form/db-validator/review/review-form-builder-form-validator";
import { z } from "zod";

export const getActiveReviewFormForTeam = {
  input: z.null(),
  output: reviewFormValidator
    .extend({
      formId: z.string(),
    })
    .nullable(),
};
