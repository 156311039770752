import { CarrierAccount } from "@redotech/redo-model/carrier-account/carrier-account";
import { CreateCarrierAccount } from "@redotech/redo-model/carrier-account/create-carrier-account";
import { z } from "zod";

export const createCarrierAccount = {
  input: CreateCarrierAccount,
  output: z.object({
    carrierAccounts: z.array(CarrierAccount),
  }),
};
