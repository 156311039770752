import { getGravatarUrlFromEmail } from "@redotech/redo-web/gravatar-utils";
import { AI_AUTOPILOT_USER_ID, AI_AVATAR_URL } from "../config";

type GetUserAvatarUrlParams = {
  email: string;
  size?: number;
  defaultImage?: "404" | "identicon";
  userId?: string;
};

export const getUserAvatarUrl = ({
  email,
  size = 32,
  defaultImage = "404",
  userId,
}: GetUserAvatarUrlParams) => {
  if (userId === AI_AUTOPILOT_USER_ID && AI_AVATAR_URL) {
    return AI_AVATAR_URL;
  }

  return getGravatarUrlFromEmail(email, size, defaultImage);
};
