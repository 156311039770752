// extracted by mini-css-extract-plugin
export var createdDate = "_8q-1";
export var divider = "_8q-2";
export var headerDivider = "_8q-3";
export var messagesCard = "_8q-0";
export var messagesContainer = "_8q-7";
export var messagesContainerWrapper = "_8q-4";
export var pointerCapturingButtonBoxes = "_8q-a";
export var showLeftPanelButton = "_8q-9";
export var showMoreButton = "_8q-c";
export var showMoreButtonContainer = "_8q-b";
export var summaryTitle = "_8q-6";
export var systemMessageCardWrapper = "_8q-d";
export var topButtons = "_8q-8";
export var withPadding = "_8q-5";