// extracted by mini-css-extract-plugin
export var border = "_3e-5";
export var button = "_3e-g";
export var center = "_3e-7";
export var centered = "_3e-3";
export var closeButton = "_3e-9";
export var disabledWrapper = "_3e-h";
export var error = "_3e-k";
export var fill = "_3e-d";
export var footer = "_3e-c";
export var footerAction = "_3e-e";
export var footerButtons = "_3e-f";
export var header = "_3e-4";
export var icon = "_3e-j";
export var info = "_3e-n";
export var inline = "_3e-6";
export var large = "_3e-2";
export var main = "_3e-a";
export var modal = "_3e-0";
export var none = "_3e-o";
export var scrollable = "_3e-b";
export var small = "_3e-1";
export var success = "_3e-m";
export var tight = "_3e-i";
export var titleIcon = "_3e-8";
export var warn = "_3e-l";