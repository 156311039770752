import { useRequiredContext } from "@redotech/react-util/context";
import { Order } from "@redotech/redo-model/order";
import { ReturnType } from "@redotech/redo-model/return";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import { CopyableText } from "@redotech/redo-web/copyable-text";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { getDateString } from "@redotech/redo-web/date-utils";
import { ExternalLinkButton } from "@redotech/redo-web/external-link-button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo, useState } from "react";
import { TeamContext } from "../../../app/team";
import { UserContext } from "../../../app/user";
import { orderExternalUrl } from "../../../return/return-detail-cards/details-card";
import { ProductDetailCard } from "../../product-detail-card";
import { getOrderOrReturnDetailName } from "../naming-utils";
import { OrderDetailsOrderActions } from "./order-details-order-actions";
import { OrderDetailsShippingMethod } from "./order-details-shipping-method";
import { OrderDetailsTrackingInformation } from "./order-details-tracking-information";
import * as orderDetailsCss from "./order-details.module.css";

export const OrderDetails = memo(function OrderDetails({
  order,
  setCancelOrderModalOpen,
  setCreateClaimModalOpen,
  setCreateType,
  setDuplicateOrderModalOpen,
  setEditOrderModalOpen,
  setOrderNeedingAction,
  setRefundOrderModalOpen,
}: {
  order: Order;
  setCancelOrderModalOpen: (open: boolean) => void;
  setCreateClaimModalOpen: (open: boolean) => void;
  setCreateType: (type: ReturnType) => void;
  setDuplicateOrderModalOpen: (open: boolean) => void;
  setEditOrderModalOpen: (open: boolean) => void;
  setOrderNeedingAction: (order: Order) => void;
  setRefundOrderModalOpen: (open: boolean) => void;
}) {
  const team = useRequiredContext(TeamContext);
  const user = useRequiredContext(UserContext);
  const fulfillments = filteredFulfillments(order);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const canManageOrder = useMemo(() => {
    if (order.provider === "commentsold") {
      return false;
    }
    return permitted(user.permissions, Permission.MANAGE_ORDER);
  }, [user.permissions, order.provider]);

  const getBadgeColorFromShipmentStatus = (shipmentStatus: string) => {
    return shipmentStatus === "delivered" || shipmentStatus === "fulfilled"
      ? RedoBadgeColor.SUCCESS
      : shipmentStatus === "shipped"
        ? RedoBadgeColor.BLUE_LIGHT
        : shipmentStatus === "in_transit"
          ? RedoBadgeColor.BLUE_LIGHT
          : RedoBadgeColor.WARNING;
  };

  return (
    <Flex
      className={orderDetailsCss.orderCard}
      dir="column"
      pb="md"
      pl="md"
      pr="md"
      pt="xs"
    >
      <Flex align="stretch" dir="column">
        <Flex align="center" justify="space-between">
          <Flex align="center" gap="xxs" justify="flex-start">
            <CopyableText
              altTextToCopy={
                order.shopify.name[0] === "#"
                  ? order.shopify.name.substring(1)
                  : order.shopify.name
              }
              fontSize="xs"
              fontWeight="medium"
            >
              {getOrderOrReturnDetailName({
                detailType: "Order",
                name: order.shopify.name,
              })}
            </CopyableText>
            <ExternalLinkButton url={orderExternalUrl(team, order) || ""} />
          </Flex>

          <Flex gap="xxs">
            {canManageOrder && (
              <OrderDetailsOrderActions
                order={order}
                setCancelOrderModalOpen={setCancelOrderModalOpen}
                setCreateClaimModalOpen={setCreateClaimModalOpen}
                setCreateType={setCreateType}
                setDuplicateOrderModalOpen={setDuplicateOrderModalOpen}
                setEditOrderModalOpen={setEditOrderModalOpen}
                setOrderNeedingAction={setOrderNeedingAction}
                setRefundOrderModalOpen={setRefundOrderModalOpen}
              />
            )}

            <RedoButton
              className={orderDetailsCss.orderCollapseButton}
              hierarchy={RedoButtonHierarchy.TERTIARY}
              IconLeading={() =>
                !isCollapsed ? <ChevronUpSvg /> : <ChevronDownSvg />
              }
              onClick={() => setIsCollapsed(!isCollapsed)}
              size={RedoButtonSize.EXTRA_SMALL}
            />
          </Flex>
        </Flex>
        <Flex wrap="nowrap">
          <div className={orderDetailsCss.orderPillsContainer}>
            {order.shopify.financial_status && (
              <div className={orderDetailsCss.pillWrapper}>
                <RedoBadge
                  color={
                    order.shopify.financial_status === "paid"
                      ? RedoBadgeColor.SUCCESS
                      : RedoBadgeColor.ERROR
                  }
                  size={RedoBadgeSize.SMALL}
                  text={formatSentenceCase(order.shopify.financial_status)}
                />
              </div>
            )}
            <div className={orderDetailsCss.pillWrapper}>
              <RedoBadge
                color={
                  order.shopify?.fulfillment_status === "fulfilled"
                    ? RedoBadgeColor.SUCCESS
                    : RedoBadgeColor.WARNING
                }
                size={RedoBadgeSize.SMALL}
                text={
                  order.shopify?.fulfillment_status
                    ? formatSentenceCase(order.shopify.fulfillment_status)
                    : "Unfulfilled"
                }
              />
            </div>
            {!!order.shopify?.cancelled_at &&
              order.shopify.cancelled_at !== null && (
                <div className={orderDetailsCss.pillWrapper}>
                  <RedoBadge
                    color={RedoBadgeColor.ERROR}
                    size={RedoBadgeSize.SMALL}
                    text="Canceled"
                  />
                </div>
              )}
            {order.protected && (
              <div className={orderDetailsCss.pillWrapper}>
                <RedoBadge
                  color={RedoBadgeColor.BLUE_LIGHT}
                  size={RedoBadgeSize.SMALL}
                  text="Returns Coverage"
                />
              </div>
            )}
            {order.packageProtected && (
              <div className={orderDetailsCss.pillWrapper}>
                <RedoBadge
                  color={RedoBadgeColor.BLUE_LIGHT}
                  size={RedoBadgeSize.SMALL}
                  text="Package Protection"
                />
              </div>
            )}
          </div>
        </Flex>
        <Flex
          dir={isCollapsed ? "row" : "column"}
          gap={isCollapsed ? undefined : "md"}
          wrap="nowrap"
        >
          <Flex align="stretch" dir="column" grow="1">
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Ordered on
            </Text>
            <CopyableText fontSize="xs" fontWeight="medium" textColor="primary">
              {getDateString(new Date(order.shopifyCreatedAt))}
            </CopyableText>
          </Flex>
          <Flex align="stretch" dir="column" grow="1">
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Total
            </Text>
            <CopyableText fontSize="xs" fontWeight="medium" textColor="primary">
              {CURRENCY_FORMAT().format(
                order.shopify.total_price_usd || order.shopify.total_price,
              )}
            </CopyableText>
          </Flex>
        </Flex>
        {!isCollapsed && (
          <div
            className={orderDetailsCss.orderDetails}
            id={`order-${order.shopify_id}-details`}
          >
            <OrderDetailsShippingMethod order={order} />
            {fulfillments.length > 0 && (
              <div className={orderDetailsCss.fulfillments}>
                {fulfillments.map((fulfillment, index) => {
                  return (
                    <Flex
                      className={orderDetailsCss.fulfillmentDetails}
                      dir="column"
                      gap="md"
                      key={fulfillment.id}
                    >
                      {fulfillments.length > 1 ? (
                        <Text
                          fontSize="xs"
                          fontWeight="medium"
                          textColor="primary"
                        >
                          Package {index + 1}
                        </Text>
                      ) : (
                        <Text
                          fontSize="xs"
                          fontWeight="medium"
                          textColor="primary"
                        >
                          {`Package ${index}`}
                        </Text>
                      )}
                      <Flex dir="column" gap="xxs">
                        {fulfillment.shipment_status && (
                          <div className={orderDetailsCss.pillWrapper}>
                            <Flex>
                              <RedoBadge
                                color={getBadgeColorFromShipmentStatus(
                                  fulfillment.shipment_status,
                                )}
                                size={RedoBadgeSize.SMALL}
                                text={formatSentenceCase(
                                  fulfillment.shipment_status,
                                )}
                              />
                            </Flex>
                          </div>
                        )}
                        <OrderDetailsTrackingInformation
                          fulfillment={fulfillment}
                          index={index}
                          order={order}
                        />
                      </Flex>
                      <div className={orderDetailsCss.detailColumn}>
                        <Text
                          fontSize="xs"
                          fontWeight="medium"
                          textColor="tertiary"
                        >
                          Shipping address:
                        </Text>
                        <Flex dir="column" gap="none">
                          {order.shopify?.shipping_address
                            ? formatShippingAddress(
                                order.shopify.shipping_address,
                              )
                            : "None"}
                        </Flex>
                      </div>
                    </Flex>
                  );
                })}
              </div>
            )}
            <div className={orderDetailsCss.lineItems}>
              <div className={orderDetailsCss.products}>
                {order.shopify.line_items.map((lineItem) => {
                  return (
                    <div className={orderDetailsCss.product} key={lineItem.id}>
                      <ProductDetailCard
                        imageName={lineItem.name}
                        imageSrc={lineItem.image?.src || ""}
                        item={lineItem.name}
                        price={CURRENCY_FORMAT(
                          order.shopify.presentment_currency,
                        ).format(Number(lineItem.price))}
                        quantity={lineItem.quantity}
                        variant={lineItem.variant_title || ""}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Flex>
    </Flex>
  );
});

function formatSentenceCase(str: string) {
  return str[0].toUpperCase() + str.slice(1).replace(/_/g, " ");
}

function filteredFulfillments(order: Order) {
  const fulfillmentsWithFilteredLineItems = order.shopify.fulfillments.filter(
    (fulfillment) => {
      return {
        ...fulfillment,
        line_items: fulfillment.line_items.filter(
          (line_item) =>
            line_item.vendor?.toLowerCase() != "re:do" &&
            line_item.vendor?.toLowerCase() != "redo",
        ),
      };
    },
  );
  return fulfillmentsWithFilteredLineItems.filter((fulfillment) => {
    return fulfillment.line_items.length > 0;
  });
}

function formatShippingAddress(shippingLines: Record<string, any>) {
  const joinIf = (parts: string[], joiner: string) =>
    parts.filter(Boolean).join(joiner);

  const lastLine = joinIf(
    [
      joinIf(
        [
          joinIf([shippingLines.city, shippingLines.province_code], ", "),
          shippingLines.zip,
        ],
        " ",
      ),
      shippingLines.country_code,
    ],
    ", ",
  );
  const wholeAddress = joinIf(
    [shippingLines.address1, shippingLines.address2, lastLine],
    ", ",
  );

  return (
    <>
      <CopyableText
        altTextToCopy={wholeAddress}
        fontSize="xs"
        fontWeight="medium"
        textColor="primary"
      >
        {`${shippingLines.address1}`}
      </CopyableText>
      {shippingLines.address2 && (
        <CopyableText
          altTextToCopy={shippingLines.address2}
          fontSize="xs"
          fontWeight="medium"
          textColor="primary"
        >
          {`${shippingLines.address2}`}
        </CopyableText>
      )}
      <CopyableText
        altTextToCopy={wholeAddress}
        fontSize="xs"
        fontWeight="medium"
        textColor="primary"
      >
        {`${lastLine}`}
      </CopyableText>
    </>
  );
}
