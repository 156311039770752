// extracted by mini-css-extract-plugin
export var addDetails = "_16-l";
export var addEditButton = "_16-b";
export var addressFields = "_16-2";
export var animationContainer = "_16-0";
export var closeButton = "_16-p";
export var customerDetails = "_16-x";
export var customerInformationWrapper = "_16-6";
export var dropdowns = "_16-4";
export var error = "_16-k";
export var fields = "_16-n";
export var flex = "_16-m";
export var grow = "_16-3";
export var icon = "_16-d";
export var imageButton = "_16-q";
export var imagesWrapper = "_16-s";
export var itemDetails = "_16-i";
export var itemSelectionHeader = "_16-9";
export var label = "_16-w";
export var lineItemImage = "_16-j";
export var lineItemReturnedWrapper = "_16-h";
export var lineItemWrapper = "_16-g";
export var modalContent = "_16-f";
export var modalFooter = "_16-1";
export var multipleChoiceAnswer = "_16-u";
export var multipleChoiceAnswersWrapper = "_16-t";
export var orderButton = "_16-z";
export var orderLabel = "_16-10";
export var orderSelection = "_16-y";
export var question = "_16-v";
export var search = "_16-e";
export var selectCheckbox = "_16-a";
export var selectionWrapper = "_16-8";
export var spinner = "_16-c";
export var subheader = "_16-5";
export var title = "_16-7";
export var uploadedImage = "_16-o";
export var uploadedImageWrapper = "_16-r";