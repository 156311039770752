import { Team } from "../team";
import { SchemaType } from "./schemas/schemas";

export const categories = [
  "Order tracking",
  "Return tracking",
  "Marketing",
  "Reviews",
] as const;
export type Category = (typeof categories)[number];
export function categoryToSnakeCase(category: Category) {
  return category.toLowerCase().replace(" ", "_");
}
export function snakeCaseToCategory(snakeCase: string): Category {
  const lowercase = snakeCase.replace("_", " ");
  return (lowercase.charAt(0).toUpperCase() + lowercase.slice(1)) as Category;
}

export enum OrderTrackingTrigger {
  ORDER_CREATED = "order_created",
  ORDER_FULFILLED = "order_fulfilled",
  ORDER_PRE_TRANSIT = "order_pre_transit",
  ORDER_IN_TRANSIT = "order_in_transit",
  ORDER_OUT_FOR_DELIVERY = "order_out_for_delivery",
  ORDER_DELIVERED = "order_delivered",
  ORDER_AVAILABLE_FOR_PICKUP = "order_available_for_pickup",
  ORDER_STALLED_IN_TRANSIT = "order_stalled_in_transit",
  ORDER_STALLED_IN_FULFILLMENT = "order_stalled_in_fulfillment",
  ORDER_DELAYED = "order_delayed",
  ORDER_ARRIVING_EARLY = "order_arriving_early",
  ORDER_RETURN_TO_SENDER = "order_return_to_sender",
  ORDER_DELIVERY_ATTEMPTED = "order_delivery_attempted",
  ORDER_DELIVERY_FAILURE = "order_delivery_failure",
  ORDER_SHIPMENT_CANCELLED = "order_shipment_cancelled",
  ORDER_SHIPMENT_ERROR = "order_shipment_error",
}

export enum ReturnTrackingTrigger {
  // easypost
  RETURN_CREATED = "return_created",
  RETURN_PRE_TRANSIT = "return_pre_transit",
  RETURN_IN_TRANSIT = "return_in_transit",
  RETURN_OUT_FOR_DELIVERY = "return_out_for_delivery",
  RETURN_DELIVERED = "return_delivered",
  RETURN_AVAILABLE_FOR_PICKUP = "return_available_for_pickup",
  RETURN_STALLED = "return_stalled",
  RETURN_DELAYED = "return_delayed",
  RETURN_ARRIVING_EARLY = "return_arriving_early",
  RETURN_RETURN_TO_SENDER = "return_return_to_sender",
  RETURN_DELIVERY_ATTEMPTED = "return_delivery_attempted",
  RETURN_DELIVERY_FAILURE = "return_delivery_failure",
  RETURN_SHIPMENT_CANCELLED = "return_shipment_cancelled",
  RETURN_SHIPMENT_ERROR = "return_shipment_error",
  RETURN_IN_REVIEW = "return_in_review",

  // redo
  // RETURN_RECEIVED = "return_received", // requirements unclear
  RETURN_REJECTED = "return_rejected",
  // RETURN_ACCEPTED = "return_accepted", // requirements unclear
  // RETURN_EXCHANGE_ORDER_READY = "return_exchange_order_ready", // requirements unclear

  RETURN_PICKUP_REMINDER = "return_pickup_reminder",
  RETURN_PICKUP_SUCCESS = "return_pickup_success",
  RETURN_PICKUP_FAILURE = "return_pickup_failure",
  RETURN_OUSTANDING_BALANCE = "return_outstanding_balance",
  RETURN_EXCHANGE_OUT_OF_STOCK = "return_exchange_out_of_stock",
}

export enum MarketingTrigger {
  EMAIL_SIGNUP = "email_signup",
  SMS_SIGNUP = "sms_signup",
  SMS_CONFIRMED = "sms_confirmed",
  MARKETING_CAMPAIGN = "marketing_campaign",
  CART_ABANDONED = "cart_abandoned",
  BROWSE_ABANDONED = "browse_abandoned",
  CHECKOUT_ABANDONED = "checkout_abandoned",
}

export enum ReviewsTrigger {
  REVIEW_SUBMITTED = "review_submitted",
}

export type TriggerKey =
  | OrderTrackingTrigger
  | ReturnTrackingTrigger
  | MarketingTrigger
  | ReviewsTrigger;

export interface Trigger {
  category: Category;
  key: TriggerKey;
  displayName: string;
  schemaType: SchemaType;
}

/** Unique set of each allowed combination of category and schema type
 * Currently just orderTracking and returnTracking with their respective schema types,
 * but will need to be expanded with return events
 */
export function categorySchemaPermutations(settings: Team["settings"]) {
  const permutations: {
    category: Category;
    schemaType: SchemaType;
    displayName: string;
  }[] = [];
  if (settings.orderTracking?.enabled) {
    permutations.push({
      category: "Order tracking",
      schemaType: SchemaType.ORDER_TRACKING,
      displayName: "Order tracking",
    });
  }
  if (settings.returnTracking?.enabled) {
    permutations.push({
      category: "Return tracking",
      schemaType: SchemaType.RETURN_TRACKING,
      displayName: "Return tracking",
    });
  }
  if (settings.marketing?.enabled) {
    permutations.push({
      category: "Marketing",
      schemaType: SchemaType.EMAIL_MARKETING_SIGNUP,
      displayName: "Email marketing sign-up",
    });
    permutations.push({
      category: "Marketing",
      schemaType: SchemaType.SMS_MARKETING_SIGNUP,
      displayName: "SMS marketing sign-up",
    });
    permutations.push({
      category: "Marketing",
      schemaType: SchemaType.SMS_MARKETING_CONFIRMED,
      displayName: "SMS marketing confirmed",
    });
    if (settings.shopifyPixelId) {
      permutations.push({
        category: "Marketing",
        schemaType: SchemaType.MARKETING_CART_ABANDONMENT,
        displayName: "Cart abandonment",
      });
      permutations.push({
        category: "Marketing",
        schemaType: SchemaType.MARKETING_BROWSE_ABANDONMENT,
        displayName: "Browse abandonment",
      });
      permutations.push({
        category: "Marketing",
        schemaType: SchemaType.MARKETING_CHECKOUT_ABANDONMENT,
        displayName: "Checkout abandonment",
      });
    }
    if (!settings.orderTracking?.enabled) {
      permutations.push({
        category: "Order tracking",
        // may eventually need its own schema (discount codes, etc.)
        // but until we know for sure, let's reuse some code
        schemaType: SchemaType.ORDER_TRACKING,
        displayName: "Order created",
      });
    }
  }
  if (settings.reviews?.enabled) {
    permutations.push({
      category: "Reviews",
      schemaType: SchemaType.REVIEWS,
      displayName: "Reviews",
    });
  }
  return permutations;
}

const orderTrackingTriggers: Trigger[] = [
  {
    key: OrderTrackingTrigger.ORDER_CREATED,
    displayName: "Order created",
  },
  {
    key: OrderTrackingTrigger.ORDER_FULFILLED,
    displayName: "Order fulfilled",
  },
  {
    key: OrderTrackingTrigger.ORDER_PRE_TRANSIT,
    displayName: "Order pre-transit",
  },
  {
    key: OrderTrackingTrigger.ORDER_IN_TRANSIT,
    displayName: "Order in-transit",
  },
  {
    key: OrderTrackingTrigger.ORDER_OUT_FOR_DELIVERY,
    displayName: "Order out for delivery",
  },
  {
    key: OrderTrackingTrigger.ORDER_DELIVERED,
    displayName: "Order delivered",
  },
  {
    key: OrderTrackingTrigger.ORDER_AVAILABLE_FOR_PICKUP,
    displayName: "Order available for pickup",
  },
  {
    key: OrderTrackingTrigger.ORDER_STALLED_IN_TRANSIT,
    displayName: "Order stalled in transit",
  },
  {
    key: OrderTrackingTrigger.ORDER_STALLED_IN_FULFILLMENT,
    displayName: "Order stalled in fulfillment",
  },
  {
    key: OrderTrackingTrigger.ORDER_DELAYED,
    displayName: "Order delayed",
  },
  {
    key: OrderTrackingTrigger.ORDER_ARRIVING_EARLY,
    displayName: "Order arriving early",
  },
  {
    key: OrderTrackingTrigger.ORDER_RETURN_TO_SENDER,
    displayName: "Order return to sender",
  },
  {
    key: OrderTrackingTrigger.ORDER_DELIVERY_ATTEMPTED,
    displayName: "Order delivery attempted",
  },
  {
    key: OrderTrackingTrigger.ORDER_DELIVERY_FAILURE,
    displayName: "Order delivery failure",
  },
  {
    key: OrderTrackingTrigger.ORDER_SHIPMENT_CANCELLED,
    displayName: "Order shipment cancelled",
  },
  {
    key: OrderTrackingTrigger.ORDER_SHIPMENT_ERROR,
    displayName: "Order shipment error",
  },
].map((t) => ({
  ...t,
  category: "Order tracking" as const,
  schemaType: SchemaType.ORDER_TRACKING,
}));

const returnTrackingTriggers: Trigger[] = [
  {
    key: ReturnTrackingTrigger.RETURN_CREATED,
    displayName: "Return created",
  },
  {
    key: ReturnTrackingTrigger.RETURN_IN_TRANSIT,
    displayName: "Return in-transit",
  },
  {
    key: ReturnTrackingTrigger.RETURN_OUT_FOR_DELIVERY,
    displayName: "Return out for delivery",
  },
  {
    key: ReturnTrackingTrigger.RETURN_DELIVERED,
    displayName: "Return received", // renamed for UI clarity
  },
  {
    key: ReturnTrackingTrigger.RETURN_STALLED,
    displayName: "Return stalled in transit",
  },
  {
    key: ReturnTrackingTrigger.RETURN_DELAYED,
    displayName: "Return delayed",
  },
  {
    key: ReturnTrackingTrigger.RETURN_ARRIVING_EARLY,
    displayName: "Return arriving early",
  },
  {
    key: ReturnTrackingTrigger.RETURN_RETURN_TO_SENDER,
    displayName: "Return return to sender",
  },
  {
    key: ReturnTrackingTrigger.RETURN_DELIVERY_ATTEMPTED,
    displayName: "Return delivery attempted",
  },
  {
    key: ReturnTrackingTrigger.RETURN_DELIVERY_FAILURE,
    displayName: "Return delivery failure",
  },
  {
    key: ReturnTrackingTrigger.RETURN_SHIPMENT_CANCELLED,
    displayName: "Return shipment cancelled",
  },
  {
    key: ReturnTrackingTrigger.RETURN_SHIPMENT_ERROR,
    displayName: "Return shipment error",
  },
  {
    key: ReturnTrackingTrigger.RETURN_REJECTED,
    displayName: "Return rejected",
  },
  {
    key: ReturnTrackingTrigger.RETURN_IN_REVIEW,
    displayName: "Return in review",
  },
  {
    key: ReturnTrackingTrigger.RETURN_PICKUP_REMINDER,
    displayName: "Return pickup reminder",
  },
  {
    key: ReturnTrackingTrigger.RETURN_PICKUP_SUCCESS,
    displayName: "Return pickup success",
  },
  {
    key: ReturnTrackingTrigger.RETURN_PICKUP_FAILURE,
    displayName: "Return pickup failure",
  },
  {
    key: ReturnTrackingTrigger.RETURN_OUSTANDING_BALANCE,
    displayName: "Return outstanding balance",
  },
  {
    key: ReturnTrackingTrigger.RETURN_EXCHANGE_OUT_OF_STOCK,
    displayName: "Return exchange out of stock",
  },
].map((t) => ({
  ...t,
  category: "Return tracking" as const,
  schemaType: SchemaType.RETURN_TRACKING,
}));

const marketingTriggers: Trigger[] = [
  {
    key: MarketingTrigger.EMAIL_SIGNUP,
    displayName: "Marketing email signup",
    category: "Marketing",
    schemaType: SchemaType.EMAIL_MARKETING_SIGNUP,
  },
  {
    key: MarketingTrigger.SMS_SIGNUP,
    displayName: "Marketing SMS signup",
    category: "Marketing",
    schemaType: SchemaType.SMS_MARKETING_SIGNUP,
  },
  {
    key: MarketingTrigger.SMS_CONFIRMED,
    displayName: "Marketing SMS confirmed",
    category: "Marketing",
    schemaType: SchemaType.SMS_MARKETING_CONFIRMED,
  },
  {
    key: MarketingTrigger.CART_ABANDONED,
    displayName: "Abandoned cart",
    category: "Marketing",
    schemaType: SchemaType.MARKETING_CART_ABANDONMENT,
  },
  {
    key: MarketingTrigger.BROWSE_ABANDONED,
    displayName: "Abandoned browse",
    category: "Marketing",
    schemaType: SchemaType.MARKETING_BROWSE_ABANDONMENT,
  },
  {
    key: MarketingTrigger.CHECKOUT_ABANDONED,
    displayName: "Abandoned checkout",
    category: "Marketing",
    schemaType: SchemaType.MARKETING_CHECKOUT_ABANDONMENT,
  },
];

const reviewsTriggers: Trigger[] = [
  {
    key: ReviewsTrigger.REVIEW_SUBMITTED,
    displayName: "Review submitted",
    category: "Reviews",
    schemaType: SchemaType.REVIEWS,
  },
];

export const triggers = [
  ...orderTrackingTriggers,
  ...returnTrackingTriggers,
  ...marketingTriggers,
  ...reviewsTriggers,
];

export function getTriggerDisplayName(key: TriggerKey) {
  return triggers.find((t) => t.key === key)?.displayName;
}

export function getEnabledTriggers(settings: Team["settings"]) {
  const categories: Trigger["category"][] = [];
  if (settings.orderTracking?.enabled) {
    categories.push("Order tracking");
  }
  if (settings.returnTracking?.enabled) {
    categories.push("Return tracking");
  }
  if (settings.marketing?.enabled) {
    categories.push("Marketing");
  }
  if (settings.reviews?.enabled) {
    categories.push("Reviews");
  }
  const enabledTriggers = triggers.filter((trigger) =>
    categories.includes(trigger.category),
  );

  // special case
  if (settings.marketing?.enabled && !settings.orderTracking?.enabled) {
    enabledTriggers.push({
      key: OrderTrackingTrigger.ORDER_CREATED,
      displayName: "Order created",
      category: "Order tracking",
      schemaType: SchemaType.ORDER_TRACKING,
    });
  }

  return enabledTriggers;
}
