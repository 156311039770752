import { getWarrantyEligibleProductsSchema as getWarrantyEligibleProducts } from "@redotech/redo-model/warranties/get-warranty-eligible-products-schema";
import { submitWarrantyRegistrationSchema as submitWarrantyRegistration } from "@redotech/redo-model/warranties/submit-warranty-registration-schema";
import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { getAdvancedFlowById } from "./schema/advanced-flows/get-advanced-flow-by-id";
import {
  createReport,
  deleteReport,
  getReport,
  getReports,
  updateReport,
} from "./schema/analytics/reports";
import { calculateNewOrderValue } from "./schema/calculate-new-order-value";
import { deleteCarrierAccount } from "./schema/carrier-accounts/delete-carrier-account";
import { createCarrierAccount } from "./schema/carrier-accounts/dhl-carrier-account";
import { getCarrierAccounts } from "./schema/carrier-accounts/get-carrier-accounts";
import { getConciergeConversationById } from "./schema/concierge/get-concierge-conversation-by-id";
import { getConciergeConversations } from "./schema/concierge/get-concierge-conversations";
import { getConciergeDashboardMetrics } from "./schema/concierge/get-concierge-dashboard-metrics";
import { getConciergeGeneralSettings } from "./schema/concierge/get-concierge-general-settings";
import { getConciergeContextSettings } from "./schema/concierge/get-context-settings";
import { getSupportDashboardMetrics } from "./schema/concierge/get-support-dashboard-metrics";
import { setConciergeContextSettings } from "./schema/concierge/set-context-settings";
import { updateConciergeGeneralSettings } from "./schema/concierge/update-concierge-general-settings";
import { disconnectRivoIntegration } from "./schema/customer-accounts/rivo/disconnect-rivo-integration";
import { getRivoIntegration } from "./schema/customer-accounts/rivo/get-rivo-integration";
import { setRivoIntegration } from "./schema/customer-accounts/rivo/set-rivo-integration";
import { getNativeAccountsSettings } from "./schema/customer-accounts/storefront-display-settings/get-native-accounts-settings";
import { updateNativeAccountsSettings } from "./schema/customer-accounts/storefront-display-settings/update-native-accounts-settings";
import { getOrCreateCustomerFromPhoneNumber } from "./schema/customer/get-or-create-customer-from-phone-number";
import { createEndShipper } from "./schema/end-shippers/create-end-shipper";
import { getEndShippers } from "./schema/end-shippers/get-end-shippers";
import { updateEndShipper } from "./schema/end-shippers/update-end-shipper";
import { createArticle } from "./schema/faq/create-article";
import { createCollection } from "./schema/faq/create-collection";
import { deleteArticle } from "./schema/faq/delete-article";
import { deleteCollection } from "./schema/faq/delete-collection";
import { getFaq } from "./schema/faq/get-faq";
import { updateArticle } from "./schema/faq/update-article";
import { updateCollection } from "./schema/faq/update-collection";
import { updateTheme } from "./schema/faq/update-theme";
import { getBundlesData } from "./schema/get-bundles-data";
import { getCustomerAccountsMetricsForOverview } from "./schema/get-customer-accounts-metrics-for-overview";
import { getEmailTemplateNames } from "./schema/get-email-template-names";
import { getManagedClaimStatus } from "./schema/get-managed-claim-status";
import { getOrders } from "./schema/get-orders";
import { getOriginDiscounts } from "./schema/get-origin-discounts";
import { getRepairInvoiceDetails } from "./schema/get-repair-invoice-details";
import { getReturnsInstantExchangeRecoveryHistory } from "./schema/get-returns-instant-exchange-recovery-history";
import { getReviews } from "./schema/get-reviews";
import { getReviewsAverageByProject } from "./schema/get-reviews-average-by-project";
import { getReviewsByProductId } from "./schema/get-reviews-by-product-id";
import { getReviewsByTeamId } from "./schema/get-reviews-by-team-id";
import { getSaveForLaterStorefrontDisplaySettings } from "./schema/get-save-for-later-storefront-display-settings";
import { getIndustryCategoryComparisonStats } from "./schema/industry-category/get-industry-category-comparison-stats";
import { getValidIndustryCategories } from "./schema/industry-category/get-valid-industry-categories";
import { issueStoreCreditToCustomer } from "./schema/issue-store-credit-to-customer";
import {
  getCampaignRecipientsCount,
  paginateCampaignRecipients,
} from "./schema/marketing/campaigns/campaign-recipient";
import { createCampaign } from "./schema/marketing/campaigns/create-campaign";
import { deleteCampaign } from "./schema/marketing/campaigns/delete-campaign";
import { duplicateCampaign } from "./schema/marketing/campaigns/duplicate-campaign";
import { getCampaignById } from "./schema/marketing/campaigns/get-campaign-by-id";
import { getCustomerGroups } from "./schema/marketing/campaigns/get-customer-groups";
import { getTeamCampaigns } from "./schema/marketing/campaigns/get-team-campaigns";
import { previewEmailTemplate } from "./schema/marketing/campaigns/preview-email-template";
import { updateCampaign } from "./schema/marketing/campaigns/update-campaign";
import { getOutreachAnalytics } from "./schema/marketing/get-outreach-analytics";
import { getTeamPhoneNumber } from "./schema/marketing/get-team-phone-number-schema";
import { createSignupForm } from "./schema/marketing/signup-form/create-signup-form";
import { deleteSignupForm } from "./schema/marketing/signup-form/delete-signup-form";
import { getMarketingSubscriberCounts } from "./schema/marketing/signup-form/get-marketing-subscriber-counts";
import { getSignupFormById } from "./schema/marketing/signup-form/get-signup-form-by-id";
import { getSignupFormsByTeamId } from "./schema/marketing/signup-form/get-signup-forms-by-team-id";
import { unpublishSignupForm } from "./schema/marketing/signup-form/unpublish-signup-form";
import { updatePublishedAt } from "./schema/marketing/signup-form/update-published-at";
import { updateSignupForm } from "./schema/marketing/signup-form/update-signup-form";
import { verifyOrCreateNotOnSaleCollection } from "./schema/marketing/signup-form/verify-or-create-not-on-sale-collection";
import { getSmsTemplateById } from "./schema/marketing/sms-templates/get-sms-template-by-id";
import { updateSmsTemplate } from "./schema/marketing/sms-templates/update-sms-template";
import { createMerchantPolicy } from "./schema/merchant-policies/create-merchant-policy";
import { deleteMerchantPolicy } from "./schema/merchant-policies/delete-merchant-policy";
import { editMerchantPolicy } from "./schema/merchant-policies/edit-merchant-policy";
import { getMerchantPolicies } from "./schema/merchant-policies/get-merchant-policies";
import { addOutboundFunds } from "./schema/outbound-labels/add-outbound-funds";
import { buyShipmentRate } from "./schema/outbound-labels/buy-shipment-rate";
import { getOutboundBalance } from "./schema/outbound-labels/get-outbound-balance";
import { processReturn } from "./schema/process-return";
import { searchProducts } from "./schema/products/search-products";
import { recreateDiscounts } from "./schema/recreate-discounts";
import { removeVariantExchangeItem } from "./schema/remove-variant-exchange-item";
import { resendEmail } from "./schema/resend-email";
import { restockItemsForReturn } from "./schema/restock/restock-items-for-return";
import { setReturnItemDisposition } from "./schema/return-item-disposition/set-return-item-disposition";
import { createReviewForm } from "./schema/review-form/create-review-form";
import { deleteReviewForm } from "./schema/review-form/delete-review-form";
import { getActiveReviewFormForTeam } from "./schema/review-form/get-active-review-form-for-team";
import { getReviewForm } from "./schema/review-form/get-review-form";
import { getReviewFormsForTeam } from "./schema/review-form/get-review-forms-for-team";
import { updateReviewForm } from "./schema/review-form/update-review-form";
import { getRedoSalesChannels } from "./schema/sales-channels/get-redo-sales-channels";
import { getSalesChannelOptions } from "./schema/sales-channels/get-sales-channel-options";
import { setRedoSalesChannels } from "./schema/sales-channels/set-redo-sales-channels";
import { searchProductVariants } from "./schema/search-product-variants";
import { sendRepairInvoice } from "./schema/send-repair-invoice";
import { createCallAvailability } from "./schema/support/create-call-availability";
import {
  getCsatResponsesForConversation,
  getCsatResponsesForTeam,
  getCsatResponsesForTeamCount,
} from "./schema/support/csat";
import { deleteRecordingFromCallRecord } from "./schema/support/delete-recording-from-call-record";
import { getCallRecordWithTranscript } from "./schema/support/get-call-record-with-transcript";
import { getTeamCallAvailabilities } from "./schema/support/get-team-call-availabilities";
import { getPhoneNumberForTeam } from "./schema/support/phone-number";
import { polishMessage } from "./schema/support/polish-message";
import { getTelnyxTelephonyToken } from "./schema/support/telnyx-telephony-token";
import { toggleCallBeingRecorded } from "./schema/support/toggle-call-being-recorded";
import { toggleCallOnHold } from "./schema/support/toggle-call-on-hold";
import { triggerConciergeResponse } from "./schema/support/trigger-concierge-response";
import { updateNewOrderItems } from "./schema/update-new-order-items";
import { updateReview } from "./schema/update-review";
import { updateSaveForLaterStorefrontDisplaySettings } from "./schema/update-save-for-later-storefront-display-settings";
import { getUpsellPageAnalytics } from "./schema/upsell-page-analytics.ts/get-upsell-page-analytics";
import { createUpsellPage } from "./schema/upsell-page/create-upsell-page";
import { deleteUpsellPage } from "./schema/upsell-page/delete-upsell-page";
import { getActiveUpsellPageForTeam } from "./schema/upsell-page/get-active-upsell-page-for-team";
import { getUpsellPage } from "./schema/upsell-page/get-upsell-page";
import { updateUpsellPage } from "./schema/upsell-page/update-upsell-page";

const reviewForm1 = {
  createReviewForm,
  deleteReviewForm,
  updateReviewForm,
};

const reviewForm2 = {
  getReviewForm,
  getReviewFormsForTeam,
};

const reviewForm3 = {
  getActiveReviewFormForTeam,
};

const upsellPage1 = {
  createUpsellPage,
  deleteUpsellPage,
  updateUpsellPage,
};

const upsellPage2 = {
  getUpsellPage,
  //getUpsellPagesForTeam,
};

const upsellPage3 = {
  getActiveUpsellPageForTeam,
};

const upsellPageAnalytics = {
  getUpsellPageAnalytics,
};

const industryCategory = {
  getValidIndustryCategories,
  getIndustryCategoryComparisonStats,
};

const returnItemDisposition = {
  setReturnItemDisposition,
};

const redoMerchantRPC1 = {
  issueStoreCreditToCustomer,
  getCustomerAccountsMetricsForOverview,
  getSaveForLaterStorefrontDisplaySettings,
  updateSaveForLaterStorefrontDisplaySettings,
  getNativeAccountsSettings,
  updateNativeAccountsSettings,
  resendEmail,
  getReviewsByProductId,
  getEmailTemplateNames,
  getReviewsByTeamId,
  getReviews,
  updateReview,
  getReviewsAverageByProject,
  getReturnsInstantExchangeRecoveryHistory,
  getOrders,
  getReport,
  getReports,
  createReport,
  updateReport,
  deleteReport,
  processReturn,
};

const support = {
  createCallAvailability,
  deleteRecordingFromCallRecord,
  getCallRecordWithTranscript,
  getTeamCallAvailabilities,
  polishMessage,
  getCsatResponsesForConversation,
  getCsatResponsesForTeam,
  getCsatResponsesForTeamCount,
  getPhoneNumberForTeam,
  getTelnyxTelephonyToken,
  toggleCallOnHold,
  toggleCallBeingRecorded,
  triggerConciergeResponse,
};

const customer = {
  getOrCreateCustomerFromPhoneNumber,
};

const faq = {
  getFaq,
  createArticle,
  updateArticle,
  updateTheme,
  createCollection,
  updateCollection,
  deleteArticle,
  deleteCollection,
};

const restock = {
  restockItemsForReturn,
};

const concierge = {
  updateConciergeGeneralSettings,
  getConciergeGeneralSettings,
  getConciergeConversations,
  getConciergeContextSettings,
  setConciergeContextSettings,
  getConciergeDashboardMetrics,
  getConciergeConversationById,
  getSupportDashboardMetrics,
};

const marketing = {
  getSignupFormById,
  getSignupFormsByTeamId,
  createSignupForm,
  updateSignupForm,
  getMarketingSubscriberCounts,
  unpublishSignupForm,
  updatePublishedAt,
  deleteSignupForm,
  getOutreachAnalytics,
  getTeamPhoneNumber,
  createCampaign,
  updateCampaign,
  getCampaignById,
  getTeamCampaigns,
  getCustomerGroups,
  getCampaignRecipientsCount,
  paginateCampaignRecipients,
  getSmsTemplateById,
  updateSmsTemplate,
};

const redoMerchantRPC2 = {
  getSalesChannelOptions,
  getRedoSalesChannels,
  setRedoSalesChannels,
  updateNewOrderItems,
  removeVariantExchangeItem,
  getBundlesData,
  searchProductVariants,
  getWarrantyEligibleProducts,
  submitWarrantyRegistration,
  getRivoIntegration,
  setRivoIntegration,
  disconnectRivoIntegration,
  calculateNewOrderValue,
  getOriginDiscounts,
  recreateDiscounts,
  verifyOrCreateNotOnSaleCollection,
  searchProducts,
  getAdvancedFlowById,
  deleteCampaign,
  duplicateCampaign,
  previewEmailTemplate,
};

const merchantPolicies = {
  getMerchantPolicies,
  deleteMerchantPolicy,
  createMerchantPolicy,
  editMerchantPolicy,
};

const managedClaims = {
  getManagedClaimStatus,
};

const repairs = {
  getRepairInvoiceDetails,
  sendRepairInvoice,
};

const orderManagement = {
  createCarrierAccount,
  getCarrierAccounts,
  deleteCarrierAccount,
  addOutboundFunds,
  getOutboundBalance,
  getEndShippers,
  updateEndShipper,
  createEndShipper,
  buyShipmentRate,
};

/** This type is necessary because redoMerchantRPC is too
 *  big for typescript to infer its type. */
type RpcDefinition = typeof redoMerchantRPC1 &
  typeof redoMerchantRPC2 &
  typeof reviewForm1 &
  typeof reviewForm2 &
  typeof reviewForm3 &
  typeof upsellPage1 &
  typeof upsellPage2 &
  typeof upsellPage3 &
  typeof upsellPageAnalytics &
  typeof industryCategory &
  typeof returnItemDisposition &
  typeof merchantPolicies &
  typeof concierge &
  typeof support &
  typeof faq &
  typeof restock &
  typeof managedClaims &
  typeof customer &
  typeof repairs &
  typeof marketing &
  typeof orderManagement;

export const rpcDefinition: RpcDefinition = {
  ...redoMerchantRPC1,
  ...redoMerchantRPC2,
  ...reviewForm1,
  ...reviewForm2,
  ...reviewForm3,
  ...upsellPage1,
  ...upsellPage2,
  ...upsellPage3,
  ...upsellPageAnalytics,
  ...industryCategory,
  ...returnItemDisposition,
  ...merchantPolicies,
  ...concierge,
  ...support,
  ...faq,
  ...restock,
  ...managedClaims,
  ...customer,
  ...repairs,
  ...marketing,
  ...orderManagement,
};

export type RedoMerchantRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
