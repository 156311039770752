// extracted by mini-css-extract-plugin
export var actionButton = "_9x-1";
export var actions = "_9x-0";
export var copyableField = "_9x-2";
export var detailColumn = "_9x-3";
export var detailRow = "_9x-4";
export var dropdownTitle = "_9x-5";
export var fulfillmentDetails = "_9x-7";
export var fulfillments = "_9x-6";
export var icon = "_9x-8";
export var imgContainer = "_9x-9";
export var lineItems = "_9x-a";
export var link = "_9x-p";
export var orderCard = "_9x-k";
export var orderCollapseButton = "_9x-l";
export var orderCollapseButtonIcon = "_9x-m";
export var orderDetails = "_9x-b";
export var orderPillsContainer = "_9x-c";
export var pillWrapper = "_9x-d";
export var product = "_9x-e";
export var productDetails = "_9x-g";
export var productTopDetails = "_9x-h";
export var products = "_9x-f";
export var strong = "_9x-i";
export var tightText = "_9x-o";
export var tooltipHidden = "_9x-q";
export var variantQuantityRow = "_9x-j";
export var wrappingText = "_9x-n";