import { z } from "zod";
import { zExt } from "../common/zod-util";
import { schemas } from "../marketing/conditions";

export enum CustomerGroupType {
  STATIC = "static",
  DYNAMIC = "dynamic",
}

export const customerGroupSchema = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  name: z.string(),
  type: z.nativeEnum(CustomerGroupType),
  count: z.number().optional(),
  conditions: schemas.queryCondition.optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
  isFullSubscriberList: z.boolean().optional(),
});

export type CustomerGroup = z.infer<typeof customerGroupSchema>;
