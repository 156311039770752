import { z } from "zod";
import { CompensationMethod } from "../../../return";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum CompensationMethodFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const CompensationMethodFilterSchema = makeReportFilterSchema(
  ReportFilterType.COMPENSATION_METHOD,
  CompensationMethodFilterQuery,
  z.array(z.nativeEnum(CompensationMethod)),
);

export type CompensationMethodFilter = z.infer<
  typeof CompensationMethodFilterSchema
>;
// BaseReportFilter<ReportFilterType.COMPENSATION_METHOD, CompensationMethodFilterQuery, z.ZodArray<z.ZodEnum<CompensationMethod>>>
