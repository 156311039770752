import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { customerGroupSchema } from "../customers/customer-group-definition";
import { emailTemplateSchema } from "../email-template";
import { MarketingSubscriptionType } from "../marketing";
import { smsTemplateSchema } from "../sms-template";

export const campaignSchema = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  name: z.string(),
  channel: z.nativeEnum(MarketingSubscriptionType),
  emailTemplate: emailTemplateSchema.optional(),
  smsTemplate: smsTemplateSchema.optional(),
  customerGroupIds: z.array(zExt.objectId()),
  scheduledAt: z.date().optional().nullable(),
  finishedAt: z.date().optional().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const outreachAnalyticsSchema = z.object({
  sends: z.number(),
  deliveries: z.number(),
  opens: z.number(),
  uniqueOpens: z.number(),
  clicks: z.number(),
  uniqueClicks: z.number(),
  upsellCount: z.number(),
  upsellRevenue: z.number(),
});

export const campaignWithOutreachAnalyticsSchema = campaignSchema.extend({
  stats: outreachAnalyticsSchema.optional(),
});

export const campaignWithCustomerGroupsSchema = campaignSchema.extend({
  customerGroups: z.array(customerGroupSchema).optional(),
});

export type Campaign = z.infer<typeof campaignSchema>;
export type CampaignWithCustomerGroups = z.infer<
  typeof campaignWithCustomerGroupsSchema
>;
export type OutreachAnalytics = z.infer<typeof outreachAnalyticsSchema>;
export type CampaignWithOutreachAnalytics = z.infer<
  typeof campaignWithOutreachAnalyticsSchema
>;

const emailCampaignCreationParametersSchema = z.object({
  channel: z.literal(MarketingSubscriptionType.EMAIL),
  emailTemplate: z.object({
    subjectLine: z.string(),
    emailPreview: z.string(),
  }),
  name: z.string(),
});

const smsCampaignCreationParametersSchema = z.object({
  channel: z.literal(MarketingSubscriptionType.SMS),
  name: z.string(),
});

export const campaignCreationParametersSchema = z.discriminatedUnion(
  "channel",
  [emailCampaignCreationParametersSchema, smsCampaignCreationParametersSchema],
);

export type CampaignCreationParameters = z.infer<
  typeof campaignCreationParametersSchema
>;

export const campaignUpdateSchema = z.object({
  id: z.string(),
  fields: campaignSchema
    .omit({ _id: true, createdAt: true, updatedAt: true })
    .partial(),
});
export type CampaignUpdate = z.infer<typeof campaignUpdateSchema>;
