import { z } from "zod";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum ProductNameFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const ProductNameFilterSchema = makeReportFilterSchema(
  ReportFilterType.PRODUCT_NAME,
  ProductNameFilterQuery,
  z.array(z.string()),
);

export type ProductNameFilter = z.infer<typeof ProductNameFilterSchema>;
// BaseReportFilter<ReportFilterType.PRODUCT_NAME, ProductNameFilterQuery, z.ZodArray<z.ZodString>>
