import { z } from "zod";
import { ReturnTypeEnum } from "../../../return";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum ReturnTypeFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const ReturnTypeFilterSchema = makeReportFilterSchema(
  ReportFilterType.RETURN_TYPE,
  ReturnTypeFilterQuery,
  z.array(z.nativeEnum(ReturnTypeEnum)),
);

export type ReturnTypeFilter = z.infer<typeof ReturnTypeFilterSchema>;
// BaseReportFilter<ReportFilterType.RETURN_TYPE, ReturnTypeFilterQuery, z.ZodArray<z.ZodEnum<ReturnTypeEnum>>>
