import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { useRequiredContext } from "@redotech/react-util/context";
import { BreadcrumbOverridersContext } from "@redotech/redo-web/breadcrumb";
import { nativeRandom, randomHexString } from "@redotech/util/random";
import { memo, useContext, useLayoutEffect, useState } from "react";
import { TeamContext } from "../app/team";
import { DocumentTitleOverridersContext } from "../root";
import { ActiveViewContext } from "./conversations-table-filters/active-view-context";
import { SUPPORT_VIEW_NAME } from "./route";
import { viewUrlNameToDisplayName } from "./view-name-utils";

export const SupportBreadcrumbOverrider = memo(
  function SupportBreadcrumbOverrider() {
    const [, setBreadcrumbOverriders] = useContext(BreadcrumbOverridersContext);
    const [, setDocumentTitleOverriders] = useContext(
      DocumentTitleOverridersContext,
    );
    const team = useRequiredContext(TeamContext);
    const activeView = useContext(ActiveViewContext);

    const [breadcrumbOverrideHash, setBreadcrumbOverrideHash] =
      useState<string>(randomHexString(nativeRandom, 24));

    useLayoutEffect(() => {
      setBreadcrumbOverrideHash(randomHexString(nativeRandom, 24));
    }, [activeView.name]);

    const transitionState = useContext(OverlapTransitionStateContext);

    const clearBreadcrumbOverriders = () => {
      setBreadcrumbOverriders((prev) => {
        prev?.delete(breadcrumbOverrideHash);
        return Object.assign(new Map(prev), prev);
      });
    };

    const clearDocumentTitleOverriders = () => {
      setDocumentTitleOverriders((prev) => {
        prev?.delete(breadcrumbOverrideHash);
        return Object.assign(new Map(prev), prev);
      });
    };

    /**
     * There's a time between when this is exiting and when it's derendered
     * (follow `transitionState`). We need to clear the overrides so that the
     * next component doesn't get the old overrides.
     */
    useLayoutEffect(() => {
      if (transitionState === OverlapTransitionState.EXIT) {
        clearBreadcrumbOverriders();
        clearDocumentTitleOverriders();
      }
    }, [transitionState]);

    useLayoutEffect(
      function showCustomSupportBreadcrumbs() {
        if (transitionState === OverlapTransitionState.EXIT) {
          return;
        }
        setBreadcrumbOverriders((prev) => {
          prev?.set(breadcrumbOverrideHash, (breadcrumb) => ({
            ...breadcrumb,
            name:
              breadcrumb.name === SUPPORT_VIEW_NAME
                ? viewUrlNameToDisplayName(activeView.name)
                : breadcrumb.name,
          }));
          return Object.assign(new Map(prev), prev);
        });
        return clearBreadcrumbOverriders;
      },
      [activeView.name],
    );

    useLayoutEffect(
      function showCustomSupportDocumentTitle() {
        if (transitionState === OverlapTransitionState.EXIT) {
          return;
        }
        setDocumentTitleOverriders((prev) => {
          prev?.set(
            breadcrumbOverrideHash,
            () =>
              `${viewUrlNameToDisplayName(activeView.name)} - ${team.name} Support`,
          );
          return Object.assign(new Map(prev), prev);
        });
        return clearDocumentTitleOverriders;
      },
      [activeView.name],
    );

    return null;
  },
);
