// extracted by mini-css-extract-plugin
export var alternate = "_2m-h";
export var black = "_2m-3";
export var blue = "_2m-d";
export var blueLight = "_2m-c";
export var brand = "_2m-7";
export var error = "_2m-9";
export var gray = "_2m-6";
export var grayBlue = "_2m-b";
export var iconWrapper = "_2m-4";
export var indigo = "_2m-e";
export var orange = "_2m-g";
export var pink = "_2m-f";
export var primary = "_2m-0";
export var purple = "_2m-8";
export var success = "_2m-5";
export var warning = "_2m-a";
export var white = "_2m-1";
export var whiteSuccess = "_2m-2";