import { smsTemplateSchema } from "@redotech/redo-model/sms-template";
import { z } from "zod";

export const updateSmsTemplate = {
  input: z.object({
    id: z.string(),
    template: smsTemplateSchema,
  }),
  output: z.null(),
};
