import { MarketingSubscriptionType } from "@redotech/redo-model/marketing";
import {
  RedoButtonHierarchy,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { memo } from "react";
import { EmailSummaryCard } from "../email-summary-card";
import { SmsSummaryCard } from "../sms-summary-card";
import {
  CampaignWizardPage,
  CampaignWizardPageProps,
  CampaignWizardStep,
} from "./base";

export const PreviewCampaign = memo(function PreviewCampaign() {
  const handleClose = (props: CampaignWizardPageProps) => {
    props.setCampaign({ ...props.campaign, scheduledAt: null });
  };
  const isAlreadyScheduled = (props: CampaignWizardPageProps) =>
    !!props.unmodifiedCampaign.scheduledAt;
  return (
    <CampaignWizardPage
      actionButton={(props) => ({
        text: isAlreadyScheduled(props)
          ? "Already scheduled to send"
          : "Send campaign",
        onClick: () => {
          props.setCampaign({ ...props.campaign, scheduledAt: new Date() });
        },
        hierarchy: RedoButtonHierarchy.PRIMARY,
        disabled: props.pending || !!props.unmodifiedCampaign.scheduledAt,
        theme: RedoButtonTheme.SUCCESS,
      })}
    >
      {(props) => (
        <>
          <Flex mx="auto" my="3xl">
            {props.campaign?.channel === MarketingSubscriptionType.EMAIL ? (
              <EmailSummaryCard campaign={props.campaign} />
            ) : props.campaign?.channel === MarketingSubscriptionType.SMS ? (
              <SmsSummaryCard campaign={props.campaign} />
            ) : null}
          </Flex>

          <RedoModal
            isOpen={
              !!props.campaign.scheduledAt &&
              !props.unmodifiedCampaign.scheduledAt
            }
            onModalCloseRequested={() => handleClose(props)}
            primaryButton={{
              text: "Send campaign",
              onClick: () => props.saveAndContinue(CampaignWizardStep.PREVIEW),
            }}
            secondaryButton={{
              text: "Cancel",
              onClick: () => handleClose(props),
            }}
            title="Send campaign"
          >
            Please confirm that you want to send this campaign now.
          </RedoModal>
        </>
      )}
    </CampaignWizardPage>
  );
});
