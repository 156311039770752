// extracted by mini-css-extract-plugin
export var accent = "_2l-4";
export var add = "_2l-i";
export var addIcon = "_2l-j";
export var deleteIcon = "_2l-h";
export var disabled = "_2l-9";
export var ellipsis = "_2l-e";
export var error = "_2l-3";
export var form = "_2l-5";
export var hideFocus = "_2l-2";
export var icon = "_2l-7";
export var input = "_2l-d";
export var listItem = "_2l-g";
export var mediumHeight = "_2l-b";
export var noBorder = "_2l-8";
export var noRadius = "_2l-1";
export var shortHeight = "_2l-a";
export var tallHeight = "_2l-c";
export var textInput = "_2l-0";
export var vertical = "_2l-f";
export var widget = "_2l-6";