import { z } from "zod";

export const getReviewsAverageByProject = {
  input: z.object({ teamId: z.string() }),
  output: z.array(
    z.object({
      team: z.string(),
      shopifyProductId: z.string(),
      numberOfReviews: z.number(),
      numberOfStars: z.number(),
    }),
  ),
};
