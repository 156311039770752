import { SystemView } from "@redotech/redo-model/conversation-filters/system-view";

const systemViewToDisplayNameMap: Record<SystemView, string> = {
  [SystemView.ALL]: "All",
  [SystemView.ASSIGNED]: "Assigned to me",
  [SystemView.MENTIONS]: "Mentions",
  [SystemView.DRAFTS]: "Drafts",
  [SystemView.SENT]: "Sent",
  [SystemView.SPAM]: "Spam",
  [SystemView.CREATE]: "Create",
};

export const viewUrlNameToDisplayName = (viewName: SystemView | string) => {
  if (viewName in systemViewToDisplayNameMap) {
    return systemViewToDisplayNameMap[viewName as SystemView];
  }

  return viewName;
};
