import * as classNames from "classnames";
import { CSSProperties, memo, ReactNode, useEffect, useState } from "react";
import { formatTimeAgo } from "../date-utils";
import { Flex } from "../flex";
import { Text } from "../text";
import * as messageBubbleCss from "./message-bubble.module.css";

export enum MessageBubbleAlignment {
  LEFT = "left",
  RIGHT = "right",
}

export enum MessageBubbleTheme {
  LIGHT = "light",
  DARK = "dark",
}

export const MessageBubble = memo(function MessageBubble({
  alignment,
  theme,
  senderLabel,
  children,
  sentAt,
  style,
}: {
  alignment: MessageBubbleAlignment;
  theme: MessageBubbleTheme;
  senderLabel?: string;
  children: ReactNode;
  sentAt?: Date;
  style?: CSSProperties;
}) {
  const [timeLabel, setTimeLabel] = useState<string | undefined>(
    sentAt ? formatTimeAgo(sentAt.toISOString()) : undefined,
  );

  useEffect(() => {
    if (!sentAt) {
      return;
    }

    const interval = setInterval(() => {
      setTimeLabel(formatTimeAgo(sentAt.toISOString()));
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [sentAt]);

  return (
    <Flex
      alignSelf={
        alignment === MessageBubbleAlignment.LEFT ? "flex-start" : "flex-end"
      }
      dir="column"
      gap="xs"
      style={style}
    >
      {(!!senderLabel || !!timeLabel) && (
        <Flex
          align="flex-end"
          dir="row"
          gap="md"
          justify={
            alignment === MessageBubbleAlignment.LEFT
              ? "flex-start"
              : "flex-end"
          }
        >
          {senderLabel && (
            <Text fontSize="xs" textColor="secondary">
              {senderLabel}
            </Text>
          )}
          {timeLabel && (
            <Text fontSize="xxs" textColor="tertiary">
              {timeLabel}
            </Text>
          )}
        </Flex>
      )}
      <Flex
        alignSelf={
          alignment === MessageBubbleAlignment.LEFT ? "flex-start" : "flex-end"
        }
        className={classNames(
          messageBubbleCss.bubbleContent,
          themeClass[theme],
          alignmentClass[alignment],
        )}
        dir="column"
        grow={1}
        px="lg"
        py="md"
        radius="sm"
      >
        {children}
      </Flex>
    </Flex>
  );
});

export const LoadingMessageBubble = memo(function LoadingMessageBubble({
  alignment,
}: {
  alignment: MessageBubbleAlignment;
}) {
  return (
    <Flex
      className={classNames(
        messageBubbleCss.loadingBubble,
        alignmentClass[alignment],
      )}
      gap="xs"
      radius="md"
    >
      <div className={messageBubbleCss.loadingDotContainer}>
        <LoadingDot
          className={classNames(
            messageBubbleCss.dot,
            messageBubbleCss.firstDot,
          )}
        />
      </div>
      <div className={messageBubbleCss.loadingDotContainer}>
        <LoadingDot
          className={classNames(
            messageBubbleCss.dot,
            messageBubbleCss.secondDot,
          )}
        />
      </div>
      <div className={messageBubbleCss.loadingDotContainer}>
        <LoadingDot
          className={classNames(
            messageBubbleCss.dot,
            messageBubbleCss.thirdDot,
          )}
        />
      </div>
    </Flex>
  );
});

const alignmentClass: Record<MessageBubbleAlignment, string> = {
  [MessageBubbleAlignment.LEFT]: messageBubbleCss.left,
  [MessageBubbleAlignment.RIGHT]: messageBubbleCss.right,
};

const themeClass: Record<MessageBubbleTheme, string> = {
  [MessageBubbleTheme.LIGHT]: messageBubbleCss.light,
  [MessageBubbleTheme.DARK]: messageBubbleCss.dark,
};

const LoadingDot = memo(function LoadingDot({
  className,
}: {
  className?: string | undefined;
}) {
  return <div className={classNames(messageBubbleCss.loadingDot, className)} />;
});
