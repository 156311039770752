import { RateZodSchema } from "@redotech/redo-model/shipment";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const BuyShipmentRateResponseSchema = z.object({
  orderId: z.string(),
  fulfillmentOrderId: z.string(),
  success: z.boolean(),
  errorMessage: z.string().nullish(),
});
export type buyShipmentRateResponse = z.infer<
  typeof BuyShipmentRateResponseSchema
>;

export const buyShipmentRate = {
  input: z.object({
    buyRequests: z.array(
      z.object({
        orderId: zExt.objectId(),
        fulfillmentOrder: z.object({
          id: z.string(),
          lineItems: z.array(
            z.object({
              id: z.string(),
              quantity: z.number(),
            }),
          ),
        }),
        rate: RateZodSchema,
      }),
    ),
  }),
  output: z.array(BuyShipmentRateResponseSchema),
};
