import { plainDateTimeToCurrentDate } from "@redotech/util/temporal";
import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { ReportFilterType } from "./report-filter-type";

export enum DateFilterQuery {
  BEFORE = "before",
  AFTER = "after",
  WITHIN = "within",
}

export enum DateFilterValue {
  TODAY = "today",
  THIS_WEEK = "this week",
  THIS_MONTH = "this month",
  LAST_3_MONTHS = "last 3 months",
  THIS_YEAR = "this year",
}

export function makeReportDateFilterSchema<Type extends ReportFilterType>(
  type: Type,
) {
  return z.union([
    z.object({
      type: z.literal(type),
      query: z.union([
        z.literal(DateFilterQuery.BEFORE),
        z.literal(DateFilterQuery.AFTER),
      ]),
      value: z
        .union([
          z.nativeEnum(DateFilterValue),
          // Only allow Date when operator is not WITHIN
          // Coerce to allow filters to be stringified in search params
          z.coerce.date(),
        ])
        .optional(),
    }),
    z.object({
      type: z.literal(type),
      query: z.literal(DateFilterQuery.WITHIN),
      value: z
        .union([
          z.nativeEnum(DateFilterValue),
          // Only allow [Date, Date] when operator is WITHIN
          // Coerce to allow filters to be stringified in search params
          z.tuple([z.coerce.date(), z.coerce.date()]).readonly(),
        ])
        .optional(),
    }),
  ]);
}

export type BaseReportDateFilter<Type extends ReportFilterType> = z.infer<
  ReturnType<typeof makeReportDateFilterSchema<Type>>
>;

function startOf(value: DateFilterValue): Temporal.PlainDateTime {
  const startOfToday = Temporal.Now.plainDateISO().toPlainDateTime();
  return {
    [DateFilterValue.TODAY]: startOfToday,
    [DateFilterValue.THIS_WEEK]: startOfToday.subtract({
      days: startOfToday.dayOfWeek - 1,
    }),
    [DateFilterValue.THIS_MONTH]: startOfToday.subtract({
      days: startOfToday.day - 1,
    }),
    [DateFilterValue.LAST_3_MONTHS]: startOfToday
      .subtract({ days: startOfToday.day - 1 })
      .subtract({ months: 2 }),
    [DateFilterValue.THIS_YEAR]: startOfToday.subtract({
      days: startOfToday.dayOfYear - 1,
    }),
  }[value];
}

function endOf(value: DateFilterValue): Temporal.PlainDateTime {
  return {
    [DateFilterValue.TODAY]: startOf(value).add({ days: 1 }),
    [DateFilterValue.THIS_WEEK]: startOf(value).add({ days: 7 }),
    [DateFilterValue.THIS_MONTH]: startOf(value).add({ months: 1 }),
    [DateFilterValue.LAST_3_MONTHS]: startOf(DateFilterValue.TODAY), // Is "after the last 3 months" just "after today"?
    [DateFilterValue.THIS_YEAR]: startOf(value).add({ years: 1 }),
  }[value];
}

export function dateFilterToDateRange(
  filter: BaseReportDateFilter<ReportFilterType>,
): readonly [Date | null, Date | null] {
  if (!filter.value) {
    return [null, null];
  }
  if (filter.query === DateFilterQuery.BEFORE) {
    return [
      null,
      filter.value instanceof Date
        ? filter.value
        : plainDateTimeToCurrentDate(startOf(filter.value)),
    ];
  } else if (filter.query === DateFilterQuery.AFTER) {
    return [
      filter.value instanceof Date
        ? filter.value
        : plainDateTimeToCurrentDate(endOf(filter.value)),
      null,
    ];
  } else if (filter.query === DateFilterQuery.WITHIN) {
    if (filter.value instanceof Array) {
      // https://github.com/microsoft/TypeScript/issues/17002
      return filter.value;
    }
    return [
      plainDateTimeToCurrentDate(startOf(filter.value)),
      plainDateTimeToCurrentDate(endOf(filter.value)),
    ];
  } else {
    assertNever(filter.query);
  }
}
