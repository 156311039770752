// extracted by mini-css-extract-plugin
export var actionButton = "_63-t";
export var buttonContainer = "_63-0";
export var buttonContainer2 = "_63-1";
export var buttonSection = "_63-2";
export var details = "_63-3";
export var dropdownItem = "_63-5";
export var dropdownItemsContainer = "_63-4";
export var fileInput = "_63-r";
export var headerButton = "_63-6";
export var icon = "_63-7";
export var input = "_63-8";
export var macroListItem = "_63-9";
export var mainContent = "_63-a";
export var microText = "_63-b";
export var modal = "_63-w";
export var optionsButton = "_63-c";
export var paperclip = "_63-s";
export var pillContent = "_63-d";
export var preview = "_63-e";
export var preview2 = "_63-f";
export var quillEditor = "_63-q";
export var selectedMacro = "_63-g";
export var sidebar = "_63-h";
export var sidebarHeader = "_63-j";
export var sidebarList = "_63-i";
export var sidebarListItem = "_63-k";
export var sidebarSearch = "_63-l";
export var snoozeDurationWrapper = "_63-v";
export var statusPillContainer = "_63-m";
export var tagsContainer = "_63-n";
export var unselectedMacro = "_63-o";
export var variable = "_63-u";
export var wrapper = "_63-p";