import { z } from "zod";

export enum ManagedClaimStatus {
  REJECTED = "Rejected",
  NOT_SUBMITTED = "Not Submitted",
  PENDING_DOCS = "Pending Docs",
  UNDER_REVIEW = "Under Review",
  SUBMITTED = "Submitted",
  CLOSED = "Closed",
  APPROVED = "Approved",
  NOT_PROTECTED = "Not Protected",
}

export const getManagedClaimStatus = {
  input: z.object({ returnId: z.string() }),
  output: z.object({ status: z.nativeEnum(ManagedClaimStatus) }),
};
