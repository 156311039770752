// extracted by mini-css-extract-plugin
export var active = "_9g-b";
export var arrow = "_9g-8";
export var arrowContainer = "_9g-4";
export var container = "_9g-0";
export var disabled = "_9g-5";
export var dot = "_9g-a";
export var dotContainer = "_9g-9";
export var leftArrowContainer = "_9g-6 _9g-4";
export var rightArrowContainer = "_9g-7 _9g-4";
export var slide = "_9g-3";
export var sliderContainer = "_9g-1";
export var sliderOverflow = "_9g-2";