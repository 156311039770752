import { nativeEnum, object, string, infer as zInfer } from "zod";
import { zExt } from "../common/zod-util";

// https://docs.easypost.com/docs/carrier-types
export enum EasyPostCarrierType {
  DHL_ECS = "DhlEcsAccount",
  UPS = "UpsAccount",
  USPS = "UspsAccount",
  FEDEX_CROSS_BORDER = "FedexCrossBorderAccount",
}

export enum RedoCarrierName {
  UPS = "UPS",
  DHL_ECS = "DHL ECS",
  USPS = "USPS",
  FEDEX_CROSS_BORDER = "Fedex Cross Border",
}

export enum EasypostOAuthStatus {
  APPROVED = "approved",
  FAILURE = "failure",
}

export enum FedexCrossBorderHubOrigin {
  FONTANA = "Fontana",
  SADDLE_BROOK = "Saddle Brook",
  BASILDON = "Basildon",
}

export const CarrierAccount = object({
  _id: zExt.objectId(),
  carrierAccountId: string(),
  easypostCarrierType: nativeEnum(EasyPostCarrierType),
  redoCarrierName: nativeEnum(RedoCarrierName),
  name: string(),
  oauthUrl: string().optional(),
  oauthStatus: string().optional(),
});

export type ICarrierAccount = zInfer<typeof CarrierAccount>;
