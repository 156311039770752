import { array, nativeEnum, number, object, string, z } from "zod";
import { ResponseLength } from "../concierge-conversation/concierge-conversation-message-definition";
import { CustomerWidgetChipOptions } from "../customer-widget/customer-widget";

export const DEFAULT_QUESTION_PROMPT_COUNT = 1;

export const ConciergeBehaviorSettingsParser = object({
  name: string().max(100).optional(),
  toneOfVoice: string().max(500).optional(),
  writingExamples: array(string().max(1000)).optional(),
  brandDescription: string().max(1000).optional(),
  customerDescription: string().max(1000).optional(),
  outputLength: nativeEnum(ResponseLength).optional(),
});

export type ConciergeBehaviorSettings = z.infer<
  typeof ConciergeBehaviorSettingsParser
>;

export const ConciergeProductFormSettingsParser = object({
  numQuestionPrompts: number().min(0).max(5).optional(),
});

export const ConciergeCustomerWidgetSettingsParser = object({
  chips: z.record(z.nativeEnum(CustomerWidgetChipOptions), z.string()),
});

export const ConciergeGeneralSettingsParser = object({
  brandAndVoiceSettings: ConciergeBehaviorSettingsParser,
  productFormSettings: ConciergeProductFormSettingsParser,
  customerWidgetSettings: ConciergeCustomerWidgetSettingsParser,
});

export type ConciergeGeneralSettings = z.infer<
  typeof ConciergeGeneralSettingsParser
>;
