import { object, string } from "zod";

export const productContextSchema = object({
  productId: string(),
  variantId: string().optional(),
});

export const contextSchema = object({
  product: productContextSchema.optional(),
});
