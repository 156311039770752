import { OutreachGroupKeySchema } from "@redotech/redo-model/aggregated-metrics/outreach";
import { outreachAnalyticsSchema } from "@redotech/redo-model/campaigns/campaign-definition";
import { z } from "zod";

export const getOutreachAnalytics = {
  input: z.object({
    groupBy: OutreachGroupKeySchema,
  }),
  output: outreachAnalyticsSchema,
};
