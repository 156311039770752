// extracted by mini-css-extract-plugin
export var bubbleContent = "_6d-0";
export var dark = "_6d-2";
export var dot = "_6d-7";
export var firstDot = "_6d-9";
export var left = "_6d-3";
export var light = "_6d-1";
export var loadingBubble = "_6d-5";
export var loadingDot = "_6d-c";
export var loadingDotContainer = "_6d-6";
export var moveUpDown = "_6d-8";
export var right = "_6d-4";
export var secondDot = "_6d-a";
export var thirdDot = "_6d-b";