import Gravatar from "@gravatar/js";
import { useRequiredContext } from "@redotech/react-util/context";
import { ConversationTimelineEvent } from "@redotech/redo-model/conversation-timeline";
import { GetUser } from "@redotech/redo-model/user";
import { Avatar } from "@redotech/redo-web/arbiter-components/avatars/avatar";
import MessageTextSquareIcon from "@redotech/redo-web/arbiter-icon/message-text-square-02.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, ReactNode, useEffect, useMemo, useState } from "react";
import { TeamContext } from "../../../app/team";
import * as activityCardCss from "../../../common/activity-card.module.css";
import * as customerActivityCardCss from "../../../common/activity-card.module.css";
import { BaseConversationActivityCard } from "./base-conversation-activity-card";

export const ConversationActivityCard = memo(
  function ConversationConversationActivityCard({
    conversationActivity,
  }: {
    conversationActivity: ConversationTimelineEvent;
  }) {
    const [user, setUser] = useState<GetUser | undefined>();

    const team = useRequiredContext(TeamContext);

    useEffect(() => {
      const curUser = team.users.find(
        (u) => (u.user as GetUser)._id === conversationActivity.userId,
      );
      setUser(curUser as GetUser | undefined);
    }, []);

    const senderImage = useMemo(() => {
      if (user?.email) {
        return Gravatar({
          email: user?.email,
          defaultImage: "404",
          protocol: location.protocol === "https:" ? "https" : undefined,
          size: Math.ceil(devicePixelRatio * 32),
        });
      } else return null;
    }, [devicePixelRatio, user]);

    const iconElement = (
      <Flex
        align="center"
        className={
          activityCardCss.conversationActivityDataConversationIconContainer
        }
        justify="center"
      >
        {user?.email ? (
          <Avatar
            alt="avatar image"
            imageUrl={senderImage || "404"}
            name={user?.name}
          />
        ) : (
          <MessageTextSquareIcon
            className={
              customerActivityCardCss.customerActivityDataConversationIcon
            }
          />
        )}
      </Flex>
    );

    const getExtraDetailsElement = (): ReactNode | undefined => {
      return (
        <Flex mt="sm">
          <Text fontSize="xs">{conversationActivity.message}</Text>
        </Flex>
      );
    };

    return (
      <BaseConversationActivityCard
        extraDetailsElement={getExtraDetailsElement()}
        timestamp={conversationActivity.timestamp}
        topLeftElement={iconElement}
      />
    );
  },
);
