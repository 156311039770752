import { z } from "zod";
import { CustomerEventType } from "../customer-event/customer-event-definition";

// Common schemas
const aggregationTypeSchema = z.enum(["count", "sum", "avg", "min", "max"]);
const timeframeUnitSchema = z.enum(["day", "week", "month", "year"]);

// Value schemas
const constantValueSchema = z.object({
  type: z.literal("constant"),
  value: z.union([z.string(), z.number()]),
});

const interpolationValueSchema = z.object({
  type: z.literal("interpolation"),
  path: z.string(),
});

const aggregationOptionsSchema = z
  .object({
    field: z.string().optional(),
  })
  .optional();

const aggregationValueSchema = z.object({
  type: z.literal("aggregation"),
  aggregation: aggregationTypeSchema,
  options: aggregationOptionsSchema,
});

const afterTimeframeSchema = z.object({
  type: z.literal("after"),
  options: z.object({
    date: z.string().date(),
  }),
});

const beforeTimeframeSchema = z.object({
  type: z.literal("before"),
  options: z.object({
    date: z.string().date(),
  }),
});

const allTimeTimeframeSchema = z.object({
  type: z.literal("all-time"),
  options: z.null(),
});

const betweenRelativeTimeframeSchema = z.object({
  type: z.literal("between-relative"),
  options: z
    .object({
      start: z.number(),
      end: z.number(),
      units: timeframeUnitSchema,
    })
    .refine(({ start, end }) => start < end, {
      message: "Start value must be less than end value",
      path: ["options"],
    }),
});

const betweenTimeframeSchema = z.object({
  type: z.literal("between"),
  options: z
    .object({
      startDate: z.string().date(),
      endDate: z.string().date(),
    })
    .refine(
      ({ startDate, endDate }) => new Date(startDate) < new Date(endDate),
      {
        message: "Start date must be before end date",
        path: ["options"],
      },
    ),
});

// Combined timeframe schema
const timeframeSchema = z.discriminatedUnion("type", [
  afterTimeframeSchema,
  beforeTimeframeSchema,
  allTimeTimeframeSchema,
  betweenRelativeTimeframeSchema,
  betweenTimeframeSchema,
]);

const eventOperatorSchema = z.enum(["lt", "lte", "eq", "gte", "gt", "neq"]);
const attributeOperatorSchema = z.enum(["eq", "neq"]);

// Condition schemas
const baseConditionSchema = z.object({
  type: z.string(),
});

// Individual condition schemas
const customerAttributeConditionSchema = baseConditionSchema.extend({
  type: z.literal("customer-attribute"),
  left: interpolationValueSchema,
  operator: attributeOperatorSchema,
  right: constantValueSchema,
});

const eventFilterConditionSchema = baseConditionSchema.extend({
  type: z.literal("event-filter"),
  left: interpolationValueSchema,
  operator: attributeOperatorSchema,
  right: constantValueSchema,
});

const customerEventConditionSchema = baseConditionSchema.extend({
  type: z.literal("customer-events"),
  event: z.nativeEnum(CustomerEventType),
  left: aggregationValueSchema,
  operator: eventOperatorSchema,
  right: constantValueSchema,
  timeframe: timeframeSchema,
  event_filters: eventFilterConditionSchema.optional(),
});

const customerGroupMembershipConditionSchema = baseConditionSchema.extend({
  type: z.literal("customer-group-membership"),
  group: z.string(),
  operator: z.enum(["in", "not_in"]),
});

// Need to declare schema type ahead due to recursive nature
type ConjunctionConditionSchemaType = z.ZodObject<any>;
const conjunctionConditionSchema: ConjunctionConditionSchemaType =
  baseConditionSchema.extend({
    type: z.literal("conjunction"),
    operator: z.enum(["and", "or"]),
    conditions: z.lazy(() => queryConditionSchema.array()),
  });

// // Now we can define the conjunction schema
// const conjunctionConditionSchema = baseConditionSchema.extend({
//   type: z.literal("conjunction"),
//   operator: z.enum(["and", "or"]),
//   conditions: z.lazy(() => queryConditionSchema.array()),
// });

const staticConjunctionConditionSchema = baseConditionSchema.extend({
  type: z.literal("conjunction"),
  operator: z.enum(["and", "or"]),
  conditions: z.lazy(() => queryConditionSchema.array()),
});

// Union of all condition types
const queryConditionSchema = z.discriminatedUnion("type", [
  conjunctionConditionSchema,
  customerAttributeConditionSchema,
  customerEventConditionSchema,
  customerGroupMembershipConditionSchema,
]);

// Export type definitions
export type EventOperator = z.infer<typeof eventOperatorSchema>;
export type AttributeOperator = z.infer<typeof attributeOperatorSchema>;
export type TimeframeUnit = z.infer<typeof timeframeUnitSchema>;
export type AggregationType = z.infer<typeof aggregationTypeSchema>;
export type ConstantValue = z.infer<typeof constantValueSchema>;
export type AggregationValue = z.infer<typeof aggregationValueSchema>;
export type Timeframe = z.infer<typeof timeframeSchema>;
export type CustomerAttributeCondition = z.infer<
  typeof customerAttributeConditionSchema
>;
export type EventFilterCondition = z.infer<typeof eventFilterConditionSchema>;
export type CustomerEventCondition = z.infer<
  typeof customerEventConditionSchema
>;
export type ConjunctionCondition = z.infer<
  typeof staticConjunctionConditionSchema
>;
export type CustomerGroupMembershipCondition = z.infer<
  typeof customerGroupMembershipConditionSchema
>;
export type QueryCondition = z.infer<typeof queryConditionSchema>;

// Export schemas
export const schemas = {
  queryCondition: queryConditionSchema,
  customerEventCondition: customerEventConditionSchema,
  customerAttributeCondition: customerAttributeConditionSchema,
  eventFilterCondition: eventFilterConditionSchema,
  customerGroupMembershipCondition: customerGroupMembershipConditionSchema,
  conjunction: conjunctionConditionSchema,
  operator: eventOperatorSchema,
  timeframe: timeframeSchema,
  timeframeUnit: timeframeUnitSchema,
  aggregationType: aggregationTypeSchema,
  constantValue: constantValueSchema,
  aggregationValue: aggregationValueSchema,
};

export function isCustomerEventCondition(
  condition: QueryCondition,
): condition is CustomerEventCondition {
  return condition.type === "customer-events";
}

export function isCustomerAttributeCondition(
  condition: QueryCondition,
): condition is CustomerAttributeCondition {
  return condition.type === "customer-attribute";
}

export function isEventFilterCondition(
  condition: QueryCondition,
): condition is EventFilterCondition {
  return condition.type === "event-filter";
}

export function isConjunctionCondition(
  condition: QueryCondition,
): condition is ConjunctionCondition {
  return condition.type === "conjunction";
}

export function isCustomerGroupMembershipCondition(
  condition: QueryCondition,
): condition is CustomerGroupMembershipCondition {
  return condition.type === "customer-group-membership";
}

// example condition:
// {
//   type: "conjunction",
//   operator: "and",
//   conditions: [
//     {
//       type: "conjunction",
//       operator: "or",
//       conditions: [
//         {
//           type: "customer-attribute",
//           left: {
//             type: "interpolation",
//             path: "subscribed",
//           },
//           operator: "eq",
//           right: {
//             type: "constant",
//             value: "true",
//           },
//         },
//         {
//           type: "customer-events",
//           event: "CONVERSATION_CREATED",
//           left: {
//             type: "aggregation",
//             aggregation: "count",
//             options: {
//               field: "email",
//             },
//           },
//           operator: "gt",
//           right: {
//             type: "constant",
//             value: 0,
//           },
//           timeframe: {
//             type: "all-time",
//             options: null,
//           },
//         },
//       ],
//     },
//     {
//       type: "customer-events",
//       event: "ORDER_CREATED",
//       left: {
//         type: "aggregation",
//         aggregation: "count",
//         options: {
//           field: "email",
//         },
//       },
//       operator: "lt",
//       right: {
//         type: "constant",
//         value: 4,
//       },
//       timeframe: {
//         type: "all-time",
//         options: null,
//       },
//     },
//   ],
// };
