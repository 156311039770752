import {
  BenchmarkStatsDataType,
  IndustryCategoryComparisonStatsType,
} from "@redotech/redo-model/team-industry-category/industry-comparison-stats";
import { z } from "zod";

const comparisonStatisticValue = z.number().optional();

const comparisonStatistic = z.object({
  title: z.string(),
  merchantValue: comparisonStatisticValue,
  industryValue: comparisonStatisticValue,
  type: z.nativeEnum(BenchmarkStatsDataType),
  lowerIsBetter: z.boolean(),
});

export const getIndustryCategoryComparisonStats = {
  input: z.object({
    teamId: z.string(),
    categoryId: z.optional(z.string()),
    dateRange: z.object({
      startDate: z.date(),
      endDate: z.date(),
    }),
  }),
  output: z.object({
    statistics: z.record(
      z.nativeEnum(IndustryCategoryComparisonStatsType),
      comparisonStatistic,
    ),
  }),
};
