import {
  isOrderCreatedCustomerEvent,
  isOrderStatusUpdatedCustomerEvent,
  isOrderUpdatedCustomerEvent,
} from "@redotech/redo-model/customer-activity/customer-activity-definition";
import { IOrderCustomerEvent } from "@redotech/redo-model/customer-event/customer-event-definition";
import PackageIcon from "@redotech/redo-web/arbiter-icon/package.svg";
import { Flex } from "@redotech/redo-web/flex";
import { OrderStatusPill } from "@redotech/redo-web/order-status-pill";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext } from "react";
import { TeamContext } from "../../../../app/team";
import * as customerActivityCardCss from "../../../../common/activity-card.module.css";
import { BaseActivityCard } from "../../../../common/base-activity-card";
import { shopifyAdminOrderUrl, shopifyStoreName } from "../../../../shopify";

export const OrderCustomerActivityCard = memo(
  function OrderCustomerActivityCard({
    customerEvent,
  }: {
    customerEvent: IOrderCustomerEvent;
  }) {
    const team = useContext(TeamContext);

    const iconElement = (
      <Flex
        align="center"
        className={
          customerActivityCardCss.customerActivityDataOrderIconContainer
        }
        justify="center"
      >
        <PackageIcon
          className={customerActivityCardCss.customerActivityDataOrderIcon}
        />
      </Flex>
    );

    const getTitle = (): string => {
      if (isOrderCreatedCustomerEvent(customerEvent)) {
        return "New order: " + customerEvent.orderName;
      } else if (isOrderStatusUpdatedCustomerEvent(customerEvent)) {
        return "Order status udpated";
      } else if (isOrderUpdatedCustomerEvent(customerEvent)) {
        return "Order edited";
      } else {
        return "";
      }
    };

    const getExtraDetailsElement = () => {
      if (isOrderCreatedCustomerEvent(customerEvent)) {
        return (
          <>
            <Text fontSize="sm" textColor="secondary">
              {customerEvent.itemCount} x items
            </Text>
            <Text fontSize="sm" textColor="secondary">
              ${customerEvent.totalPrice} total
            </Text>
          </>
        );
      } else if (isOrderStatusUpdatedCustomerEvent(customerEvent)) {
        return (
          <Flex mt="lg">
            <OrderStatusPill orderStatus={customerEvent.newStatus} />
          </Flex>
        );
      } else {
        return undefined;
      }
    };

    const onCardClick = () => {
      open(
        shopifyAdminOrderUrl(
          shopifyStoreName(team!.storeUrl),
          customerEvent.shopifyId,
        ),
        "_blank",
      );
    };

    return (
      <BaseActivityCard
        extraDetailsElement={getExtraDetailsElement()}
        onClick={onCardClick}
        timestamp={customerEvent.timestamp}
        title={getTitle()}
        topLeftElement={iconElement}
      />
    );
  },
);
