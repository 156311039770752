import { SchemaInstance, defineSchema } from "../../type-system/schema";
import {
  baseTrackingSchema,
  exampleBaseTrackingSchemaInstance,
} from "./base-tracking";
import { exampleOrderTrackingSchemaInstance } from "./order-tracking";

export const returnTrackingSchema = defineSchema({
  fields: {
    ...baseTrackingSchema.fields,
    returnPackingInstructions: {
      dataType: "Maybe Multiple Text",
      documentation: "Instructions for returning the items",
    },
    emailAttachments: {
      dataType: "Multiple Email Attachment",
      documentation: "The attached items in a return confirmation email",
    },
    returnRejectedReason: {
      dataType: "Maybe Text",
      documentation: "The reason the return was rejected",
    },
    packagePickupDayOfWeek: {
      dataType: "Maybe Text",
      documentation: "The day of the week the package will be picked up",
    },
    packagePickupDetail: {
      dataType: "Maybe Text",
      documentation: "Details for picking up the package",
    },
    returnDetailsLink: {
      dataType: "Url",
      documentation: "The link to view the return details",
    },
    completeExchangeLink: {
      dataType: "Maybe Url",
      documentation: "The link to complete an exchange",
    },
    pickupSurveyLink: {
      dataType: "Maybe Url",
      documentation: "The link to a survey about the pickup experience",
    },
    pickupRescheduleLink: {
      dataType: "Maybe Url",
      documentation: "The link to reschedule a pickup",
    },
    pickupCancelLink: {
      dataType: "Maybe Url",
      documentation: "The link to cancel a pickup",
    },
  },
});

export const exampleReturnTrackingSchemaInstance: SchemaInstance<
  typeof returnTrackingSchema
> = {
  ...exampleBaseTrackingSchemaInstance,
  // order in name for backwards compatibility
  orderSummary: {
    ...exampleBaseTrackingSchemaInstance.orderSummary,
    trackableType: {
      lowerCase: "return",
      titleCase: "Return",
      verb: "returned",
    },
  },
  tracker: {
    ...exampleOrderTrackingSchemaInstance.tracker,
    trackableType: {
      lowerCase: "return",
      titleCase: "Return",
      verb: "returned",
    },
  },
  returnPackingInstructions: [
    "Print out the return label",
    "Pack the items in a box",
    "Drop off the box at the nearest post office",
  ],
  emailAttachments: [],
  returnRejectedReason:
    "The return was rejected because the items were damaged",
  packagePickupDayOfWeek: "Monday",
  packagePickupDetail: "at the front door",
  returnDetailsLink: "https://example.com/return-details",
  completeExchangeLink: "https://example.com/complete-exchange",
  pickupSurveyLink: "https://example.com/pickup-survey",
  pickupRescheduleLink: "https://example.com/pickup-reschedule",
  pickupCancelLink: "https://example.com/pickup-cancel",
};
