import { MerchantPolicyProduct } from "@redotech/redo-model/merchant-policies/merchant-policy-db-parser";
import { array, nativeEnum, object, string, null as zNull } from "zod";

export const editMerchantPolicy = {
  input: object({
    policyId: string(),
    title: string().optional(),
    text: string(),
    productsApplicable: array(nativeEnum(MerchantPolicyProduct)),
  }),
  output: zNull(),
};
