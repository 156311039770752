// extracted by mini-css-extract-plugin
export var actions = "_2q-0";
export var actionsContainer = "_2q-3";
export var border = "_2q-1";
export var collapse = "_2q-2";
export var compact = "_2q-c";
export var content = "_2q-4";
export var contentChild = "_2q-8";
export var fullscreen = "_2q-6";
export var header = "_2q-9";
export var headerContainer = "_2q-e";
export var hideHeaderBorder = "_2q-d";
export var hidePadding = "_2q-5";
export var noScrollX = "_2q-f";
export var noScrollY = "_2q-g";
export var optOutTopPadding = "_2q-7";
export var profile = "_2q-h";
export var profileContainer = "_2q-i";
export var profileImage = "_2q-j";
export var profileMain = "_2q-k";
export var profileSubtitle = "_2q-m";
export var profileTitle = "_2q-l";
export var scrolledTop = "_2q-b";
export var tabsWrapper = "_2q-o";
export var title = "_2q-n";
export var withTabs = "_2q-a";