import { z } from "zod";

export const recreateDiscounts = {
  input: z.object({
    discountCodes: z.array(z.string()),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};
