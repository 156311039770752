// extracted by mini-css-extract-plugin
export var animationContainer = "_7n-0";
export var conversationContainer = "_7n-1";
export var detailsContent = "_7n-2";
export var message = "_7n-3";
export var modalContent = "_7n-4";
export var modalFooter = "_7n-6";
export var modalHeader = "_7n-5";
export var name = "_7n-7";
export var optionContainer = "_7n-8";
export var optionTitle = "_7n-9";
export var pillContainer = "_7n-a";
export var selectedConversationsContainer = "_7n-b";
export var selectedOptionContainer = "_7n-c";