import { ReviewStatus } from "@redotech/redo-model/review/review-field-schema";
import { z } from "zod";

export const updateReview = {
  input: z.object({
    _id: z.string(),
    teamId: z.string(),
    status: z.optional(z.nativeEnum(ReviewStatus)),
    verifiedPurchase: z.optional(z.boolean()),
  }),
  output: z.literal("success"),
};
