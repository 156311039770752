import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import type { Return } from "@redotech/redo-model/return";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Card, CardSize } from "@redotech/redo-web/card";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useMemo } from "react";
import { useDebounce } from "usehooks-ts";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import { TeamContext } from "../../app/team";
import { shopifyAdminDraftOrderUrl, shopifyStoreName } from "../../shopify";

export const RepairInvoiceCard = memo(function RepairInvoiceCard({
  return: return_,
}: {
  return?: Return;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const team = useContext(TeamContext);
  const { formatCurrency } = useContext(CurrencyContext);

  const draftOrderURL = useMemo(() => {
    if (!return_ || !team) return undefined;

    const globalInvoiceId = return_.repair?.invoiceId;
    const invoiceId = globalInvoiceId?.split("/")?.pop();

    if (!invoiceId) return undefined;

    return shopifyAdminDraftOrderUrl(
      shopifyStoreName(team.storeUrl),
      invoiceId,
    ).toString();
  }, [return_]);

  const navigateToDraftOrder = async () => {
    if (!draftOrderURL) return;

    window.open(draftOrderURL, "_blank");
  };

  const repairInvoiceLoad = useLoad(async () => {
    if (!return_) return undefined;

    return await rpcClient.getRepairInvoiceDetails({
      returnId: return_?.id,
    });
  }, [return_]);

  const [sendRepairInvoiceLoad, triggerSendRepairInvoice] = useTriggerLoad(
    async () => {
      if (!return_) return undefined;

      await rpcClient.sendRepairInvoice({
        returnId: return_.id,
      });

      return {
        sent: true,
      };
    },
  );

  const handleSendRepairInvoice = useHandler(async () => {
    triggerSendRepairInvoice();
  });

  const repairInvoice = repairInvoiceLoad.value;

  const repairInvoiceError = repairInvoiceLoad.error;
  const repairInvoiceLoading =
    repairInvoiceLoad.pending || (!repairInvoice && !repairInvoiceError);

  const sendRepairInvoiceLoading = sendRepairInvoiceLoad.pending;
  const repairInvoiceSent = sendRepairInvoiceLoad.value?.sent;

  const debounceRepairInvoiceSent = useDebounce(repairInvoiceSent, 1000);

  return (
    <Card size={CardSize.MEDIUM} title="Repair invoice">
      {repairInvoiceLoading ? (
        <SkeletonText length={30} />
      ) : repairInvoiceError ? (
        <Text color="error">{repairInvoiceError.message}</Text>
      ) : (
        <>
          <RedoBadge
            color={
              repairInvoice?.paid ? RedoBadgeColor.SUCCESS : RedoBadgeColor.GRAY
            }
            size={RedoBadgeSize.MEDIUM}
            text={repairInvoice?.paid ? "Paid" : "Unpaid"}
          />
          <Flex flexDirection="row">
            <ExternalLink url={draftOrderURL}>
              {repairInvoice?.name}
            </ExternalLink>
            <Text>
              {repairInvoice?.total
                ? `- ${formatCurrency(repairInvoice?.total)}`
                : ""}
            </Text>
          </Flex>
          <Flex flexDirection="row" gap="lg" justify="center">
            <Button
              fullWidth
              onClick={navigateToDraftOrder}
              size={ButtonSize.MICRO}
              theme={ButtonTheme.OUTLINED}
            >
              {repairInvoice?.paid ? "View invoice" : "Edit invoice"}
            </Button>
            {!repairInvoice?.paid && (
              <Button
                fullWidth
                onClick={handleSendRepairInvoice}
                pending={sendRepairInvoiceLoading}
                size={ButtonSize.MICRO}
                theme={ButtonTheme.PRIMARY}
              >
                {repairInvoiceSent && !debounceRepairInvoiceSent
                  ? "Sent!"
                  : debounceRepairInvoiceSent
                    ? "Resend invoice"
                    : "Send invoice"}
              </Button>
            )}
          </Flex>
        </>
      )}
    </Card>
  );
});
