import { useParam } from "@redotech/react-router-util/param";
import { IterableMap, genericMemo } from "@redotech/react-util/component";
import { useScrolled } from "@redotech/react-util/scroll";
import { TableSort } from "@redotech/redo-model/table";
import {
  ForwardedRef,
  MouseEvent,
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { TableFetcher, YieldType, sortEqual, sortParam } from "./table";

export interface CardClickHandler<T> {
  (record: T, event: MouseEvent<HTMLTableRowElement>, idx: number): void;
}

export interface CardListRef<T> {
  refresh: () => Promise<void>;
  getNext: (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => T | undefined;
  getPrevious: (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => T | undefined;
  removeItem: (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => void;
  externalRefresh: (refreshCallback: (items: T[]) => void) => Promise<void>;
  externalSetItems: (items: T[]) => void;
}

export interface CardListCardProps<T> {
  item?: T;
  onClick?: (event: MouseEvent<HTMLTableRowElement>) => void;
  loading?: boolean;
  empty?: boolean;
  isActive?: (item: T) => boolean;
  key?: string;
}

export type CardListCard<T> = (props: CardListCardProps<T>) => ReturnType<any>;

type CardListProps<T> = {
  onCardClick?: CardClickHandler<T>;
  fetcher: TableFetcher<T>;
  card: CardListCard<T>;
  sortDefault: TableSort;
  passThroughValues?: any;
  isCardActive?: (item: T) => boolean;
  scrollAreaRef: RefObject<HTMLElement>;
  inactive?: boolean;
};

function CardListComponent<T>(
  {
    onCardClick,
    fetcher,
    card,
    sortDefault,
    passThroughValues,
    isCardActive,
    scrollAreaRef,
    inactive,
  }: CardListProps<T>,
  ref: ForwardedRef<CardListRef<T>>,
) {
  const [sort, setSort] = useParam(sortParam(sortDefault), sortEqual);

  return (
    <div>
      <CardListContent
        card={card}
        fetcher={fetcher}
        inactive={inactive}
        isCardActive={isCardActive}
        onCardClick={onCardClick}
        passThroughValues={passThroughValues}
        ref={ref}
        scrollAreaRef={scrollAreaRef}
        setSort={setSort}
        sort={sort}
      />
    </div>
  );
}

export const CardList = genericMemo(
  forwardRef(CardListComponent) as <T>(
    props: CardListProps<T> & {
      ref: React.ForwardedRef<CardListRef<T>>;
    },
  ) => ReturnType<typeof CardListComponent>,
);

type CardListContentProps<T> = {
  fetcher: TableFetcher<T>;
  card: CardListCard<T>;
  onCardClick?: CardClickHandler<T>;
  sort: TableSort;
  setSort(value: TableSort): void;
  passThroughValues?: any;
  isCardActive?: (item: T) => boolean;
  scrollAreaRef: RefObject<HTMLElement>;
  inactive?: boolean;
};

function CardListContentComponent<T>(
  {
    fetcher,
    card,
    onCardClick,
    sort,
    setSort,
    passThroughValues,
    isCardActive,
    scrollAreaRef,
    inactive,
  }: CardListContentProps<T>,
  ref: ForwardedRef<CardListRef<T>>,
) {
  const scrolled = useScrolled(scrollAreaRef.current);
  const [items, setItems] = useState<T[]>([]);
  const [iterator, setIterator] = useState<AsyncIterator<YieldType<T>>>();
  const [pending, setPending] = useState(false);
  const [finished, setFinished] = useState(false);
  const [abort, setAbort] = useState(new AbortController());
  const [refresh, setRefresh] =
    useState<(signal: AbortSignal) => Promise<T[]>>();
  const [activeCardIndex, setActiveCardIndex] = useState(-1);

  useEffect(() => {
    abort.abort();
    const newAbort = new AbortController();
    setAbort(newAbort);
    const signal = newAbort.signal;
    setPending(false);
    setFinished(false);
    setItems([]);
    setIterator(
      fetcher.data(
        "",
        {},
        undefined,
        sort,
        25,
        undefined,
        signal,
        passThroughValues,
      ),
    );
    if (iterator?.return) {
      void iterator.return();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, sort, passThroughValues]);

  const loadMoreIfNecessary = async () => {
    if (
      !inactive &&
      // if not in first 150 results we assume it's not in the current filters being applied, so stop loading infinitely
      (scrolled.bottom || (activeCardIndex === -1 && items.length < 150)) &&
      !pending &&
      !finished &&
      iterator
    ) {
      setPending(true);
      let done: boolean;
      let value: YieldType<T>;
      let aborted: boolean;
      try {
        const results = await iterator.next();
        done = results.done || false;
        value = results.value;
        aborted = value?.aborted || false;
        if (aborted) return; // Pending has already been restored to false
      } catch (e) {
        setPending(false);
        throw e;
      }
      setPending(false);
      if (done) {
        if (iterator.return) {
          await iterator.return();
        }
        if (!aborted) {
          setFinished(true);
        }
        return;
      }
      setRefresh(() => value.refresh);
      setItems((items) => [...items, ...value.items]);
    }
  };

  useEffect(() => {
    void loadMoreIfNecessary();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterator, scrolled.bottom, pending, inactive]);

  useEffect(() => {
    const activeCard = document.getElementById(`card${activeCardIndex}`);
    activeCard?.scrollIntoView({ behavior: "smooth", block: "end" });
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCardIndex >= 0]);

  const refreshData = async () => {
    if (refresh) {
      setAbort(new AbortController());
      const reloadedItems = await refresh(abort.signal);
      setItems(reloadedItems);
    }
  };

  const externalRefresh = async (setItemsCallback: (items: T[]) => void) => {
    if (refresh) {
      const reloadedItems = await refresh(abort.signal);
      setItemsCallback(reloadedItems);
    }
  };

  const getNext = (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => {
    const index = items.findIndex((item) => areEqual(item, currentItem));
    if (index === -1 || index === items.length - 1) {
      return;
    }
    return items[index + 1];
  };

  const getPrevious = (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => {
    const index = items.findIndex((item) => areEqual(item, currentItem));
    if (index === -1 || index === 0) {
      return;
    }
    return items[index - 1];
  };

  const removeItem = (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => {
    const index = items.findIndex((item) => areEqual(item, currentItem));
    if (index === -1) {
      return;
    }
    setItems((items) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  const externalSetItems = (items: T[]) => {
    setItems(items);
  };

  useImperativeHandle(ref, () => ({
    refresh: refreshData,
    getNext,
    getPrevious,
    removeItem,
    externalRefresh,
    externalSetItems,
  }));

  return (
    <div>
      <IterableMap items={items || []} keyFn={(_, index) => index}>
        {(item, index) => {
          function onClick(event: MouseEvent<HTMLTableRowElement>) {
            onCardClick!(item, event, index);
          }
          if (isCardActive && isCardActive(item)) {
            setActiveCardIndex(index);
          }
          return (
            <div id={`card${index}`}>
              {card({ item, onClick, isActive: isCardActive })}
            </div>
          );
        }}
      </IterableMap>
      {pending &&
        [...Array(5)].map((_, index) =>
          card({ loading: true, key: `loading${index}` }),
        )}
      {!(items || []).length && !pending && card({ empty: true })}
    </div>
  );
}

const CardListContent = genericMemo(
  forwardRef(CardListContentComponent) as <T>(
    props: CardListContentProps<T> & {
      ref: React.ForwardedRef<CardListRef<T>>;
    },
  ) => ReturnType<typeof CardListContentComponent>,
);
