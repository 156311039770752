import { Navigate, RouteObject } from "react-router-dom";
import { inAnalyticsReportView, reportsRoutes } from "./reports/route";

export const analyticsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Analytics", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: <Page hidePadding={inAnalyticsReportView} />,
      };
    },
    children: [
      {
        index: true,
        element: <Navigate replace to="reports" />,
      },
      {
        path: "insights",
        handle: { breadcrumb: "AI Insights (Beta)" },
        async lazy() {
          const { Insights } = await import("./ai-insights/ai-insights");
          return { Component: Insights };
        },
      },
      {
        path: "reports",
        children: reportsRoutes,
      },
      {
        handle: { breadcrumb: "Operations" },
        path: "operations",
        lazy: async () => {
          const { OperationsAnalytics } = await import(
            "./operations/operations-analytics"
          );
          return { Component: OperationsAnalytics };
        },
      },
      {
        path: "returns",
        handle: { breadcrumb: "Returns" },
        async lazy() {
          const { ReturnAnalytics: Analytics } = await import(
            "./returns/return-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        // Old claims path
        path: "claims-summary",
        element: <Navigate to="../claims" />,
      },
      {
        handle: { breadcrumb: "Claims" },
        path: "claims",
        lazy: async () => {
          const { ClaimsSummary } = await import("./claims/claims-analytics");
          return { Component: ClaimsSummary };
        },
      },
      {
        path: "support",
        handle: { breadcrumb: "Support" },
        async lazy() {
          const { SupportAnalytics: Analytics } = await import(
            "./support/support-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "order-tracking",
        handle: { breadcrumb: "Order Tracking" },
        async lazy() {
          const { OrderTrackingAnalytics: Analytics } = await import(
            "./order-tracking/order-tracking-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "order-fulfillment",
        handle: { breadcrumb: "Order Fulfillment" },
        async lazy() {
          const { OutboundLabelsAnalytics: Analytics } = await import(
            "./outbound-labels/outbound-labels-analytics"
          );
          return { Component: Analytics };
        },
      },
    ],
  },
];
