// extracted by mini-css-extract-plugin
export var attachment = "_9h-a";
export var emailContainer = "_9h-4";
export var emailContentPaddedContainer = "_9h-b";
export var emailHeader = "_9h-6";
export var emailInfoIcon = "_9h-9";
export var emailShortenedSummary = "_9h-e";
export var infoDropdown = "_9h-0";
export var infoRowBoundedContent = "_9h-d";
export var internal = "_9h-5";
export var merchant = "_9h-8";
export var messageInputContainer = "_9h-h";
export var normal = "_9h-7";
export var replyCard = "_9h-f";
export var shadowDomWrapper = "_9h-3";
export var subtleButton = "_9h-1";
export var subtleButtonIconContainer = "_9h-2";
export var toggleViewRepliesButton = "_9h-c";
export var wrappingText = "_9h-g";