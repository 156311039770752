import {
  ConversationMessage,
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Attachment } from "@redotech/redo-model/create-conversation-body";
import {
  EmailDraftRecipientInfo,
  filterAndPreprocessEmailInfo,
} from "@redotech/redo-model/support/conversations/email-info";

// There should only be one internal draft in the conversation
export function getInternalDraftFromConversation(
  conversation: ExpandedConversation,
): ExpandedConversationMessage | undefined {
  return conversation.messages.find(
    (otherMessage) =>
      otherMessage.draftInfo?.isDraft &&
      otherMessage.visibility === MessageVisibility.INTERNAL,
  );
}

// Given an existing message, find the draft that is in reply to it
// There can be multiple public drafts in the conversation. One per message
export function getPublicDraftReplyingToMessage(
  conversation: ExpandedConversation,
  messageToReplyTo: ExpandedConversationMessage,
  isFirstMessageAfterPlatformConversion?: boolean,
): ExpandedConversationMessage | undefined {
  // If we are not working with emails there will only be one draft - the one that is in reply to the most recent message
  if (
    conversation.platform !== ConversationPlatform.EMAIL ||
    isFirstMessageAfterPlatformConversion
  ) {
    const msg = conversation.messages.find(
      (otherMessage) =>
        otherMessage.draftInfo?.isDraft &&
        otherMessage.visibility === MessageVisibility.PUBLIC,
    );
    return msg;
  }

  // If we are working with emails, we need to find the draft that is in reply to the email
  const result = conversation.messages.find(
    (otherMessage) =>
      ((otherMessage.draftInfo?.emailDraftInfo?.inReplyTo &&
        messageToReplyTo?.email?.messageId ===
          otherMessage.draftInfo?.emailDraftInfo?.inReplyTo) ||
        (otherMessage.draftInfo?.inReplyToMongoId &&
          messageToReplyTo._id === otherMessage.draftInfo?.inReplyToMongoId)) &&
      otherMessage.draftInfo?.isDraft &&
      otherMessage.visibility === MessageVisibility.PUBLIC,
  );
  return result;
}

export function getPrivateDraftFromConversation(
  conversation: ExpandedConversation,
  commentId: string,
  platform: ConversationPlatform,
): ExpandedConversationMessage | undefined {
  if (isInstagram(platform)) {
    return getPrivateInstagramDraftFromConversation(conversation, commentId);
  } else if (isFacebook(platform)) {
    return getPrivateFacebookDraftFromConversation(conversation, commentId);
  }
  return undefined;
}

function isInstagram(platform: ConversationPlatform) {
  return (
    platform === ConversationPlatform.INSTAGRAM_COMMENTS ||
    platform === ConversationPlatform.INSTAGRAM
  );
}

function isFacebook(platform: ConversationPlatform) {
  return (
    platform === ConversationPlatform.FACEBOOK_COMMENTS ||
    platform === ConversationPlatform.FACEBOOK
  );
}

export function getPrivateFacebookDraftFromConversation(
  dmConversation: ExpandedConversation,
  commentId: string,
): ExpandedConversationMessage | undefined {
  return dmConversation.messages.find(
    (otherMessage) =>
      otherMessage.draftInfo?.isDraft &&
      otherMessage.facebook?.comment?.commentId === commentId,
  );
}

export function getPrivateInstagramDraftFromConversation(
  dmConversation: ExpandedConversation,
  commentId: string,
): ExpandedConversationMessage | undefined {
  return dmConversation.messages.find(
    (otherMessage) =>
      otherMessage.draftInfo?.isDraft &&
      otherMessage.instagram?.privateRepliedComment?.commentId === commentId,
  );
}

export function getFirstDraftFromConversation(
  conversation: ExpandedConversation,
): ExpandedConversationMessage | undefined {
  return conversation.messages.find((message) => message.draftInfo?.isDraft);
}

export function draftAttachmentsToFakeFiles(
  attachments: Attachment[],
): ConversationMessage["files"] {
  return attachments.map((attachment) => {
    return {
      _id: attachment.url,
      url: attachment.url,
      description: attachment.description,
      mimeType: attachment.mimeType,
      originalContentId: undefined,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  });
}

export const filesToAttachments = (
  savedDraft: ConversationMessage | undefined,
): Attachment[] => {
  return (
    savedDraft?.files.map((file) => {
      return {
        url: file.url,
        description: file.description || "",
        mimeType: file.mimeType || "",
      };
    }) || []
  );
};

export function sameAttachments(savedUrls: string[], draftUrls: string[]) {
  if (savedUrls.length !== draftUrls.length) {
    return false;
  }
  return savedUrls.every((url) => draftUrls.includes(url));
}

export function sameRecipients(
  recipientsInfo: EmailDraftRecipientInfo,
  savedRecipientsInfo: EmailDraftRecipientInfo,
) {
  const recpientTo = recipientsInfo.to?.map((to) => to.email);
  const savedRecipientsTo = savedRecipientsInfo.to?.map((to) => to.email);
  const recipientCc = recipientsInfo.cc?.map((cc) => cc.email);
  const savedRecipientsCc = savedRecipientsInfo.cc?.map((cc) => cc.email);
  const recipientBcc = recipientsInfo.bcc?.map((bcc) => bcc.email);
  const savedRecipientsBcc = savedRecipientsInfo.bcc?.map((bcc) => bcc.email);
  const allMatch =
    recpientTo?.every((to) => savedRecipientsTo?.includes(to)) &&
    recipientCc?.every((cc) => savedRecipientsCc?.includes(cc)) &&
    recipientBcc?.every((bcc) => savedRecipientsBcc?.includes(bcc));
  return allMatch;
}

export function numRecipients(recipientsInfo: EmailDraftRecipientInfo) {
  return (
    (recipientsInfo.cc?.length || 0) +
    (recipientsInfo.bcc?.length || 0) +
    (recipientsInfo.to?.length || 0)
  );
}

export function getSavedRecipientsInfo(savedDraft: ConversationMessage) {
  return {
    bcc: filterAndPreprocessEmailInfo(savedDraft?.email?.bcc || []),
    cc: filterAndPreprocessEmailInfo(savedDraft?.email?.cc || []),
    to: filterAndPreprocessEmailInfo(savedDraft?.email?.to || []),
  };
}
