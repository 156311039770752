// extracted by mini-css-extract-plugin
export var acitonButtons = "_71-0";
export var block = "_71-1";
export var blockContent = "_71-7";
export var blockDescription = "_71-8";
export var blockIcon = "_71-9";
export var blockTitle = "_71-6";
export var border = "_71-a";
export var center = "_71-k";
export var edge = "_71-b";
export var edgeLabel = "_71-c";
export var edgePath = "_71-d";
export var error = "_71-e";
export var flowchart = "_71-f";
export var flowchartContent = "_71-g";
export var highlighted = "_71-3";
export var hover = "_71-4";
export var icon = "_71-h";
export var left = "_71-l";
export var navButton = "_71-i";
export var navButtons = "_71-j";
export var right = "_71-m";
export var selected = "_71-2";
export var small = "_71-5";