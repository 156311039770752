import { reviewFormDataDbWriteValidator } from "@redotech/redo-model/form-builder-form/db-validator/review/review-form-builder-form-db-validator";
import { z } from "zod";

export const createReviewForm = {
  input: z.object({
    teamId: z.string(),
    formData: reviewFormDataDbWriteValidator,
  }),
  output: z.null(),
};
