import * as classNames from "classnames";
import { memo, ReactElement } from "react";
import * as featuredIconColorCSS from "./redo-featured-icon-colors.module.css";
import * as featuredIconCSS from "./redo-featured-icon.module.css";

export enum FeaturedIconSize {
  XX_SMALL = "xxs",
  X_SMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  X_LARGE = "xl",
}

export enum FeaturedIconType {
  LIGHT = "light",
  DARK = "dark",
  MODERN = "modern",
}

export enum FeaturedIconColor {
  BRAND = "brand",
  GRAY = "gray",
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
  BLACK = "black",
}

export const RedoFeaturedIcon = memo(function RedoFeaturedIcon({
  icon,
  size = FeaturedIconSize.SMALL,
  type = FeaturedIconType.LIGHT,
  color = FeaturedIconColor.BRAND,
}: {
  icon: ReactElement;
  size?: FeaturedIconSize;
  type?: FeaturedIconType;
  color?: FeaturedIconColor | { customColorClassName: string };
}) {
  const variantClassnames = [
    featuredIconCSS[size],
    featuredIconCSS[type],
    featuredIconColorCSS[type],
    typeof color === "string"
      ? featuredIconColorCSS[color]
      : color.customColorClassName,
  ];

  return (
    <div
      className={classNames(featuredIconCSS.iconHighlight, variantClassnames)}
    >
      {icon}
    </div>
  );
});
