import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { JSXElementConstructor, memo } from "react";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
  RedoBadgeType,
} from "../arbiter-components/badge/redo-badge";
import Mail01Svg from "../arbiter-icon/mail-01.svg";
import PhoneIcon from "../arbiter-icon/phone-call.svg";
import PostscriptLogoIcon from "../arbiter-icon/postscript-favicon.svg";
import AttentiveLogoIcon from "../icon-old/attentive-logo.svg";
import ChatBubbleIcon from "../icon-old/chat-bubble.svg";
import FacebookIcon from "../icon-old/facebook-color.svg";
import InstagramIcon from "../icon-old/instagram-color.svg";
import LogoIcon from "../icon-old/logo-icon.svg";

const platformMappings: Record<
  ConversationPlatform,
  { text: string; icon: JSXElementConstructor<unknown>; color: RedoBadgeColor }
> = {
  redoChat: { text: "Redo Chat", icon: LogoIcon, color: RedoBadgeColor.BRAND },
  instagram: { text: "DM", icon: InstagramIcon, color: RedoBadgeColor.PINK },
  instagramComments: {
    text: "Comments",
    icon: InstagramIcon,
    color: RedoBadgeColor.PINK,
  },
  facebook: {
    text: "Messenger",
    icon: FacebookIcon,
    color: RedoBadgeColor.BLUE_LIGHT,
  },
  facebookComments: {
    text: "Comments",
    icon: FacebookIcon,
    color: RedoBadgeColor.BLUE_LIGHT,
  },
  email: { text: "Email", icon: Mail01Svg, color: RedoBadgeColor.GRAY },
  sms: {
    text: "Text message",
    icon: ChatBubbleIcon,
    color: RedoBadgeColor.SUCCESS,
  },
  attentive: {
    text: "Attentive",
    icon: AttentiveLogoIcon,
    color: RedoBadgeColor.WARNING,
  },
  postscript: {
    text: "Postscript",
    icon: PostscriptLogoIcon,
    color: RedoBadgeColor.PURPLE,
  },
  voice: {
    text: "Voice",
    icon: PhoneIcon,
    color: RedoBadgeColor.SUCCESS,
  },
};

export const RedoSupportChannelBadge = memo(function RedoSupportChannelBadge({
  platform,
  size = RedoBadgeSize.SMALL,
}: {
  platform: ConversationPlatform;
  size?: RedoBadgeSize;
}) {
  const { text, icon, color } = platformMappings[platform];

  return (
    <RedoBadge
      color={color}
      segmentLeading={{ type: "icon", Icon: icon }}
      size={size}
      text={text}
      type={RedoBadgeType.PRIMARY}
    />
  );
});
