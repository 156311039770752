// extracted by mini-css-extract-plugin
export var container = "_67-0";
export var content = "_67-3";
export var customerTitle = "_67-4";
export var empty = "_67-5";
export var gray = "_67-6";
export var header = "_67-7";
export var info = "_67-8";
export var loading = "_67-2";
export var loadingCard = "_67-9";
export var oneLine = "_67-a";
export var personName = "_67-e";
export var pillContainer = "_67-b";
export var selected = "_67-1";
export var text = "_67-c";
export var twoLines = "_67-d";
export var unread = "_67-f";
export var unreadIndicator = "_67-g";