import { Navigate, RouteObject } from "react-router-dom";

import { conciergeSettingsRoutes } from "./concierge/route";
import { faqRoutes, inFaqBuilder, inFaqHomeView } from "./faq/route";
import { aiSettingsRoutes } from "./general/ai/route";
import { upsellPageSettingsRoutes } from "./order-tracking-settings/route";
import { reviewsSettingsRoutes } from "./reviews-settings/route";

const fullscreen = (url: string) => {
  return (
    url.includes("tracking-page") ||
    (url.includes("ad-blocks/") && !url.endsWith("ad-blocks/"))
  );
};

const inForge = (url: string) => {
  return (
    (url.includes("reviews-displays-widgets/") &&
      !url.endsWith("reviews-displays-widgets/")) ||
    (url.includes("upsell-page/") && !url.endsWith("upsell-page/")) ||
    inFaqBuilder(url)
  );
};

export const settingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Settings", breadcrumbNavigate: false },
    lazy: async () => {
      const { Page } = await import("../app/page");
      return {
        element: (
          <Page
            hideHeader={(url) => fullscreen(url) || inForge(url)}
            hideHeaderBorder={inFaqHomeView}
            hideNavbar={(url) => fullscreen(url) || inForge(url)}
            hidePadding={inFaqHomeView}
          />
        ),
      };
    },
    children: [
      aiSettingsRoutes,
      conciergeSettingsRoutes,
      {
        handle: { breadcrumb: "Automations" },
        path: "automations",
        lazy: async () => {
          const { AutomationsPage } = await import("./automation");
          return { Component: AutomationsPage };
        },
      },
      {
        handle: { breadcrumb: "Bundles" },
        path: "bundles",
        lazy: async () => {
          const { BundlesPage } = await import("./bundles");
          return { Component: BundlesPage };
        },
      },
      {
        handle: { breadcrumb: "Billing" },
        path: "billing",
        lazy: async () => {
          const { BillingPage } = await import("./billing/billing");
          return { Component: BillingPage };
        },
      },
      {
        handle: { breadcrumb: "Customer widget" },
        path: "customer-widget",
        lazy: async () => {
          const { CustomerWidgetSettingsPage } = await import(
            "./customer-widget/customer-widget-settings"
          );
          return { Component: CustomerWidgetSettingsPage };
        },
      },
      faqRoutes,
      {
        handle: { breadcrumb: "Channels" },
        path: "channels",
        children: [
          {
            path: "",
            lazy: async () => {
              const { ChannelsPage } = await import("./channels");
              return { Component: ChannelsPage };
            },
          },
          {
            handle: { breadcrumb: "Support Email" },
            path: "supportEmail",
            lazy: async () => {
              const { SupportEmailInfo } = await import("./support-email-info");
              return { Component: SupportEmailInfo };
            },
          },
          {
            handle: { breadcrumb: "Facebook" },
            path: "facebook-connect",
            async lazy() {
              const { Connect } = await import("../support/facebook/connect");
              return { element: <Connect /> };
            },
          },
          {
            handle: { breadcrumb: "Facebook" },
            path: "facebook-settings",
            async lazy() {
              const { FacebookSettings } = await import(
                "../setting/channel/facebook-settings"
              );
              return { element: <FacebookSettings /> };
            },
          },
          {
            handle: { breadcrumb: "Instagram" },
            path: "instagram-connect",
            async lazy() {
              const { Connect } = await import("../support/instagram/connect");
              return { element: <Connect /> };
            },
          },
          {
            handle: { breadcrumb: "Instagram" },
            path: "instagram-settings",
            async lazy() {
              const { InstagramSettings } = await import(
                "../setting/channel/instagram-settings"
              );
              return { element: <InstagramSettings /> };
            },
          },
          {
            handle: { breadcrumb: "Voice" },
            path: "voice-settings",
            async lazy() {
              const { VoiceSettings } = await import(
                "../setting/channel/voice-settings"
              );
              return { element: <VoiceSettings /> };
            },
          },
          {
            handle: { breadcrumb: "Gmail" },
            path: "gmail-connect",
            async lazy() {
              const { Connect } = await import("../support/gmail/connect");
              return { element: <Connect /> };
            },
          },
          {
            handle: { breadcrumb: "Outlook" },
            path: "outlook-connect",
            async lazy() {
              const { Connect } = await import("../support/outlook/connect");
              return { element: <Connect /> };
            },
          },
          {
            path: "meta-callback",
            async lazy() {
              const { Callback } = await import("../support/meta-callback");
              return { element: <Callback /> };
            },
          },
          {
            handle: { breadcrumb: "Attentive" },
            path: "attentive-connect",
            async lazy() {
              const { Connect } = await import("../support/attentive/connect");
              return { element: <Connect /> };
            },
          },
          {
            handle: { breadcrumb: "Postscript" },
            path: "postscript-connect",
            async lazy() {
              const { Connect } = await import("../support/postscript/connect");
              return { element: <Connect /> };
            },
          },
          {
            path: "attentive-callback",
            async lazy() {
              const { Callback } = await import(
                "../support/attentive-callback"
              );
              return { element: <Callback /> };
            },
          },
          {
            path: "gmail-callback",
            async lazy() {
              const { Callback } = await import("../support/gmail-callback");
              return { element: <Callback /> };
            },
          },
          {
            path: "outlook-callback",
            async lazy() {
              const { Callback } = await import("../support/outlook-callback");
              return { element: <Callback /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Communication" },
        path: "communication",
        lazy: async () => {
          const { CommunicationPage } = await import("./communication");
          return { Component: CommunicationPage };
        },
      },
      {
        handle: { breadcrumb: "Coverage" },
        path: "coverage",
        lazy: async () => {
          const { CoveragePage: CoverageSettings } = await import(
            "./coverage/coverage"
          );
          return { Component: CoverageSettings };
        },
      },
      {
        handle: { breadcrumb: "Developer" },
        path: "developer",
        lazy: async () => {
          const { DeveloperPage } = await import("./developer");
          return { Component: DeveloperPage };
        },
      },
      {
        handle: { breadcrumb: "Exchanges" },
        path: "exchanges",
        lazy: async () => {
          const { ExchangesPage: Exchanges } = await import("./exchanges");
          return { Component: Exchanges };
        },
      },
      {
        handle: { breadcrumb: "Invoices" },
        path: "invoices",
        lazy: async () => {
          const { Payouts } = await import("../billing/payouts");
          return { Component: Payouts };
        },
      },
      {
        handle: { breadcrumb: "Notifications" },
        path: "notifications",
        lazy: async () => {
          const { NotificationsPage } = await import("./notifications");
          return { Component: NotificationsPage };
        },
      },
      {
        handle: { breadcrumb: "Tracking Page" },
        path: "tracking-page",
        lazy: async () => {
          const { TrackingPage } = await import(
            "./order-tracking-settings/tracking-page/tracking-page"
          );
          return { Component: TrackingPage };
        },
      },
      reviewsSettingsRoutes,
      {
        handle: { breadcrumb: "Email Domain" },
        path: "email-domain",
        lazy: async () => {
          const { EmailDomainPage } = await import("./email-domain");
          return { Component: EmailDomainPage };
        },
      },
      {
        handle: { breadcrumb: "Parcels" },
        path: "parcels",
        lazy: async () => {
          const { ParcelsPage: Parcels } = await import("./parcels");
          return { Component: Parcels };
        },
      },
      {
        handle: { breadcrumb: "Carriers" },
        path: "carriers",
        lazy: async () => {
          const { CarriersPage: Carriers } = await import(
            "./carrier-accounts/carriers"
          );
          return { Component: Carriers };
        },
      },
      {
        handle: { breadcrumb: "End shippers" },
        path: "end-shippers",
        lazy: async () => {
          const { EndShippersPage: EndShippers } = await import(
            "./end-shippers/end-shippers-page"
          );
          return { Component: EndShippers };
        },
      },
      {
        handle: { breadcrumb: "Order Fulfillment Balance" },
        path: "balance",
        lazy: async () => {
          const { OutboundLabelBalancePage } = await import(
            "./outbound-label-balance"
          );
          return { Component: OutboundLabelBalancePage };
        },
      },
      {
        handle: { breadcrumb: "Shipping" },
        path: "shipping",
        lazy: async () => {
          const { ShippingPage } = await import("./shipping");
          return { Component: ShippingPage };
        },
      },
      {
        handle: { breadcrumb: "Integrations" },
        path: "integrations",
        children: [
          {
            path: "",
            lazy: async () => {
              const { IntegrationsPage } = await import("./integrations");
              return { Component: IntegrationsPage };
            },
          },
          {
            handle: { breadcrumb: "Comment Sold" },
            path: "comment-sold",
            lazy: async () => {
              const { CommentSoldPage } = await import("./comment-sold");
              return { Component: CommentSoldPage };
            },
          },
          {
            handle: { breadcrumb: "ShipBob" },
            path: "shipbob",
            lazy: async () => {
              const { Connect } = await import("./channel/shipbob-connect");
              return { Component: Connect };
            },
          },
        ],
      },
      // We moved /locations -> /shipping, so we'll redirect users just in case.
      {
        path: "locations",
        element: <Navigate replace to="../shipping" />,
      },
      {
        handle: { breadcrumb: "Return Flow" },
        path: "return-flow",
        children: [
          {
            path: "",
            lazy: async () => {
              const { ReturnFlow } = await import("./return-flow/return-flow");
              return { element: <ReturnFlow /> };
            },
          },
          {
            handle: { breadcrumb: "AB Test Analytics" },
            path: "ab-test-analytics",
            lazy: async () => {
              const { ReturnFlowABTestAnalytics } = await import(
                "./return-flow/ab-test-analytics"
              );
              return { element: <ReturnFlowABTestAnalytics /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Claim Flow" },
        path: "claim-flow",
        lazy: async () => {
          const { ClaimFlow } = await import("./claim-flow");
          return { element: <ClaimFlow /> };
        },
      },
      {
        handle: { breadcrumb: "Warranty Flow" },
        path: "warranty-flow",
        lazy: async () => {
          const { WarrantyFlow } = await import("./warranty-flow");
          return { element: <WarrantyFlow /> };
        },
      },
      {
        handle: { breadcrumb: "Chat Logic Flow" },
        path: "chat-flow",
        lazy: async () => {
          const { ChatFlow } = await import("./chat-flow");
          return { element: <ChatFlow /> };
        },
      },
      {
        handle: { breadcrumb: "CSAT" },
        path: "customer-satisfaction",
        lazy: async () => {
          const { CustomerSatisfaction } = await import(
            "./customer-satisfaction"
          );
          return { Component: CustomerSatisfaction };
        },
      },
      {
        handle: { breadcrumb: "General" },
        path: "general",
        children: [
          {
            index: true,
            lazy: async () => {
              const { SupportGeneral } = await import("./support-general");
              return { element: <SupportGeneral /> };
            },
          },
          {
            handle: { breadcrumb: "Contact Form" },
            path: "contact-form",
            lazy: async () => {
              const { ContactFormSettings } = await import(
                "./support-general/contact-form-settings"
              );
              return { Component: ContactFormSettings };
            },
          },
          {
            handle: { breadcrumb: "Support AI" },
            path: "support-ai",
            lazy: async () => {
              const { ManageSupportAiPage } = await import(
                "./support-general/manage-support-ai"
              );
              return { Component: ManageSupportAiPage };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Discount flow" },
        path: "discount-flow",
        lazy: async () => {
          const { DiscountFlow } = await import(
            "./discount-flow/discount-flow"
          );
          return { element: <DiscountFlow /> };
        },
      },
      {
        handle: { breadcrumb: "Upsells" },
        path: "upsells",
        lazy: async () => {
          const { UpsellSettings } = await import(
            "./order-tracking-settings/upsells/upsell-settings"
          );
          return { element: <UpsellSettings /> };
        },
      },
      upsellPageSettingsRoutes,
      {
        handle: { breadcrumb: "Customer Portal" },
        path: "customer-portal",
        lazy: async () => {
          const { CustomerPortalPage: CustomerPortal } = await import(
            "./customer-portal"
          );
          return { Component: CustomerPortal };
        },
      },
      // We moved /return-portal -> /customer-portal, so we'll redirect users just in case.
      {
        path: "return-portal",
        element: <Navigate replace to="../customer-portal" />,
      },
      {
        handle: { breadcrumb: "Returns UI" },
        path: "returns-ui",
        lazy: async () => {
          const { ReturnsUiPage } = await import("./returns-ui/returns-ui");
          return { element: <ReturnsUiPage /> };
        },
      },
      {
        handle: { breadcrumb: "Rules" },
        path: "rules",
        children: [
          {
            path: "",
            lazy: async () => {
              const { RuleList } = await import("./rules/list");
              return { element: <RuleList /> };
            },
          },
          {
            handle: { breadcrumb: "New rule" },
            path: "new",
            lazy: async () => {
              const { RuleFlow } = await import("./rules/rule-flow");
              return { element: <RuleFlow isNew /> };
            },
          },
          {
            handle: { breadcrumb: "Edit rule" },
            path: "edit/:ruleId",
            lazy: async () => {
              const { RuleFlow } = await import("./rules/rule-flow");
              return { element: <RuleFlow isNew={false} /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Users" },
        path: "users",
        children: [
          {
            path: "",
            lazy: async () => {
              const { UsersPage } = await import("./user/user");
              return { Component: UsersPage };
            },
          },
          {
            handle: { breadcrumb: "Profile settings" },
            path: ":userId",
            lazy: async () => {
              const { ProfilePage } = await import("./user/profile-page");
              return { Component: ProfilePage };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Theme" },
        path: "theme",
        lazy: async () => {
          const { ThemePage } = await import("./theme");
          return { Component: ThemePage };
        },
      },
      {
        handle: { breadcrumb: "Brand kit" },
        path: "brand-kit",
        lazy: async () => {
          const { BrandKitPage } = await import("./brand-kit");
          return { Component: BrandKitPage };
        },
      },
      {
        handle: { breadcrumb: "Integrations" },
        path: "customer-accounts-integrations",
        lazy: async () => {
          const { IntegrationsPage } = await import(
            "./customer-accounts/integrations/integrations"
          );
          return { Component: IntegrationsPage };
        },
      },
      {
        handle: { breadcrumb: "Ad blocks" },
        path: "customer-accounts-ad-blocks",
        children: [
          {
            index: true,
            lazy: async () => {
              const { AdBlocksPage } = await import(
                "./customer-accounts/ad-blocks/ad-blocks-drawer"
              );
              return { Component: AdBlocksPage };
            },
          },
          {
            path: ":adBlockId",
            handle: { breadcrumb: "Edit Ad Block" },
            lazy: async () => {
              const { AdBlockBuilder } = await import(
                "./customer-accounts/ad-blocks/ad-blocks-builder"
              );
              return { element: <AdBlockBuilder /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Storefront display" },
        path: "customer-accounts-storefront-display",
        lazy: async () => {
          const { StorefrontDisplaysPage } = await import(
            "./customer-accounts/storefront-displays"
          );
          return { Component: StorefrontDisplaysPage };
        },
      },
    ],
  },
];
