import { GetUser as User } from "@redotech/redo-model/user";
import * as classNames from "classnames";
import { memo } from "react";
import * as avatarStackCss from "./avatar-stack.module.css";
import { getGravatarUrlFromEmail } from "./gravatar-utils";
import { Tooltip } from "./tooltip/tooltip";
import { UserImage, UserImageSize, userImageSizeClasses } from "./user-image";

export interface UserInfo {
  user: User;
  subtitle?: string;
  greyOut?: boolean;
}
export const AvatarStack = memo(function AvatarStack({
  users: userInfos,
  maxAvatars: maxUsers,
  generateAvatarUrl = ({ email, size, userId }) =>
    getGravatarUrlFromEmail(email, size),
}: {
  users: UserInfo[];
  maxAvatars: number;
  generateAvatarUrl?: ({
    email,
    size,
    userId,
  }: {
    email: string;
    size: number;
    userId?: string;
  }) => string;
}) {
  /** Spacers do not currently adapt if this value is changed from micro. */
  const size = UserImageSize.MICRO;

  return (
    <div className={avatarStackCss.avatarStackContainer}>
      {userInfos
        .slice(0, Math.min(userInfos.length, maxUsers))
        .map((userInfo, index) => (
          <div key={index}>
            {/* If the last one, determine if we are showing an avatar or a placeholder for more users */}
            {index === Math.min(userInfos.length, maxUsers) - 1 &&
            userInfos.length > maxUsers ? (
              <div className={avatarStackCss.avatarContainer}>
                <div className={avatarStackCss.avatar}>
                  <div
                    className={classNames(
                      avatarStackCss.remainderBubble,
                      avatarStackCss.bubble,
                      userImageSizeClasses[size],
                    )}
                  >
                    <div>
                      <p>+{userInfos.length - maxUsers + 1}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Tooltip
                arrow
                key={userInfo.user._id}
                subtitle={userInfo.subtitle}
                title={userInfo.user.name + "\n" + userInfo.user.email}
              >
                <div className={avatarStackCss.avatarContainer}>
                  <div className={avatarStackCss.avatar}>
                    <UserImage
                      alt={userInfo.user.name
                        ?.split(" ", 2)
                        ?.filter((namePart) => !!namePart)
                        ?.map((namePart) => namePart[0].toUpperCase())
                        ?.join("")}
                      contrastBorder
                      greyOut={userInfo.greyOut}
                      imageUrl={generateAvatarUrl({
                        email: userInfo.user.email,
                        userId: userInfo.user._id,
                        size,
                      })}
                      key={index}
                      name={userInfo.user.name}
                      size={size}
                    />
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        ))}
    </div>
  );
});
