import { z } from "zod";
import { SchemaType } from "./advanced-flow/schemas/schemas";
import { categories } from "./advanced-flow/triggers";
import { zExt } from "./common/zod-util";
import { TemplateType } from "./email-builder";

export interface SMSTemplateRow extends SMSTemplate {
  _id: any;
}

export const smsTemplateSchema = z.object({
  team: zExt.objectId(),
  name: z.string(),
  content: z.string(),
  templateType: z.nativeEnum(TemplateType),
  category: z.enum(categories),
  schemaType: z.nativeEnum(SchemaType),
  imageSrc: z.string().optional(),
  autoShortenLinks: z.boolean().optional(),
});

export type SMSTemplate = z.infer<typeof smsTemplateSchema>;
