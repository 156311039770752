export function shopifyStoreName(storeDomain: string) {
  return storeDomain.replace(/\.myshopify\.com$/, "");
}

const shopifyAdminUrl = new URL("https://admin.shopify.com");

function shopifyAdminStoreUrl(storeName: string): URL {
  return new URL(`store/${encodeURIComponent(storeName)}`, shopifyAdminUrl);
}

export function shopifyAdminProductUrl(
  storeName: string,
  productId: string,
): URL {
  return new URL(
    `products/${encodeURIComponent(productId)}`,
    shopifyAdminStoreUrl(storeName) + "/",
  );
}

export function shopifyAdminOrderUrl(storeName: string, orderId: string): URL {
  return new URL(
    `orders/${encodeURIComponent(orderId)}`,
    shopifyAdminStoreUrl(storeName) + "/",
  );
}

export function shopifyAdminDraftOrderUrl(
  storeName: string,
  draftOrderId: string,
): URL {
  return new URL(
    `draft_orders/${encodeURIComponent(draftOrderId)}`,
    shopifyAdminStoreUrl(storeName) + "/",
  );
}
