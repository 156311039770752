import { z } from "zod";
import { makeReportDateFilterSchema } from "./base-report-date-filter";
import { ReportFilterType } from "./report-filter-type";

export const ReturnProcessedDateFilterSchema = makeReportDateFilterSchema(
  ReportFilterType.RETURN_PROCESSED_DATE,
);

export type ReturnProcessedDateFilter = z.infer<
  typeof ReturnProcessedDateFilterSchema
>;
// BaseReportDateFilter<ReportFilterType.RETURN_PROCESSED_DATE>
