import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { Flex } from "@redotech/redo-web/flex";
import { Tabs, TabSize } from "@redotech/redo-web/tab";
import { getCart } from "@redotech/shopify-storefront";
import { memo, useContext, useEffect, useState } from "react";
import { StorefrontClientContext, TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { getTeamOrders, getTeamReturns } from "../../client/team";
import { CustomerActivityPanel } from "../../customers/customer-detail/customer-activity/customer-activity-panel";
import { ConversationDetailsPanel } from "../conversation-detail-panel/conversation-details-panel";
import { ConversationActivityPanel } from "./conversation-activity-panel";

enum ContextTab {
  DETAILS = "details",
  CONVERSATION_ACTIVITY = "conversation-activity",
  CUSTOMER_ACTIVITY = "customer-activity",
}

const contextTabLabel = {
  [ContextTab.DETAILS]: "Details",
  [ContextTab.CONVERSATION_ACTIVITY]: "Timeline",
  [ContextTab.CUSTOMER_ACTIVITY]: "Activity",
};

export const ConversationContext = memo(function ConversationContext({
  conversation,
  setConversationAssignee,
  setActiveConversation,
}: {
  conversation: ExpandedConversation;
  setConversationAssignee: (assignee: any) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
}) {
  const team = useRequiredContext(TeamContext);
  const storefrontClient = useContext(StorefrontClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);

  const [tab, setTab] = useState<ContextTab>(ContextTab.DETAILS);

  const primaryEmail = getPrimaryCustomerEmail(conversation.customer);

  const [returnsLoad, doReturnsLoad] = useTriggerLoad(async (signal) => {
    if (primaryEmail) {
      const loadedReturns = await getTeamReturns(client, {
        email: primaryEmail,
        signal,
      });
      return loadedReturns;
    }
  });

  const [cartsLoad, doCartsLoad] = useTriggerLoad(async (signal) => {
    if (storefrontClient && conversation.carts?.length) {
      const cartPromises = conversation.carts.map(async (cartId: string) => {
        const cart = await getCart(
          storefrontClient,
          `gid://shopify/Cart/${cartId}`,
        );
        return cart;
      });
      const carts = await Promise.all(cartPromises);
      return carts;
    }
    return undefined;
  });

  const [ordersLoad, doOrdersLoad] = useTriggerLoad(async (signal) => {
    if (primaryEmail) {
      const loadedOrders = await getTeamOrders(client, {
        email: primaryEmail,
      });
      return loadedOrders;
    }
  });

  useEffect(() => {
    doOrdersLoad();
    doReturnsLoad();
  }, [conversation._id]);

  useEffect(() => {
    doCartsLoad();
  }, [storefrontClient, conversation]);

  return team.settings.customerAccounts?.enabled ? (
    <Tabs<ContextTab>
      customContainerCss={{
        backgroundColor: "var(--redo-colors-background-bg-primary)",
        borderLeft: "1px solid var(--redo-colors-border-border-secondary)",
        height: "100vh",
      }}
      customOutletCss={{
        overflowY: "auto",
        paddingTop: "0",
      }}
      customTabCss={{
        fontSize: "16px",
        height: "40px",
      }}
      keyFn={(option) => option}
      options={Object.values(ContextTab)}
      tab={(option) => contextTabLabel[option]}
      tabSize={TabSize.SMALL}
      value={tab}
      valueChange={setTab}
    >
      {tab === ContextTab.DETAILS ? (
        <ConversationDetailsPanel
          cartsLoad={cartsLoad}
          conversation={conversation}
          doCartsLoad={doCartsLoad}
          doOrdersLoad={doOrdersLoad}
          doReturnsLoad={doReturnsLoad}
          inTab
          ordersLoad={ordersLoad}
          returnsLoad={returnsLoad}
          setActiveConversation={setActiveConversation}
          setConversationAssignee={setConversationAssignee}
        />
      ) : tab === ContextTab.CUSTOMER_ACTIVITY ? (
        <Flex dir="column" w="full">
          <CustomerActivityPanel
            customerId={conversation.customer.customer}
            headerText="Customer Activity"
          />
        </Flex>
      ) : (
        <Flex dir="column" w="full">
          <ConversationActivityPanel conversationId={conversation._id} />
        </Flex>
      )}
    </Tabs>
  ) : (
    <ConversationDetailsPanel
      cartsLoad={cartsLoad}
      conversation={conversation}
      doCartsLoad={doCartsLoad}
      doOrdersLoad={doOrdersLoad}
      doReturnsLoad={doReturnsLoad}
      inTab={false}
      ordersLoad={ordersLoad}
      returnsLoad={returnsLoad}
      setActiveConversation={setActiveConversation}
      setConversationAssignee={setConversationAssignee}
    />
  );
});
