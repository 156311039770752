import {
  createEndShipperZodSchema,
  endShipperZodSchema,
} from "@redotech/redo-model/end-shippers/end-shipper";
import { z } from "zod";

export const createEndShipper = {
  input: createEndShipperZodSchema,
  output: z.object({
    endShippers: z.array(endShipperZodSchema),
  }),
};
