import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { NewMessageModal } from "./new-message-modal";
import * as offerVoiceConversionCss from "./offer-voice-conversion.module.css";

export const OfferVoiceConversion = memo(function OfferVoiceConversion({
  conversation,
}: {
  conversation: ExpandedConversation;
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const primaryEmail = getPrimaryCustomerEmail(conversation.customer);

  return (
    <Flex height="half-screen" justify="center" w="full">
      <Flex
        align="center"
        className={offerVoiceConversionCss.voiceConversionContent}
        dir="column"
      >
        <Text fontSize="md" fontWeight="semibold">
          Convert ticket
        </Text>
        <Text fontSize="sm" textAlign="center" textColor="tertiary">
          This ticket is currently a voice ticket only and does not support
          messaging. In order to reply to this customer via messaging, convert
          the ticket to email.
        </Text>
        <RedoButton
          hierarchy={RedoButtonHierarchy.PRIMARY}
          onClick={() => {
            setModalOpen(true);
          }}
          size={RedoButtonSize.SMALL}
          text="Reply via email"
        />
      </Flex>
      {modalOpen && (
        <NewMessageModal
          customerInfo={
            primaryEmail
              ? {
                  email: primaryEmail,
                  _id: conversation.customer.customer,
                }
              : undefined
          }
          existingConversation={conversation}
          open={modalOpen}
          setOpen={setModalOpen}
        />
      )}
    </Flex>
  );
});
