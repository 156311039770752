import {
  createEndShipperZodSchema,
  endShipperZodSchema,
} from "@redotech/redo-model/end-shippers/end-shipper";
import { z } from "zod";

export const updateEndShipper = {
  input: z.object({
    newValues: createEndShipperZodSchema,
    savedEndShipper: endShipperZodSchema,
  }),
  output: z.object({
    endShippers: z.array(endShipperZodSchema),
  }),
};
