import { z } from "zod";
import { ReturnStatus } from "../../../return-status";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum ReturnStatusFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const ReturnStatusFilterSchema = makeReportFilterSchema(
  ReportFilterType.RETURN_STATUS,
  ReturnStatusFilterQuery,
  z.array(z.nativeEnum(ReturnStatus)),
);

export type ReturnStatusFilter = z.infer<typeof ReturnStatusFilterSchema>;
// BaseReportFilter<ReportFilterType.RETURN_STATUS, ReturnStatusFilterQuery, z.ZodArray<z.ZodEnum<ReturnStatus>>>
