// extracted by mini-css-extract-plugin
export var copyableField = "_99-4";
export var customerBadge = "_99-0";
export var customerInfoTitle = "_99-2";
export var details = "_99-1";
export var dropdownContainer = "_99-5";
export var dropdownImage = "_99-6";
export var editAssigneeForm = "_99-a";
export var editButton = "_99-3";
export var email = "_99-7";
export var hidden = "_99-d";
export var input = "_99-8";
export var saveButton = "_99-b";
export var shopifyIcon = "_99-c";
export var username = "_99-9";