import { array, date, nativeEnum, object, string, z } from "zod";
import { zExt } from "../../common/zod-util";
import { BillingStatus } from "../concierge-conversation-message-definition";
import { conciergeConversationMessageSchema } from "./message";
import { contextSchema } from "./product-details";

export const conciergeConversationSchema = object({
  _id: string(),
  team: string(),
  email: string().optional(),
  context: contextSchema.optional(),
  messages: array(conciergeConversationMessageSchema),
  lastMessageCreatedAt: date(),
  createdAt: date(),
  updatedAt: date(),
  billingStatus: nativeEnum(BillingStatus).optional(),
  convertedToSupportConversation: z.boolean().optional(),
});

export type ConciergeConversation = z.infer<typeof conciergeConversationSchema>;

export const conciergeConversationListItemSchema = object({
  _id: zExt.objectId(),
  recentMessageText: string(),
  lastMessageCreatedAt: date(),
});

export type ConciergeConversationListItem = z.infer<
  typeof conciergeConversationListItemSchema
>;

export function isConciergeConversationListItem(
  chatInfo: any,
): chatInfo is ConciergeConversationListItem {
  return conciergeConversationListItemSchema.safeParse(chatInfo).success;
}
