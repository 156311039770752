import { Currency } from "@redotech/money/currencies";
import { z } from "zod";

export enum InstantExchangeRecoveryStatus {
  SUCCESS = "RECOVERED",
  FAILED = "FAILED",
}

export const InstantExchangeRecoveryHistorySchema = z.object({
  orderName: z.string(),
  returnId: z.string(),
  customerEmail: z.string(),
  customerName: z.string(),
  recoveryStatus: z.nativeEnum(InstantExchangeRecoveryStatus),
  currency: z.nativeEnum(Currency),
  amount: z.number(),
  initialAttemptedRecoveryDate: z.date(),
});

export const getReturnsInstantExchangeRecoveryHistory = {
  input: z.object({ teamId: z.string() }),
  output: z.object({
    instantExchangeRecoveryHistory: z.array(
      InstantExchangeRecoveryHistorySchema,
    ),
  }),
};
