import {
  faqArticleBodySchema,
  faqCollectionBodySchema,
  faqThemeBodySchema,
} from "@redotech/redo-model/faq/faq-body-schema";
import { z } from "zod";

export const getFaq = {
  input: z.object({}),
  output: z.object({
    theme: z.optional(faqThemeBodySchema),
    collections: z.map(z.string(), faqCollectionBodySchema),
    articles: z.map(z.string(), faqArticleBodySchema),
  }),
};
