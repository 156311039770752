import { z } from "zod";

export const getOriginDiscounts = {
  input: z.object({
    orderId: z.string(),
  }),
  output: z.object({
    discountCodes: z
      .array(
        z.object({ code: z.string(), amount: z.string(), type: z.string() }),
      )
      .nullish(),
  }),
};
