// extracted by mini-css-extract-plugin
export var dark = "_5q-8";
export var iconHighlight = "_5q-0";
export var lg = "_5q-6";
export var light = "_5q-1";
export var md = "_5q-5";
export var modern = "_5q-9";
export var sm = "_5q-4";
export var xl = "_5q-7";
export var xs = "_5q-3";
export var xxs = "_5q-2";