import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { GetUser } from "@redotech/redo-model/user";
import { AvatarStack } from "@redotech/redo-web/avatar-stack";
import { memo, useContext, useEffect, useState } from "react";
import { UserContext } from "../app/user";
import { UserCacheContext } from "../app/user-cache";
import { RedoMerchantClientContext } from "../client/context";
import { getUserById } from "../client/user";
import { getUserAvatarUrl } from "../common/get-avatar-url";
import * as activeUsersCss from "./active-users.module.css";
import { TeamConversationActivityContext } from "./support";

export const ActiveUsers = memo(function ActiveUsers({
  conversation,
  maxAvatars,
}: {
  conversation: ExpandedConversation;
  maxAvatars: number;
}) {
  const [activeUsers, setActiveUsers] = useState<GetUser[]>([]);
  const [typing, setTyping] = useState<boolean>(false);
  const userCache = useContext(UserCacheContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const teamConversationActivity = useContext(TeamConversationActivityContext);
  const user = useContext(UserContext);

  const updateUserCache = async (userId: string) => {
    if (!userCache.has(userId)) {
      userCache.set(userId, await getUserById(client, { id: userId }));
    }
    return userCache.get(userId)!;
  };

  const filterOutCurrentUser = (getUser: GetUser) =>
    !user || getUser._id !== user?._id;

  useEffect(() => {
    (async () => {
      if (conversation && teamConversationActivity?.[conversation._id]) {
        const usersViewing = (
          await Promise.all(
            teamConversationActivity[conversation._id].viewing?.map(
              updateUserCache,
            ),
          )
        ).filter(filterOutCurrentUser);
        const usersTyping = (
          await Promise.all(
            teamConversationActivity[conversation._id].typing?.map(
              updateUserCache,
            ),
          )
        ).filter(filterOutCurrentUser);
        setActiveUsers(usersViewing);

        /** Only show the typing indicator if there are _other_ users typing */
        setTyping(usersTyping.length > 0);
      } else {
        setActiveUsers([]);
        setTyping(false);
      }
    })();
    // TODO: this dependency means that the effect will run for every card every time
    // teamConversationActivity changes. Better to run only when the corresponding entry changes. -Zach
  }, [teamConversationActivity]);

  return (
    <div className={activeUsersCss.activityContainer}>
      {activeUsers.length > 0 && (
        <AvatarStack
          generateAvatarUrl={getUserAvatarUrl}
          maxAvatars={maxAvatars}
          users={activeUsers.map((activeUser) => {
            return { user: activeUser };
          })}
        />
      )}
      {typing && (
        <div className={activeUsersCss.typingIndicatorContainer}>
          <div className={activeUsersCss.typingIndicatorDot} />
          <div className={activeUsersCss.typingIndicatorDot} />
          <div className={activeUsersCss.typingIndicatorDot} />
        </div>
      )}
    </div>
  );
});
