// extracted by mini-css-extract-plugin
export var brand = "_44-4";
export var compact = "_44-7";
export var extraSmall = "_44-8";
export var icon = "_44-0";
export var large = "_44-1";
export var medium = "_44-2";
export var outline = "_44-9";
export var primaryDark = "_44-5";
export var primaryLight = "_44-3";
export var primaryModern = "_44-6";