// extracted by mini-css-extract-plugin
export var grid = "_f-0";
export var span1 = "_f-2";
export var span10 = "_f-b";
export var span10L = "_f-z";
export var span10M = "_f-n";
export var span10Xl = "_f-1b";
export var span11 = "_f-c";
export var span11L = "_f-10";
export var span11M = "_f-o";
export var span11Xl = "_f-1c";
export var span12 = "_f-d";
export var span12L = "_f-11";
export var span12M = "_f-p";
export var span12Xl = "_f-1d";
export var span1L = "_f-q";
export var span1M = "_f-e";
export var span1Xl = "_f-12";
export var span2 = "_f-3";
export var span2L = "_f-r";
export var span2M = "_f-f";
export var span2Xl = "_f-13";
export var span3 = "_f-4";
export var span3L = "_f-s";
export var span3M = "_f-g";
export var span3Xl = "_f-14";
export var span4 = "_f-5";
export var span4L = "_f-t";
export var span4M = "_f-h";
export var span4Xl = "_f-15";
export var span5 = "_f-6";
export var span5L = "_f-u";
export var span5M = "_f-i";
export var span5Xl = "_f-16";
export var span6 = "_f-7";
export var span6L = "_f-v";
export var span6M = "_f-j";
export var span6Xl = "_f-17";
export var span7 = "_f-8";
export var span7L = "_f-w";
export var span7M = "_f-k";
export var span7Xl = "_f-18";
export var span8 = "_f-9";
export var span8L = "_f-x";
export var span8M = "_f-l";
export var span8Xl = "_f-19";
export var span9 = "_f-a";
export var span9L = "_f-y";
export var span9M = "_f-m";
export var span9Xl = "_f-1a";
export var stretch = "_f-1";