// extracted by mini-css-extract-plugin
export var checkbox = "_48-0";
export var circle = "_48-3";
export var horizontalContainer = "_48-7";
export var sm = "_48-5";
export var switch0 = "_48-2";
export var switchDisabled = "_48-8";
export var switchEnabled = "_48-1";
export var textSwitchContainer = "_48-9";
export var textSwitchOption = "_48-a";
export var textSwitchOptionSelected = "_48-b";
export var verticalContainer = "_48-6";
export var xs = "_48-4";