import { conciergeConversationSchema } from "@redotech/redo-model/concierge-conversation/rpc/concierge-conversation-schema";
import { zExt } from "@redotech/rpc/ext";
import { object } from "zod";

export const getConciergeConversationById = {
  input: object({
    conversationId: zExt.objectId(),
  }),
  output: object({
    conversation: conciergeConversationSchema,
  }),
};
