import { ConversationDocument } from "../conversation";
import { CallDirection } from "../support/voice/voice-types";

// Ignore event gets sent when we expect to receive undefined from notice server (on connection, roughly every 45 seconds)
export enum TeamNoticeEventType {
  SOME_CONVERSATIONS_UPDATED = "SOME_CONVERSATIONS_UPDATED",
  RELOAD_ALL_CONVERSATIONS = "RELOAD_ALL_CONVERSATIONS",
  CONNECTION_MADE = "CONNECTION_MADE",
  CONNECTION_ABORTED = "CONNECTION_ABORTED",
  ERROR_EVENT = "ERROR_EVENT",
  END_RINGING = "END_RINGING",
  CALL_CONNECTED = "CALL_CONNECTED",
  USER_AVAILABILITY_UPDATED = "USER_AVAILABILITY_UPDATED",
}

export type BackendTeamNoticeEvent =
  | SomeConversationsUpdatedBackend
  | EmptyTeamNotice
  | CallConnectedPayload
  | EndRingingPayload;

export interface SomeConversationsUpdatedBackend {
  type: TeamNoticeEventType.SOME_CONVERSATIONS_UPDATED;
  conversationIds: string[];
}

export interface EmptyTeamNotice {
  type:
    | TeamNoticeEventType.RELOAD_ALL_CONVERSATIONS
    | TeamNoticeEventType.CONNECTION_MADE
    | TeamNoticeEventType.CONNECTION_ABORTED
    | TeamNoticeEventType.ERROR_EVENT
    | TeamNoticeEventType.USER_AVAILABILITY_UPDATED;
}

export interface CallConnectedPayload {
  type: TeamNoticeEventType.CALL_CONNECTED;
  supportAgentCallSessionId: string;
  customerCallControlId: string;
  customerId: string;
  customerNumber: string;
  direction: CallDirection;
}

export interface EndRingingPayload {
  type: TeamNoticeEventType.END_RINGING;
  customerCallControlId: string;
}

export type FrontendTeamNoticeEvent =
  | SomeConversationsUpdatedFrontend
  | EmptyTeamNotice
  | CallConnectedPayload
  | EndRingingPayload;

export interface SomeConversationsUpdatedFrontend {
  type: TeamNoticeEventType.SOME_CONVERSATIONS_UPDATED;
  conversations: ConversationDocument[];
}
