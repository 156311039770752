// extracted by mini-css-extract-plugin
export var active = "_2r-3";
export var activeWrapper = "_2r-0";
export var button = "_2r-1";
export var collapsed = "_2r-i";
export var divider = "_2r-7";
export var dividerSection = "_2r-6";
export var dropdown = "_2r-8";
export var dropdownChevron = "_2r-a";
export var expand = "_2r-9";
export var fullscreen = "_2r-r";
export var icon = "_2r-n";
export var label = "_2r-5 _k-0";
export var labelWithBubble = "_2r-p _k-0";
export var logo = "_2r-j";
export var logoSection = "_2r-m";
export var main = "_2r-q";
export var menu = "_2r-b";
export var menuHeader = "_2r-c _k-0";
export var menuItem = "_2r-d _k-0";
export var nav = "_2r-e";
export var noBackground = "_2r-2";
export var option = "_2r-4";
export var options = "_2r-g";
export var optionsContainer = "_2r-f";
export var page = "_2r-h";
export var rotate = "_2r-o";
export var section = "_2r-k";
export var settings = "_2r-t";
export var subitem = "_2r-s";
export var subitemActive = "_2r-x";
export var subitemLabel = "_2r-w";
export var subitemLine = "_2r-v";
export var subitemLineContainer = "_2r-u";
export var subsection = "_2r-l";