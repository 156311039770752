import { z } from "zod";

export enum CustomerWidgetChipOptions {
  WHERE_IS_MY_ORDER = "WHERE_IS_MY_ORDER",
  WHAT_RECOMMENDATIONS_WOULD_YOU_MAKE = "WHAT_RECOMMENDATIONS_WOULD_YOU_MAKE",
  WHAT_IS_THE_MOST_POPULAR_ITEM = "WHAT_IS_THE_MOST_POPULAR_ITEM",
  SHOW_ME_THE_BEST_SELLERS = "SHOW_ME_THE_BEST_SELLERS",
  CUSTOM = "CUSTOM",
}

export const customerWidgetChipOptionsToTextMap: Record<
  CustomerWidgetChipOptions,
  string
> = {
  [CustomerWidgetChipOptions.WHERE_IS_MY_ORDER]: "Where's my order?",
  [CustomerWidgetChipOptions.WHAT_RECOMMENDATIONS_WOULD_YOU_MAKE]:
    "What recommendations would you make?",
  [CustomerWidgetChipOptions.WHAT_IS_THE_MOST_POPULAR_ITEM]:
    "What's the most popular item?",
  [CustomerWidgetChipOptions.SHOW_ME_THE_BEST_SELLERS]:
    "Show me the best sellers",
  [CustomerWidgetChipOptions.CUSTOM]: "Other",
};

export const customerWidgetChipSchema = z
  .object({
    text: z.string(),
    chipOption: z.nativeEnum(CustomerWidgetChipOptions),
  })
  .refine(
    (data) => {
      return (
        data.chipOption === CustomerWidgetChipOptions.CUSTOM ||
        customerWidgetChipOptionsToTextMap[data.chipOption] === data.text
      );
    },
    {
      message: "Chip text must match the chip option unless it's custom",
    },
  );

export type CustomerWidgetChip = z.infer<typeof customerWidgetChipSchema>;

export const customerWidgetChipsSchema = z.array(customerWidgetChipSchema);

export function isCustomerWidgetChipOption(
  value: string,
): value is CustomerWidgetChipOptions {
  return value in CustomerWidgetChipOptions;
}

export enum CustomerWidgetState {
  HOME = "home",
  SUPPORT_CONVERSATIONS = "conversations",
  SUPPORT_MESSAGING = "messaging",
  ORDERS = "orders",
  ORDER_DETAILS = "order-details",
  ACCOUNT = "account",
  CONCIERGE_MESSAGING = "concierge-messaging",
  WISH_LIST = "wish-list",
}

export type GetCustomerWidgetStateFn = () => CustomerWidgetState;
