import { z } from "zod";

export const searchProductVariants = {
  input: z.object({
    query: z.string(),
    teamId: z.string(),
  }),
  output: z.array(
    z.object({
      variantId: z.string(),
      name: z.string(),
      imageSrc: z.string(),
    }),
  ),
};
