// extracted by mini-css-extract-plugin
export var box = "_6p-1";
export var checked = "_6p-5";
export var descriptionSpacer = "_6p-9";
export var disabled = "_6p-4";
export var icon = "_6p-6";
export var input = "_6p-0";
export var labelSpacer = "_6p-8";
export var medium = "_6p-3";
export var small = "_6p-2";
export var text = "_6p-7";