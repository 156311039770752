// extracted by mini-css-extract-plugin
export var actionButton = "_r-0";
export var actionButtons = "_r-1";
export var addIcon = "_r-1o";
export var addItem = "_r-21";
export var addressSpace = "_r-3a";
export var adjustment = "_r-r";
export var adjustmentBonus = "_r-24";
export var adjustmentFee = "_r-25";
export var animationContainer = "_r-2";
export var buttonBar = "_r-k";
export var buttonContent = "_r-4";
export var buttonContentPrintBarcodes = "_r-5";
export var buttonIcon = "_r-3";
export var card = "_r-6";
export var cardTitleContainer = "_r-7";
export var centerButtons = "_r-g";
export var checkbox = "_r-q";
export var chevron = "_r-11";
export var chevronDisabled = "_r-12";
export var clickAwayListener = "_r-2f";
export var clickable = "_r-p";
export var code = "_r-9";
export var column1 = "_r-a";
export var column2 = "_r-b";
export var container = "_r-c";
export var coverage = "_r-2p";
export var createLabelsImage = "_r-33";
export var deleteIconContainer = "_r-38";
export var editIcon = "_r-j";
export var editItem = "_r-h";
export var editNewItemsLink = "_r-1n";
export var emptyExchangeOrder = "_r-27";
export var error = "_r-w";
export var external = "_r-2o";
export var fraudRiskContainer = "_r-2z";
export var fullHeight = "_r-32";
export var fullWidth = "_r-31";
export var greenReturnAdjustment = "_r-28";
export var greenReturnImage = "_r-2c";
export var greenReturnSelectContainer = "_r-29";
export var greenReturnSelectTitle = "_r-2a";
export var greenReturnText = "_r-2b";
export var header = "_r-10";
export var headerSubtitle = "_r-m";
export var helpText = "_r-i";
export var icon = "_r-8";
export var item = "_r-15";
export var itemCard = "_r-34";
export var itemExtra = "_r-16";
export var itemHeader = "_r-1d";
export var itemImage = "_r-17";
export var itemIndexText = "_r-14";
export var itemMain = "_r-18";
export var itemOption = "_r-1j";
export var itemOptions = "_r-1g";
export var itemOptionsText = "_r-1h";
export var itemPrice = "_r-1m";
export var itemPriceContainer = "_r-1t";
export var itemPriceEdit = "_r-1l";
export var itemProperties = "_r-19";
export var itemProperty = "_r-1a";
export var itemPropertyName = "_r-1b";
export var itemPropertyValue = "_r-1c";
export var itemSearch = "_r-22";
export var itemSubtitle = "_r-1f";
export var itemSummaryLine = "_r-1v";
export var itemSummaryValue = "_r-1w";
export var itemTitle = "_r-1e";
export var itemTitleIcon = "_r-1i";
export var itemType = "_r-1k";
export var itemValue = "_r-1u";
export var menuTitle = "_r-1y";
export var modalButton = "_r-2d";
export var modalContent = "_r-2q";
export var modalFooterRight = "_r-2e";
export var modalImage = "_r-z";
export var modalProduct = "_r-o";
export var modalProductInfo = "_r-v";
export var modalProductInfoRight = "_r-x";
export var modalProductInfoRightBottom = "_r-y";
export var newAddress = "_r-13";
export var newItemHeader = "_r-26";
export var newItemsSection = "_r-39";
export var noConversations = "_r-d";
export var noTextWrap = "_r-35";
export var noTransition = "_r-u";
export var optionContent = "_r-1z";
export var optionTitle = "_r-1x";
export var orderLink = "_r-2l";
export var originalPrice = "_r-1p";
export var paddedModalContent = "_r-2r";
export var priceSummaryTitle = "_r-20";
export var processButtons = "_r-2s";
export var processItemSubheader = "_r-n";
export var product = "_r-2m";
export var restock = "_r-s";
export var restockSwitch = "_r-t";
export var returnTypeModal = "_r-2g";
export var returnTypeModalButton = "_r-2j";
export var returnTypeModalHeader = "_r-2i";
export var right = "_r-1q";
export var rightAligned = "_r-2h";
export var riskReportIframe = "_r-30";
export var selectItemsModalButtons = "_r-e";
export var selectItemsModalRightButtons = "_r-f";
export var status = "_r-2n";
export var subsection = "_r-2x";
export var summary = "_r-l";
export var summaryText = "_r-1r";
export var summaryTextLight = "_r-1s";
export var table = "_r-2k";
export var tableCell = "_r-2w";
export var tableDescription = "_r-2v";
export var tableHeader = "_r-2u";
export var tableHeaderGroup = "_r-2t";
export var tooltipLink = "_r-37";
export var trackingNumbers = "_r-2y";
export var trash = "_r-36";
export var voided = "_r-23";