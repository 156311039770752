import { ConciergeGeneralSettingsParser } from "@redotech/redo-model/concierge-settings/general";
import { object, string } from "zod";

export const getConciergeGeneralSettings = {
  input: object({
    teamId: string(),
  }),
  output: object({
    settings: ConciergeGeneralSettingsParser.optional(),
  }),
};
