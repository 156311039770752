import { z } from "zod";
import { zExt } from "../common/zod-util";

export const productToProcessSchema = z.object({
  _id: zExt.objectId(),
  returnValue: z.number().nullish(),
  restock: z.boolean(),
  reject: z.boolean(),
  merchantGrade: z.string().nullish(),
  merchantOutcome: z.string().nullish(),
  merchantNotes: z.string().nullish(),
});

export type ProductToProcess = z.infer<typeof productToProcessSchema>;
