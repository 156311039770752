import { z } from "zod";
import { upsellPageDataSchema } from "./upsell-page-data";

export const updateUpsellPage = {
  input: z.object({
    teamId: z.string(),
    upsellPageId: z.string(),
    upsellPageData: upsellPageDataSchema,
  }),
  output: z.null(),
};
