import { z } from "zod";
import { makeReportDateFilterSchema } from "./base-report-date-filter";
import { ReportFilterType } from "./report-filter-type";

export const ReturnCreatedDateFilterSchema = makeReportDateFilterSchema(
  ReportFilterType.RETURN_CREATED_DATE,
);

export type ReturnCreatedDateFilter = z.infer<
  typeof ReturnCreatedDateFilterSchema
>;
// BaseReportDateFilter<ReportFilterType.RETURN_CREATED_DATE>
