/**
 *
 *
 *  KEEP THIS FILE IN SYNC WITH THE ONE IN MOBILE APP :D
 *
 *
 *
 */

/** https://regex101.com/r/U7mkS4/1 */
export const YAHOO_QUOTED_CLASS_REGEX = /class="[^"]*yahoo_quoted[^"]*"/g;

export function replaceReplyThreadClassesWithSameDefaultClass(html: string) {
  return (
    html
      // Just going to add the gmail_quote class (temporary front end only) to anything we want to hide to standardize it.
      // replace any number of x_*gmail_quote classes with just gmail_quote -> outlook
      .replaceAll('gmail_quote"', 'gmail_quote" class="gmail_quote"')

      // Yahoo mail
      .replaceAll(
        // regex to match class= anything then yahoo_quoted
        YAHOO_QUOTED_CLASS_REGEX,
        'class="gmail_quote"',
      )

      // Thunderbird
      .replaceAll('class="moz-cite-prefix"', 'class="gmail_quote"')

      // Gorgias
      .replaceAll('class="gorgias_quote"', 'class="gmail_quote"')

      // Adds the class "gmail_quote" to blockquotes with type="cite" to have them be collapsible.
      .replaceAll('type="cite"', 'type="cite" class="gmail_quote"')
  );
}
