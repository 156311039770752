// extracted by mini-css-extract-plugin
export var buttons = "_4z-0";
export var buttonsActive = "_4z-h";
export var cancelConfirm = "_4z-1";
export var closeButton = "_4z-2";
export var column = "_4z-3";
export var container = "_4z-4";
export var detailHeader = "_4z-6";
export var detailTitle = "_4z-7";
export var detailsContent = "_4z-5";
export var header = "_4z-8";
export var headerWithClose = "_4z-9";
export var icon = "_4z-a";
export var infoIcon = "_4z-c";
export var informationContainer = "_4z-b";
export var sideDrawer = "_4z-d";
export var sideDrawerOpen = "_4z-e";
export var stickyFooter = "_4z-f";
export var typeOption = "_4z-g";