// extracted by mini-css-extract-plugin
export var colorSquare = "_9y-0";
export var danger = "_9y-5";
export var lightPink = "_9y-8";
export var neutralBlue = "_9y-7";
export var neutralYellow = "_9y-6";
export var normal = "_9y-1";
export var primary = "_9y-2";
export var primaryLight = "_9y-3";
export var solidBlack = "_9y-g";
export var solidGreen = "_9y-b";
export var solidLightBlue = "_9y-d";
export var solidNavy = "_9y-c";
export var solidOrange = "_9y-f";
export var solidPink = "_9y-e";
export var solidRed = "_9y-9";
export var solidYellow = "_9y-a";
export var success = "_9y-4";