import { z } from "zod";
import { ReturnedProductStatus } from "../../../return";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum ProductStatusFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const ProductStatusFilterSchema = makeReportFilterSchema(
  ReportFilterType.PRODUCT_STATUS,
  ProductStatusFilterQuery,
  z.array(z.nativeEnum(ReturnedProductStatus)),
);

export type ProductStatusFilter = z.infer<typeof ProductStatusFilterSchema>;
// BaseReportFilter<ReportFilterType.PRODUCT_STATUS, ProductStatusFilterQuery, z.ZodArray<z.ZodEnum<ReturnedProductStatus>>>
