import { Flex } from "@redotech/redo-web/flex";
import { isDef } from "@redotech/util/checks";
import { memo, useEffect, useRef } from "react";
import PhoneImage from "./Phone.png?url";
import * as smsPhoneCss from "./sms-phone.module.css";
export const SmsPhone = memo(function SmsPhone({
  content,
  imageSrc,
  autoShortenLinks,
}: {
  content: string;
  imageSrc?: string | undefined;
  autoShortenLinks: boolean;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Update height when content changes and sanitize content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content]);

  return (
    <Flex className={smsPhoneCss.container}>
      <Flex className={smsPhoneCss.content} dir="column" gap="md">
        {isDef(imageSrc) && imageSrc && (
          <img alt="Card" className={smsPhoneCss.smsImage} src={imageSrc} />
        )}
        <textarea
          className={smsPhoneCss.textarea}
          onChange={() => {}}
          readOnly
          ref={textareaRef}
          value={sanitizeSmsBuilderContent(content, autoShortenLinks)}
        />
      </Flex>

      <img alt="Card" className={smsPhoneCss.phoneCard} src={PhoneImage} />
    </Flex>
  );
});

function sanitizeSmsBuilderContent(content: string, autoShortenLinks: boolean) {
  const spanRegex = /<span[^>]*>(.*?)<\/span>/gi;
  return content.replace(spanRegex, (match, p1) => {
    return autoShortenLinks ? `redo.link/XXXXX` : `${p1}`;
  });
}
