export const GORGIAS_CLIENT_ID = process.env.GORGIAS_CLIENT_ID;

export const REDO_API_URL = process.env.REDO_API_URL || "http://localhost:8888";

export const REDO_MERCHANT_APP_VERSION =
  process.env.REDO_MERCHANT_APP_VERSION || "_";

export const REDO_MERCHANT_SERVER_URL =
  process.env.REDO_MERCHANT_SERVER_URL || "http://localhost:8007";

export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID;

export const FACEBOOK_USER_CONFIG_ID = process.env.FACEBOOK_USER_CONFIG_ID;

export const FACEBOOK_BUSINESS_CONFIG_ID =
  process.env.FACEBOOK_BUSINESS_CONFIG_ID;

export const INSTAGRAM_BUSINESS_CONFIG_ID =
  process.env.INSTAGRAM_BUSINESS_CONFIG_ID;

export const ATTENTIVE_CLIENT_ID = process.env.ATTENTIVE_CLIENT_ID;
export const CHATBOT_USER_ID = process.env.CHATBOT_USER_ID;
export const AI_AUTOPILOT_USER_ID = process.env.AI_AUTOPILOT_USER_ID;
export const AI_AVATAR_URL = process.env.AI_AVATAR_URL;
export const GMAIL_CLIENT_ID = process.env.GMAIL_CLIENT_ID;
export const OUTLOOK_CLIENT_ID = process.env.OUTLOOK_CLIENT_ID;
export const ANONYMOUS_USER_ID = process.env.ANONYMOUS_USER_ID;
export const ATTENTIVE_OUTBOUND_USER_ID =
  process.env.ATTENTIVE_OUTBOUND_USER_ID;

export const SHIPBOB_CLIENT_ID = process.env.SHIPBOB_CLIENT_ID || "";
export const SHIPBOB_INTEGRATION_NAME =
  process.env.SHIPBOB_INTEGRATION_NAME || "";

export const DATADOG_APPLICATION_ID =
  process.env.DATADOG_APPLICATION_ID ?? null;
export const DATADOG_CLIENT_TOKEN = process.env.DATADOG_CLIENT_TOKEN ?? null;
