import { DiscountValueType } from "@redotech/redo-model/order-discount";
import {
  BannerStatus,
  TextAppearance,
  TextSize,
  UpsellPageElementType,
  UpsellPageLayoutType,
  UpsellPageProductSource,
} from "@redotech/redo-model/upsell-page/upsell-page-types";
import { z } from "zod";

export const upsellPageDataSchema = z.object({
  layout: z.object({
    type: z.nativeEnum(UpsellPageLayoutType),
    timer: z.object({
      type: z.literal(UpsellPageElementType.TIMER),
      showTimer: z.boolean(),
      backgroundColor: z.string(),
      totalTime: z.string(),
      timerBannerStatus: z.nativeEnum(BannerStatus),
      timerTextSize: z.nativeEnum(TextSize),
      timerTextColor: z.nativeEnum(TextAppearance),
      supportingTextSize: z.nativeEnum(TextSize),
      supportingText: z.string(),
    }),
    banner: z.object({
      type: z.literal(UpsellPageElementType.BANNER),
      showBanner: z.boolean(),
      title: z.string(),
      titleSize: z.nativeEnum(TextSize),
      supportingText: z.string(),
      supportingTextSize: z.nativeEnum(TextSize),
      backgroundColor: z.string(),
    }),
    upsellProducts: z.array(
      z.object({
        type: z.literal(UpsellPageElementType.UPSELL_PRODUCT),
        upsellProductSource: z.nativeEnum(UpsellPageProductSource).optional(),
        collectionId: z.string().optional(),
        productId: z.string().optional(),
        variantIds: z.array(z.string()).optional(),
        discountAmount: z.string(),
        discountValueType: z.nativeEnum(DiscountValueType),
        showDescription: z.boolean().optional(),
      }),
    ),
  }),
});
