// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_9r-0";
export var footer = "_9r-2";
export var infoSection = "_9r-3";
export var infoSectionContainer = "_9r-4";
export var infoSectionTitleRow = "_9r-5";
export var itemDetails = "_9r-6";
export var line = "_9r-7";
export var lineItemImage = "_9r-1";
export var lineItems = "_9r-8";
export var modalContent = "_9r-9";
export var multiLineInfo = "_9r-a";
export var orderName = "_9r-b";
export var strong = "_9r-c";