import ArrowDown from "@redotech/redo-web/arbiter-icon/arrow-down_filled.svg";
import ArrowUp from "@redotech/redo-web/arbiter-icon/arrow-up_filled.svg";
import XClose from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { ForwardedRef, forwardRef, memo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as redoSearchTextInputCss from "./redo-search-text-input.module.css";
import { RedoTextInput, RedoTextInputProps } from "./redo-text-input";

export const RedoSearchTextInput = memo(
  forwardRef(function RedoSearchTextInput(
    {
      upAction,
      downAction,
      totalSearchResults,
      currentSearchIndex,
      searchString,
      setSearchString,
      textInputProps,
    }: {
      upAction: () => void;
      downAction: () => void;
      totalSearchResults: number;
      currentSearchIndex: number;
      searchString: string;
      setSearchString: (searchString: string) => void;
      textInputProps: RedoTextInputProps;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    return (
      <RedoTextInput
        {...textInputProps}
        onKeyDown={(e) => {
          if (e.shiftKey && e.key === "Enter") {
            upAction();
          } else {
            if (e.key === "Enter") {
              downAction();
            }
          }
        }}
        trailingActions={
          searchString && (
            <Flex align="center" gap="xs">
              <>
                {totalSearchResults === 0 ? (
                  <Text fontSize="xxs" textColor="tertiary">
                    No results
                  </Text>
                ) : (
                  <Text fontSize="xxs" textColor="tertiary">
                    {(currentSearchIndex || 0) + 1}/{totalSearchResults}
                  </Text>
                )}
                <ArrowUp
                  className={redoSearchTextInputCss.actionIcon}
                  onClick={() => {
                    upAction();
                  }}
                />
                <ArrowDown
                  className={redoSearchTextInputCss.actionIcon}
                  onClick={() => {
                    downAction();
                  }}
                />
              </>
              <XClose
                className={redoSearchTextInputCss.actionIcon}
                onClick={() => setSearchString("")}
              />
            </Flex>
          )
        }
      />
    );
  }),
);
