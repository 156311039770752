import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { PopperPlacementType } from "@mui/base/Popper";
import { IterableMap } from "@redotech/react-util/component";
import { useLazyContext } from "@redotech/react-util/context";
import { useSearch } from "@redotech/react-util/search";
import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoList,
  RedoListItem,
} from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import * as classNames from "classnames";
import Fuse from "fuse.js";
import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserContext } from "../../app/user";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";
import { ConversationTagPill } from "./conversation-tag-pill";
import { CreateConversationTagModal } from "./create-conversation-tag-modal";
import { CreateTagOption } from "./create-tag-option";
import { EditConversationTagModal } from "./edit-conversation-tag-modal";

const searcher = new Fuse<ConversationTagWithId>([], {
  keys: ["name"],
  threshold: 0.3,
});

export const ConversationTagInput = memo(function ConversationTagInput({
  currentTags,
  setCurrentTags,
  disabled = false,
  showBorder = true,
  onlyDropdownMode = undefined,
  showAddButtonToEnterInput: showAddButtonToEnterInput = false,
  dropdownPlacement = "bottom",
}: {
  currentTags: ConversationTagWithId[];
  setCurrentTags(tags: ConversationTagWithId[]): void;
  disabled?: boolean;
  showBorder?: boolean;
  onlyDropdownMode?: {
    anchor: HTMLElement | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  };
  showAddButtonToEnterInput?: boolean;
  dropdownPlacement?: PopperPlacementType;
}) {
  const [availableTags, setAvailableTags] = useState<ConversationTagWithId[]>(
    [],
  );
  const user = useContext(UserContext);
  const canCreateTags =
    !!user && permitted(user.permissions, Permission.MANAGE_TAG);

  const [conversationTags] = useLazyContext(ConversationTagsContext);

  useEffect(() => {
    setAvailableTags(conversationTags.value || []);
  }, [conversationTags]);

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  function tagIsSelected(tag: ConversationTagWithId) {
    return currentTags.some((current) => current.name === tag.name);
  }

  const [searchText, setSearchText] = useState<string>("");

  const [selectDropdownOpen_, setSelectDropdownOpen_] = useState(false);

  const [selectDropdownOpen, setSelectDropdownOpen] = onlyDropdownMode
    ? [onlyDropdownMode.open, onlyDropdownMode.setOpen]
    : [selectDropdownOpen_, setSelectDropdownOpen_];

  const [tagBeingEdited, setTagBeingEdited] = useState<
    ConversationTagWithId | undefined
  >(undefined);

  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);

  const filteredAvailableTags = useSearch(searcher, availableTags, searchText);

  function addTagToConversation(tag: ConversationTagWithId) {
    if (currentTags.find((usedTag) => usedTag.name === tag.name)) {
      return;
    }
    // Eagerly update
    const newTags = [...currentTags, tag];
    setCurrentTags(newTags);
    setSearchText("");
  }

  function removeTagFromConversation(tag: ConversationTagWithId) {
    // Eagerly update
    const updatedTags = currentTags.filter(
      (current) => current.name !== tag.name,
    );
    setCurrentTags(updatedTags);
  }

  const addNewTagButton = (
    <Flex
      className={conversationTagInputCss.pointer}
      onClick={() => setSelectDropdownOpen(true)}
    >
      <RedoBadge
        color={RedoBadgeColor.GRAY}
        segmentLeading={{
          type: "icon",
          Icon: PlusIcon,
        }}
        size={RedoBadgeSize.X_SMALL}
        text={currentTags.length === 0 ? "Add tag" : undefined}
      />
    </Flex>
  );

  const existingTags = currentTags.length > 0 && (
    <Flex className={conversationTagInputCss.tagsFlex} wrap="wrap">
      <IterableMap items={currentTags} keyFn={(value) => value.name}>
        {(tag) => (
          <ConversationTagPill
            badgeSize={RedoBadgeSize.X_SMALL}
            tag={tag}
            xClicked={
              disabled
                ? undefined
                : () => {
                    removeTagFromConversation(tag);
                  }
            }
          />
        )}
      </IterableMap>
      {showAddButtonToEnterInput && currentTags.length === 0 && addNewTagButton}
    </Flex>
  );

  useEffect(() => {
    if (selectDropdownOpen) {
      inputRef?.focus();
    }
  }, [selectDropdownOpen]);

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const tagInput = (
    <input
      autoFocus
      className={classNames(
        conversationTagInputCss.input,
        showBorder && conversationTagInputCss.border,
        !selectDropdownOpen &&
          showAddButtonToEnterInput &&
          conversationTagInputCss.hide,
      )}
      disabled={disabled}
      onChange={(event) => setSearchText(event.target.value)}
      onClick={(event) => {
        event.stopPropagation();
        setSelectDropdownOpen(true);
      }}
      onFocus={(event) => {
        event.stopPropagation();
        setSelectDropdownOpen(true);
      }}
      placeholder="Search tags..."
      ref={setInputRef}
      value={searchText}
    />
  );

  const wrappedInput = (
    <Flex className={conversationTagInputCss.inputFlex} ref={setDropdownAnchor}>
      <div className={conversationTagInputCss.inputWrapper}>
        {existingTags}
        {showAddButtonToEnterInput && addNewTagButton}
      </div>
    </Flex>
  );

  const tagItems: RedoListItem<ConversationTagWithId | "create">[] =
    filteredAvailableTags.map((tag) => ({
      value: tag,
      menu: {
        onClick: () => {
          setTagBeingEdited(tag);
          setSelectDropdownOpen(false);
        },
      },
    }));

  if (canCreateTags) {
    const createTagItem: RedoListItem<"create"> = {
      value: "create",
    };

    tagItems.push(createTagItem);
  }

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

  const dropdownItems = (
    <Dropdown
      anchor={onlyDropdownMode?.anchor || dropdownAnchor}
      fitToAnchor={false}
      open={selectDropdownOpen}
      placement={dropdownPlacement}
    >
      <Flex
        className={conversationTagInputCss.dropdownContainer}
        dir="column"
        gap="sm"
      >
        {tagInput}
        <Divider />
        <RedoList
          focusedIndex={focusedIndex}
          isItemSelected={(item) => {
            if (item.value === "create") {
              return false;
            }
            return tagIsSelected(item.value);
          }}
          items={tagItems}
          itemSelected={(item) => {
            if (item.value === "create") {
              setCreateTagModalOpen(true);
              setSelectDropdownOpen(false);
            } else {
              const tag = item.value;
              if (tagIsSelected(tag)) {
                removeTagFromConversation(tag);
              } else {
                addTagToConversation(tag);
              }
            }
          }}
          refToListenTo={inputRef}
          setFocusedIndex={setFocusedIndex}
          size={RedoListItemSize.SMALL}
        >
          {(item) => {
            if (item.value === "create") {
              return <CreateTagOption />;
            } else {
              return (
                <ConversationTagPill
                  badgeSize={RedoBadgeSize.X_SMALL}
                  tag={item.value}
                />
              );
            }
          }}
        </RedoList>
      </Flex>
    </Dropdown>
  );

  const dropdown = selectDropdownOpen && (
    <ClickAwayListener onClickAway={() => setSelectDropdownOpen(false)}>
      {dropdownItems}
    </ClickAwayListener>
  );

  const editModal = tagBeingEdited && (
    <EditConversationTagModal
      resolved={() => {
        setTagBeingEdited(undefined);
        setSelectDropdownOpen(true);
      }}
      tag={tagBeingEdited}
    />
  );

  const createModal = createTagModalOpen && (
    <CreateConversationTagModal
      cancelClicked={() => {
        setSelectDropdownOpen(true);
        setCreateTagModalOpen(false);
      }}
      tagCreated={(newTag) => {
        setSelectDropdownOpen(true);
        setCreateTagModalOpen(false);
        setAvailableTags([...availableTags, newTag]);
      }}
    />
  );

  return (
    <>
      {!onlyDropdownMode ? (
        <div className={conversationTagInputCss.fullWidth}>
          {wrappedInput}
          {dropdown}
          {editModal}
          {createModal}
        </div>
      ) : (
        <>
          {dropdown}
          {editModal}
          {createModal}
        </>
      )}
    </>
  );
});
