import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useTriggerLoad } from "@redotech/react-util/load";
import { useDevicePixelRatio } from "@redotech/react-util/screen";
import { getUserAvatarUrl } from "@redotech/redo-merchant-app/common/get-avatar-url";
import type { Return } from "@redotech/redo-model/return";
import { TimelineEvent } from "@redotech/redo-model/timeline";
import { GetUser } from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import SendIcon from "@redotech/redo-web/arbiter-icon/send-03.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import { Card } from "@redotech/redo-web/card";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { UserImage, UserImageSize } from "@redotech/redo-web/user-image";
import { groupInput, input } from "@redotech/ui/form";
import { FormEvent, memo, useContext, useMemo } from "react";
import { TeamContext } from "../../app/team";
import { returnCommentCreate, returnCommentDelete } from "../../client/comment";
import { RedoMerchantClientContext } from "../../client/context";
import * as commentsCss from "./comments-card.module.css";

export const CommentsCard = memo(function CommentsCard({
  return: return_,
  reload,
}: {
  return?: Return;
  reload(): void;
}) {
  return (
    <Card title="Comments">
      <Flex dir="column" gap="md">
        {return_?.timeline
          .filter((event) => !event.isShipment && !event.customer)
          .map((event) => (
            <Comment
              event={event}
              key={event._id}
              reload={reload}
              return={return_}
            />
          ))}
      </Flex>
      <NewComment reload={reload} returnId={return_?._id} />
    </Card>
  );
});

const Comment = memo(function Comment({
  return: return_,
  event,
  reload,
}: {
  reload(): void;
  return: Return;
  event: TimelineEvent;
}) {
  const team = useContext(TeamContext);
  const devicePixelRatio = useDevicePixelRatio();
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = event?.user as GetUser | null;
  const gravatarUrl = useMemo(() => {
    return getUserAvatarUrl({
      email: event.isSystem
        ? "hello@getredo.com"
        : (event.customer ? event.customer.email : user ? user.email : "") ||
          "",
      defaultImage: "identicon",
      userId: user?._id,
      size: Math.ceil(32 * devicePixelRatio),
    });
  }, [devicePixelRatio, team?.email]);

  const profileImage = useMemo(() => {
    if (!user) {
      return null;
    }
    return getUserAvatarUrl({ email: user.email, size: 32, userId: user._id });
  }, [user, devicePixelRatio]);

  const [_deleteLoad, doDelete] = useTriggerLoad(async (signal) => {
    await alertOnFailure("Could not delete comment")(() =>
      returnCommentDelete(client, {
        commentId: event._id!,
        returnId: return_._id,
        signal,
      }),
    );
    reload();
  });

  if (!team) {
    return null;
  }

  return (
    <Flex dir="row" gap="xs">
      {user ? (
        <UserImage
          alt="profile picture"
          imageUrl={profileImage}
          name={user?.name ?? ""}
          size={UserImageSize.SMALL}
        />
      ) : (
        <img className={commentsCss.commentProfile} src={gravatarUrl} />
      )}

      {event.link ? (
        <Flex dir="column" flex={1} gap="xs">
          <Flex
            bgColor="secondary"
            dir="row"
            key={event._id}
            p="md"
            radius="sm"
          >
            <Text fontSize="xs" textColor="primary">
              {event.link?.message}
            </Text>
            <Text
              className={commentsCss.commentLink}
              fontSize="xs"
              onClick={() => window.open(event.link?.url)}
            >
              &nbsp;{`order ${event.link?.orderNumber ?? "link"}`}
            </Text>
          </Flex>
          <Text fontSize="xxs" textColor="secondary">
            {`${getDateTimeString(new Date(event.updatedAt))} ${
              user?.name ?? ""
            }`}
          </Text>
        </Flex>
      ) : (
        <Flex dir="column" flex={1} gap="xs">
          <Flex bgColor="secondary" dir="row" p="md" radius="sm" w="full">
            <Text fontSize="xs" textColor="primary" w="full">
              {event.message}
            </Text>
            {!event.isSystem && !event.customer && (
              <RedoButton
                IconLeading={TrashIcon}
                onClick={() => doDelete()}
                pending={_deleteLoad.pending}
                size={RedoButtonSize.SMALL}
                theme={RedoButtonTheme.DESTRUCTIVE}
              />
            )}
          </Flex>
          <Text fontSize="xxs" textColor="secondary">
            {`${getDateTimeString(new Date(event.updatedAt))} ${
              user?.name ?? ""
            }`}
          </Text>
        </Flex>
      )}
    </Flex>
  );
});

const commentForm = groupInput({
  message: input<string>(),
});

const NewComment = memo(function NewComment({
  returnId,
  reload,
}: {
  returnId?: string;
  reload(): void;
}) {
  const input = useInput(commentForm, { message: "" });
  const { message } = input.inputs;

  const client = useRequiredContext(RedoMerchantClientContext);
  const [submitLoad, submit] = useTriggerLoad(async (signal) => {
    if (!returnId) {
      // Tried to submit a comment before return was loaded
      return;
    }
    await alertOnFailure("Could not create new comment")(() =>
      returnCommentCreate(client, {
        message: message.value,
        returnId,
        signal,
      }),
    );
    reload();
    message.setValue("");
  });

  const submitPending = submitLoad.pending;
  const submitDisabled = !returnId || message.value === "";
  const inputDisabled = submitLoad.pending || !returnId;

  return (
    <form
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submit();
      }}
    >
      <FormTextInput
        button={
          <RedoButton
            disabled={submitDisabled}
            IconTrailing={SendIcon}
            onClick={() => submit()}
            pending={submitPending}
            size={RedoButtonSize.REGULAR}
          />
        }
        disabled={inputDisabled}
        fullwidth
        input={message}
        label=""
        placeholder="Type a comment and press Enter"
      />
    </form>
  );
});
