import { conciergeConversationSchema } from "@redotech/redo-model/concierge-conversation/rpc/concierge-conversation-schema";
import { zExt } from "@redotech/rpc/ext";
import { array, coerce, number, object, string, enum as zodEnum } from "zod";

export const getConciergeConversations = {
  input: object({
    teamId: string(),
    startDate: coerce.date().optional(),
    endDate: coerce.date().optional(),
    paginationToken: object({
      lastMessageCreatedAt: coerce.date(),
      _id: zExt.objectId(),
    }).optional(),
    sortOrder: zodEnum(["asc", "desc"]).default("desc"),
    limit: number().min(1).max(100).default(10),
  }).strict(),
  output: object({
    conversations: array(conciergeConversationSchema),
    nextPaginationToken: object({
      lastMessageCreatedAt: coerce.date(),
      _id: zExt.objectId(),
    }).nullable(),
  }),
};
