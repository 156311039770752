import { CurrencyCode } from "@redotech/shopify-client/storefront.graphql";
import { defineSchema, SchemaInstance } from "../../type-system/schema";

try {
  // eslint-disable-next-line
  require("temporal-polyfill/global"); // useful for storybook (since it uses webpack not bazel)
} catch (e) {
  // do nothing, this is only for storybook
}

type XOR<T, U> = T | U extends object
  ? (T extends U ? never : T) | (U extends T ? never : U)
  : T | U;

type DiscountAmountInput = {
  enabled: boolean;
  discountAmount: number;
  discountPercentage?: never;
  excludeOnSale: boolean;
};

type DiscountPercentageInput = {
  enabled: boolean;
  discountAmount?: never;
  discountPercentage: number; // Shopify expects this between 0 and 1
  excludeOnSale: boolean;
};

export type DiscountCodeInput = XOR<
  DiscountAmountInput,
  DiscountPercentageInput
>;

const discountCodeSchema = defineSchema({
  fields: {
    discountCode: {
      dataType: "Maybe Text",
      documentation: "The discount code to shared with the customer.",
    },
  },
});

export const baseMarketingSignupSchema = defineSchema({
  fields: {
    inferredCustomerName: {
      dataType: "Text",
      documentation:
        'The inferred name of the customer who signed up for marketing messages. When the subscriber is anonymous, it defaults to "Customer".',
    },
    alreadySubscribed: {
      dataType: "Boolean",
      documentation:
        "Whether the customer was already subscribed to marketing messages.",
    },
  },
});

export const emailMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    ...discountCodeSchema.fields,
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
    customerEmail: {
      dataType: "Email",
      documentation:
        "The email address of the customer who signed up for marketing emails.",
    },
  },
});

export const baseMarketingSchema = defineSchema({
  fields: {
    inferredCustomerName: {
      dataType: "Text",
      documentation:
        'The inferred name of the customer. When the subscriber is anonymous, it defaults to "Customer".',
    },
    interactiveCart: {
      dataType: "Maybe Interactive Cart", // only gets populated if needed
      documentation: "Product recommendations inside of an interactive cart.",
    },
  },
});

export const baseMarketingEmailSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    customerEmail: {
      dataType: "Email",
      documentation: "The email address of the customer.",
    },
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
  },
});

export const baseMarketingCartAbandomentSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "The time the last item was added to the cart.",
    },
    isCartAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not begun the checkout process or checked out since updating their cart.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned the cart.",
    },
    abandonedCart: {
      dataType: "Maybe Interactive Cart",
      documentation: "The cart that was abandoned.",
    },
  },
});

export const baseMarketingBrowseAbandomentSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "When the browsing last occurred.",
    },
    isBrowseAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not added anything to the cart or placed an order since browsing.",
    },
    customerEmail: {
      dataType: "Email",
      documentation:
        "The email address of the customer who abandoned their browsing session.",
    },
    browseAbandonmentData: {
      dataType: "Browse Abandonment",
      documentation: "The product variants viewed by the customer.",
    },
  },
});

const dummyInteractiveCart = {
  cart: {
    id: "preview",
    apiUrl: "https://example.com/api/cart",
    widgetSlug: "widget-slug",
    teamId: "team-id",
    storeName: "Example Store",
    storeUrl: "https://example.com",
    checkoutUrl: "https://example.com/checkout",
    generatedByRedo: false,
  },
};

const exampleBaseMarketingSchemaInstance: SchemaInstance<
  typeof baseMarketingSchema
> = {
  inferredCustomerName: "Customer",
  interactiveCart: dummyInteractiveCart,
};

export const baseMarketingCheckoutAbandonmentSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "When the last customer action on checkout occurred.",
    },
    isCheckoutAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not placed an order since starting checkout.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned their browsing session.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation:
        "The phone number of the customer who abandoned their browsing session.",
    },
    checkout: {
      dataType: "Shopify Checkout",
      documentation: "Data about the customer's checkout session",
    },
    abandonedCart: {
      dataType: "Maybe Interactive Cart",
      documentation: "The cart that was abandoned.",
    },
  },
});

export const exampleBaseMarketingCheckoutAbandonmentSchemaInstance: SchemaInstance<
  typeof baseMarketingCheckoutAbandonmentSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  isAbandonment: true,
  time: Temporal.Now.plainDateTimeISO(),
  isCheckoutAbandoned: true,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  checkout: {
    attributes: [],
    billingAddress: null,
    currencyCode: CurrencyCode.Usd,
    discountApplications: [],
    email: "john.doe@example.com",
    lineItems: [],
    order: null,
    shippingAddress: null,
    shippingLine: null,
    subtotalPrice: {
      amount: 100,
      currencyCode: CurrencyCode.Usd,
    },
    phone: "+1234567890",
    token: "",
    totalPrice: {
      amount: 105,
      currencyCode: CurrencyCode.Usd,
    },
    totalTax: {
      amount: 5,
      currencyCode: CurrencyCode.Usd,
    },
    transactions: [],
  },
  abandonedCart: dummyInteractiveCart,
};

export const exampleBaseMarketingBrowseAbandomentSchemaInstance: SchemaInstance<
  typeof baseMarketingBrowseAbandomentSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  customerEmail: "john.doe@example.com",
  isBrowseAbandoned: true,
  isAbandonment: true,
  time: Temporal.Now.plainDateTimeISO(),
  browseAbandonmentData: {
    viewedProducts: [
      {
        id: "123",
        image: null,
        price: {
          amount: 100,
          currencyCode: CurrencyCode.Usd,
        },
        product: {
          id: "123",
          title: "T-Shirt",
          type: null,
          untranslatedTitle: "T-Shirt",
          url: "",
          vendor: "",
        },
        sku: "123",
        title: "Red T-Shirt",
        untranslatedTitle: "Red T-Shirt",
      },
    ],
  },
};

export const exampleBaseMarketingCartAbandomentSchemaInstance: SchemaInstance<
  typeof baseMarketingCartAbandomentSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  customerEmail: "john.doe@example.com",
  isCartAbandoned: true,
  isAbandonment: true,
  time: Temporal.Now.plainDateTimeISO(),
  abandonedCart: dummyInteractiveCart,
};

export const exampleBaseMarketingEmailSchemaInstance: SchemaInstance<
  typeof baseMarketingEmailSchema
> = {
  inferredCustomerName: "Customer",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
  interactiveCart: dummyInteractiveCart,
};

export const baseMarketingSmsSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation: "The phone number of the customer.",
    },
    customer: {
      dataType: "Customer",
      documentation: "The customer object.",
    },
  },
});

const exampleBaseMarketingSignupSchema: SchemaInstance<
  typeof baseMarketingSignupSchema
> = {
  inferredCustomerName: "Customer",
  alreadySubscribed: false,
};

export const exampleEmailMarketingSignupSchema: SchemaInstance<
  typeof emailMarketingSignupSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  discountCode: "EMAILABCD",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
};

export const smsMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation:
        "The phone number of the customer who signed up for marketing SMS.",
    },
  },
});

export const exampleSmsMarketingSignupSchema: SchemaInstance<
  typeof smsMarketingSignupSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  customerPhoneNumber: "+1234567890",
};

export const smsMarketingConfirmedSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    ...discountCodeSchema.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation:
        "The phone number of the customer who confirmed their marketing SMS subscription.",
    },
  },
});

export const exampleSmsMarketingConfirmedSchema: SchemaInstance<
  typeof smsMarketingConfirmedSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  discountCode: "SMSABCD",
  customerPhoneNumber: "+1234567890",
};
