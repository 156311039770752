import { useRequiredContext } from "@redotech/react-util/context";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MerchantAppSetting } from "../../setting/settings";

export const SETTING_ATTRIBUTE = "data-setting-id" as const;

type NavigationStatus =
  | { status: "idle" }
  | { status: "navigating"; settingId: string }
  | { status: "error"; error: Error }
  | { status: "completed" };

interface SettingNavigationState {
  registerSetting: (id: string, element: HTMLElement | null) => void;
  navigateToSetting: (setting: MerchantAppSetting) => Promise<void>;
  getSettingElement: (id: string) => HTMLElement | undefined;
  getAllSettings: () => readonly MerchantAppSetting[];
  navigationStatus: NavigationStatus;
  highlightDuration: number;
}

interface SettingNavigationProviderProps {
  children: React.ReactNode;
  settings: readonly MerchantAppSetting[];
  highlightDuration?: number;
  onNavigated?: (setting: MerchantAppSetting) => void;
}

const SettingNavigationContext = createContext<
  SettingNavigationState | undefined
>(undefined);

export const SettingNavigationProvider: React.FC<
  SettingNavigationProviderProps
> = ({ children, settings, highlightDuration = 2000, onNavigated }) => {
  const [availableSettings, setAvailableSettings] = useState<
    readonly MerchantAppSetting[]
  >([]);
  const [navigationStatus, setNavigationStatus] = useState<NavigationStatus>({
    status: "idle",
  });
  const elementsRef = useRef(new Map<string, HTMLElement>());
  const highlightTimerRef = useRef<NodeJS.Timeout>();
  const currentHighlightRef = useRef<string | null>(null);
  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();
  const currentSettingRef = useRef<string | null>(null);

  useEffect(() => {
    const fragment = location.hash.slice(1);
    if (fragment) {
      const setting = settings.find((s) => s.id === fragment);
      if (setting) {
        requestAnimationFrame(() => {
          navigateToSetting(setting).catch((err) =>
            console.error("Failed to navigate to fragment:", err),
          );
        });
      }
    }
  }, [location.hash, settings]);

  useEffect(() => {
    const settingIds = new Set(settings.map((s) => s.id));
    if (settingIds.size !== settings.length) {
      console.warn("Duplicate setting IDs detected in settings prop");
    }
    setAvailableSettings(settings);
  }, [settings]);

  useEffect(() => {
    return () => {
      if (highlightTimerRef.current) {
        clearTimeout(highlightTimerRef.current);
      }
      if (currentHighlightRef.current) {
        const element = elementsRef.current.get(currentHighlightRef.current);
        if (element) {
          element.removeAttribute("data-highlighted");
        }
      }
      elementsRef.current.clear();
    };
  }, []);

  const registerSetting = useCallback(
    (id: string, element: HTMLElement | null) => {
      if (element) {
        elementsRef.current.set(id, element);
      } else {
        elementsRef.current.delete(id);
      }
    },
    [],
  );

  const findElementById = useCallback((id: string): HTMLElement | null => {
    return (
      elementsRef.current.get(id) ||
      document.querySelector(`[${SETTING_ATTRIBUTE}="${id}"]`)
    );
  }, []);

  const getSettingElement = useCallback(
    (id: string): HTMLElement | undefined => {
      const element = findElementById(id);
      return element || undefined;
    },
    [findElementById],
  );

  const getAllSettings = useCallback((): readonly MerchantAppSetting[] => {
    return availableSettings;
  }, [availableSettings]);

  const waitForElement = useCallback(
    (id: string): Promise<HTMLElement> => {
      return new Promise((resolve, reject) => {
        let frameId: number;

        const check = () => {
          const element = findElementById(id);
          if (element) {
            cleanup();
            resolve(element);
          } else {
            frameId = requestAnimationFrame(check);
          }
        };

        const timeoutId = setTimeout(() => {
          cleanup();
          reject(new Error(`Element with id ${id} not found after 5 seconds`));
        }, 5000);

        const cleanup = () => {
          if (frameId) cancelAnimationFrame(frameId);
          if (timeoutId) clearTimeout(timeoutId);
        };

        frameId = requestAnimationFrame(check);
      });
    },
    [findElementById],
  );

  useEffect(() => {
    const handleHighlightComplete = () => {
      setNavigationStatus({ status: "completed" });
    };

    document.addEventListener("highlightComplete", handleHighlightComplete);
    return () => {
      document.removeEventListener(
        "highlightComplete",
        handleHighlightComplete,
      );
    };
  }, []);

  const navigateToSetting = useCallback(
    async (setting: MerchantAppSetting): Promise<void> => {
      if (currentSettingRef.current === setting.id) {
        return;
      }

      try {
        setNavigationStatus({ status: "navigating", settingId: setting.id });
        currentSettingRef.current = setting.id;

        const path = setting.path;
        const fullPath = `/stores/${teamId}/settings${path.startsWith("/") ? path : `/${path}`}`;

        await navigate(fullPath);

        const settingElement = await waitForElement(setting.id);

        const yOffset = -100;
        const y =
          settingElement.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({
          top: y,
          behavior: "smooth",
        });

        window.history.pushState(null, "", `${fullPath}#${setting.id}`);
        onNavigated?.(setting);
      } catch (error) {
        currentSettingRef.current = null;
        setNavigationStatus({
          status: "error",
          error:
            error instanceof Error ? error : new Error("Navigation failed"),
        });
        console.error("Failed to navigate to setting:", error);
      }
    },
    [navigate, teamId, waitForElement, onNavigated],
  );

  const value = {
    registerSetting,
    navigateToSetting,
    getSettingElement,
    getAllSettings,
    navigationStatus,
    highlightDuration,
  };

  return (
    <SettingNavigationContext.Provider value={value}>
      {children}
    </SettingNavigationContext.Provider>
  );
};

export const useSettingNavigation = () =>
  useRequiredContext(SettingNavigationContext);
