import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { ConversationTimelineEvent } from "@redotech/redo-model/conversation-timeline";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Header } from "@redotech/redo-web/text";
import { memo } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { getConversationTimeline } from "../../client/conversations";
import * as customerActivityCss from "../../customers/customer-detail/customer-activity/customer-activity.module.css";
import { ConversationActivityCard } from "./conversation-activity/conversation-activity-card";

export interface ConversationTimelineProps {
  conversationActivityLoad: LoadState<ConversationTimelineEvent[]>;
}

enum ConversationActivityPanelContentState {
  LOADING = 0,
  HAS_EVENTS = 1,
  NO_EVENTS = 2,
}

export const ConversationActivityPanelContent = memo(
  function ConversationActivityPanelContent({
    conversationActivityLoad,
  }: ConversationTimelineProps) {
    function getConversationActivityPanelContentState(): ConversationActivityPanelContentState {
      if (conversationActivityLoad.pending) {
        return ConversationActivityPanelContentState.LOADING;
      } else if (
        conversationActivityLoad.value &&
        conversationActivityLoad.value.length > 0
      ) {
        return ConversationActivityPanelContentState.HAS_EVENTS;
      } else {
        return ConversationActivityPanelContentState.NO_EVENTS;
      }
    }

    function getConversationActivityPanelContent() {
      switch (getConversationActivityPanelContentState()) {
        case ConversationActivityPanelContentState.LOADING:
          return <LoadingRedoAnimation />;
        case ConversationActivityPanelContentState.HAS_EVENTS:
          return conversationActivityLoad.value?.map(
            (conversationActivity, i) => (
              <ConversationActivityCard
                conversationActivity={conversationActivity}
                key={i}
              />
            ),
          );
        case ConversationActivityPanelContentState.NO_EVENTS:
          return <p>No conversation activity</p>;
      }
    }

    return (
      <Flex
        className={customerActivityCss.contentContainer}
        dir="column"
        gap="xl"
        pl="3xl"
        pr="3xl"
        pt="xl"
      >
        {getConversationActivityPanelContent()}
      </Flex>
    );
  },
);

export const ConversationActivityPanel = memo(
  function ConversationActivityPanel({
    conversationId,
    headerText,
  }: {
    conversationId: string;
    headerText?: string;
  }) {
    const client = useRequiredContext(RedoMerchantClientContext);

    const conversationActivityLoad = useLoad(
      async (signal): Promise<ConversationTimelineEvent[]> => {
        const conversationActivity = await getConversationTimeline(client, {
          conversationId,
          signal,
        });
        return conversationActivity.map((entry) => ({
          ...entry,
          timestamp: new Date(entry.timestamp),
        }));
      },
      [conversationId],
    );

    return (
      <Flex
        className={customerActivityCss.panelContainer}
        dir="column"
        gap="none"
      >
        {headerText && (
          <Flex
            className={customerActivityCss.headerContainer}
            justify="space-between"
            pb="2xl"
            pl="3xl"
            pr="3xl"
            pt="3xl"
          >
            <Header fontFamily="inherit" fontSize="xxs">
              {headerText}
            </Header>
          </Flex>
        )}
        <ConversationActivityPanelContent
          conversationActivityLoad={conversationActivityLoad}
        />
      </Flex>
    );
  },
);
