import { NonIndexRouteObject } from "react-router-dom";

export const conciergeSettingsRoutes: NonIndexRouteObject = {
  handle: { breadcrumb: "Concierge", breadcrumbNavigate: false },
  path: "concierge",
  children: [
    {
      handle: { breadcrumb: "Data sources" },
      path: "data-sources",
      lazy: async () => {
        const { ContextSettingsPage } = await import("./context-settings");
        return { Component: ContextSettingsPage };
      },
    },
    {
      path: "general",
      handle: { breadcrumb: "General" },
      lazy: async () => {
        const { GeneralSettingsPage } = await import("./general");
        return { Component: GeneralSettingsPage };
      },
    },
  ],
};
