import { z } from "zod";

export const triggerConciergeResponse = {
  input: z
    .object({
      conversationId: z.string(),
    })
    .strict(),
  output: z.object({
    success: z.boolean(),
    error: z.string().optional(),
  }),
};
