import { SchemaType } from "@redotech/redo-model/advanced-flow/schemas/schemas";
import { Category } from "@redotech/redo-model/advanced-flow/triggers";
import { TemplateType } from "@redotech/redo-model/email-builder";
import { SMSTemplate } from "@redotech/redo-model/sms-template";
import {
  groupInput,
  input,
  InputProvider,
  optionalInput,
} from "@redotech/ui/form";

export const smsDefault: SmsBuilderValue = {
  team: "",
  name: "",
  content: "Type your campaign message here...",
  templateType: TemplateType.DEFAULT,
  category: "Marketing",
  schemaType: SchemaType.SMS_MARKETING_SIGNUP,
  imageSrc: "",
  autoShortenLinks: true,
};

export const smsBuilderInput = groupInput({
  team: input<string>(),
  name: input<string>(),
  content: input<string>(),
  templateType: input<TemplateType>(),
  category: input<Category>(),
  schemaType: input<SchemaType>(),
  imageSrc: optionalInput(input<string>(), () => ""),
  autoShortenLinks: optionalInput(input<boolean>(), () => true),
});

export type SmsBuilderValue = InputProvider.Value<typeof smsBuilderInput>;
export type SmsBuilderForm = InputProvider.Form<typeof smsBuilderInput>;

export function getSmsBuilderDefault(): SmsBuilderValue {
  return smsDefault;
}

export type StaticSmsBuilderElement = SMSTemplate;
