import { campaignWithOutreachAnalyticsSchema } from "@redotech/redo-model/campaigns/campaign-definition";
import { z } from "zod";

export const getTeamCampaigns = {
  input: z.object({
    includeStats: z.boolean().optional(),
    sortKey: z.string(),
    sortDirection: z.enum(["asc", "desc"]),
  }),
  output: z.array(campaignWithOutreachAnalyticsSchema),
};
