import { SchemaInstance, defineSchema } from "../../type-system/schema";
import {
  baseTrackingSchema,
  exampleBaseTrackingSchemaInstance,
} from "./base-tracking";

export const orderTrackingSchema = defineSchema({
  fields: {
    ...baseTrackingSchema.fields,
  },
});

export const exampleOrderTrackingSchemaInstance: SchemaInstance<
  typeof orderTrackingSchema
> = {
  ...exampleBaseTrackingSchemaInstance,
};

// const defaultTracker: TrackerDataType = {
//   Code: "1234567890",
//   Carrier: "USPS",
//   Status: "delivered" as ExternalShipmentTracker['status'],
//   DeliveryEstimate: new Date(
//     Temporal.Now.instant().add({ hours: weekInHours }).toString(),
//   ),
//   RoughDeliveryEstimate: new Date(
//     Temporal.Now.instant().add({ hours: weekInHours }).toString(),
//   ),
//   Details: [
//     {
//       Message: "Order received",
//       Status: "pre_transit" as ExternalShipmentTracker['status'],
//       Datetime: new Date(),
//     },
//   ],
//   Link: "https://example.com",
// };
