import { PaymentIntentItem } from "@redotech/redo-model/payment-intent";
import type { MerchantAppReturn } from "@redotech/redo-model/return";
import { Card } from "@redotech/redo-web/card";
import { CURRENCY_FORMAT, CurrencyContext } from "@redotech/redo-web/currency";
import { ExternalLink } from "@redotech/redo-web/link";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { Event, Timeline } from "@redotech/redo-web/timeline";
import { Fragment, memo, useContext } from "react";
// import * as returnCss from "./return.module.css";
import { Currency, CurrencyMinorUnits } from "@redotech/money/currencies";
import { orderStatusName } from "../util";
import * as transactionsCss from "./transactions-card.module.css";

const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
});

export const TransactionsCard = memo(function TransactionsCard({
  return: return_,
}: {
  return?: MerchantAppReturn;
}) {
  const processTransactionEvent = (
    paymentIntentItem: PaymentIntentItem,
    eventType: string,
  ): Event[] => {
    const events: Event[] = [];

    events.push({
      message: `Customer charged for ${eventType}`,
      at: Temporal.Instant.fromEpochSeconds(
        paymentIntentItem.payment_intent.created,
      ),
      detail: `Charged for ${CURRENCY_FORMAT(
        paymentIntentItem.payment_intent.currency || Currency.USD,
      ).format(
        paymentIntentItem.payment_intent.amount /
          10 **
            (CurrencyMinorUnits[
              (paymentIntentItem.payment_intent.currency as Currency) ||
                Currency.USD
            ] ?? 2),
      )}`,
    });

    if (paymentIntentItem.refund) {
      events.push({
        message: `Customer refunded for ${eventType}`,
        at: Temporal.Instant.fromEpochSeconds(paymentIntentItem.refund.created),
        detail: `Refunded ${CURRENCY_FORMAT(
          paymentIntentItem.payment_intent.currency || Currency.USD,
        ).format(
          paymentIntentItem.refund.amount /
            10 **
              (CurrencyMinorUnits[
                (paymentIntentItem.payment_intent.currency as Currency) ||
                  Currency.USD
              ] ?? 2),
        )}`,
      });
    }

    return events;
  };

  const transactionEvents: Event[] = [];
  for (const paymentIntentItem of return_?.paymentIntents || []) {
    if (paymentIntentItem.type === "label") {
      transactionEvents.push(
        ...processTransactionEvent(paymentIntentItem, "return shipping label"),
      );
    } else if (paymentIntentItem.type === "recovery") {
      transactionEvents.push(
        ...processTransactionEvent(paymentIntentItem, "recovery fee"),
      );
    }
  }
  const { formatCurrency } = useContext(CurrencyContext);

  return return_?.exchanges.length ||
    return_?.draftOrder ||
    return_?.refunds.length ||
    return_?.storedCredits.length ||
    return_?.giftCards.length ? (
    <Card title="Transactions">
      {(return_?.exchanges.length || return_?.draftOrder) && (
        <div className={transactionsCss.summaryInfoSection}>
          <h3>
            <b>Exchange Order</b>
          </h3>
          {return_?.exchanges.length
            ? return_?.exchanges.map((order, i) => (
                <div className={transactionsCss.summaryInfo} key={i}>
                  Order{" "}
                  <ExternalLink
                    showIcon={false}
                    url={`https://${return_.team.storeUrl}/admin/orders/${
                      order.id || ""
                    }`}
                  >
                    {order.name}
                    {"  "}
                  </ExternalLink>
                  <Pill size={PillSize.SMALL}>
                    {orderStatusName(order.fulfillment_status)}
                  </Pill>
                  <span className={transactionsCss.summaryDate}>
                    {"  "}
                    {dateTimeFormat.format(new Date(order.created_at))}
                  </span>
                </div>
              ))
            : return_?.draftOrder && (
                <>
                  <div className={transactionsCss.summaryInfo}>
                    Draft Order{" "}
                    <ExternalLink
                      showIcon={false}
                      url={`https://${
                        return_.team.storeUrl
                      }/admin/draft_orders/${return_.draftOrder.id || ""}`}
                    >
                      {return_.draftOrder.name}
                    </ExternalLink>{" "}
                    <span className={transactionsCss.summaryDate}>
                      {"  "}
                      {dateTimeFormat.format(
                        new Date(return_.draftOrder.created_at),
                      )}
                    </span>
                  </div>
                  {return_.draftOrder.invoice_sent_at && (
                    <div className={transactionsCss.summaryInfo}>
                      Invoice sent to customer{" "}
                      <span className={transactionsCss.summaryDate}>
                        {dateTimeFormat.format(
                          new Date(return_.draftOrder.invoice_sent_at),
                        )}
                      </span>
                    </div>
                  )}
                </>
              )}
          {return_?.totals.depositRefunded && (
            <div className={transactionsCss.summaryInfo}>
              Instant exchange deposit refunded
            </div>
          )}
        </div>
      )}
      {return_?.giftCards.length ? (
        <div className={transactionsCss.summaryInfoSection}>
          <h3>
            <b>Gift cards</b>
          </h3>
          {return_?.giftCards.map((giftCard, i) => (
            <div className={transactionsCss.summaryInfo} key={i}>
              {giftCard.code}
              {"  "}
              <b>{formatCurrency(giftCard.value || 0)}</b>
              <span className={transactionsCss.summaryDate}>
                {"  "}
                {dateTimeFormat.format(new Date(giftCard.createdAt))}
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {return_?.storedCredits.length ? (
        <div className={transactionsCss.summaryInfoSection}>
          <h3>
            <b>Discount codes</b>
          </h3>
          {return_?.storedCredits.map((discountCode, index) => (
            <div className={transactionsCss.summaryInfo} key={index}>
              {discountCode.code}
              {"  "}
              <b>{formatCurrency(discountCode.value ?? 0)}</b>
              <span className={transactionsCss.summaryDate}>
                {"  "}
                {dateTimeFormat.format(new Date(discountCode.createdAt))}
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {return_?.refunds.length ? (
        <div className={transactionsCss.summaryInfoSection}>
          <h3>
            <b>Refunds</b>
          </h3>
          {return_?.refunds.map((refund, i) => (
            <Fragment key={i}>
              {refund.refund?.transactions.map(
                (transaction: any, j: number) => (
                  <div className={transactionsCss.summaryInfo} key={j}>
                    <b>
                      {CURRENCY_FORMAT(transaction.currency).format(
                        transaction.amount,
                      )}{" "}
                    </b>
                    to the original payment method
                    {"  "}
                    <span className={transactionsCss.summaryDate}>
                      {dateTimeFormat.format(new Date(transaction.created_at))}
                    </span>
                  </div>
                ),
              )}
            </Fragment>
          ))}
        </div>
      ) : null}
      {transactionEvents.length ? (
        <div className={transactionsCss.summaryInfoSection}>
          <h3>
            <b>Customer Payments</b>
          </h3>
          <Timeline events={transactionEvents} />
        </div>
      ) : null}
    </Card>
  ) : null;
});
