import { CurrencyCode } from "@redotech/shopify-client/storefront.graphql";
import {
  GetCartQuery,
  GetCartQueryVariables,
} from "./shopify-storefront.graphql";
import { ShopifyStorefrontClient } from "./storefront-client";

const CART_DATA_FRAGMENT = /* GraphQL */ `
  fragment CartData on Cart {
    id
    totalQuantity
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      checkoutChargeAmount {
        amount
        currencyCode
      }
    }
    lines(first: 250) {
      edges {
        node {
          id
          quantity
          discountAllocations {
            __typename
            discountedAmount {
              amount
              currencyCode
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              image {
                originalSrc
                url
              }
              title
              price {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
              product {
                id
                title
                vendor
                options {
                  id
                  name
                  values
                }
                onlineStoreUrl
              }
            }
          }
        }
      }
    }
  }
`;

const getCartQuery = /* GraphQL */ `
  query GetCart($id: ID!) {
    cart(id: $id) {
      ...CartData
    }
  }
  ${CART_DATA_FRAGMENT}
`;

export interface CartLine {
  node: {
    id: string;
    cost: {
      totalAmount: {
        amount: string;
        currencyCode: string;
      };
    };
    discountAllocations?: {
      __typename: string;
      discountedAmount: {
        amount: string;
        currencyCode: string;
      };
    }[];
    merchandise: {
      id: string;
      availableForSale: boolean;
      image?: {
        /** @deprecated Use `url` instead */
        originalSrc: string;
        url: string;
      } | null;
      price: {
        amount: string;
        currencyCode: string;
      };
      selectedOptions: {
        name: string;
        value: string;
      }[];
      product: {
        title: string;
        vendor: string;
        id: string;
        options: {
          id: string;
          name: string;
          values: string[];
        }[];
        onlineStoreUrl?: string;
      };
      title: string;
    };
    quantity: number;
  };
}

export interface CartData {
  id: string;
  totalQuantity: number;
  checkoutUrl: string;
  cost: {
    subtotalAmount: {
      amount: string;
      currencyCode: string;
    };
    totalTaxAmount?: {
      amount: string;
      currencyCode: string;
    } | null;
    checkoutChargeAmount: {
      amount: string;
      currencyCode: CurrencyCode;
    };
    totalAmount: {
      amount: string;
      currencyCode: string;
    };
  };
  lines: {
    edges: CartLine[];
  };
}

const cartGidPrefix = "gid://shopify/Cart/";

export async function getCart(
  client: ShopifyStorefrontClient,
  id: string,
): Promise<CartData> {
  const gid = id.startsWith(cartGidPrefix) ? id : `${cartGidPrefix}${id}`;
  const { data, errors } = await client.request<
    GetCartQuery,
    GetCartQueryVariables
  >(getCartQuery, { id: gid });

  if (errors) {
    throw errors.graphQLErrors ?? errors;
  }

  if (!data?.cart) {
    throw new Error("Cart not found");
  }

  return data.cart;
}

// slightly more convenient function signature
export async function getTeamCart(
  team: {
    storeUrl: string;
  },
  storefrontAccessToken: string,
  cartId: string,
) {
  return getCart(
    new ShopifyStorefrontClient(team.storeUrl, storefrontAccessToken),
    cartId,
  );
}
