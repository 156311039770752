// extracted by mini-css-extract-plugin
export var actionButton = "_9b-0";
export var bubble = "_9b-2";
export var bubbleContext = "_9b-6";
export var bubbleItems = "_9b-7";
export var customer = "_9b-3";
export var divider = "_9b-8";
export var fileList = "_9b-9";
export var infoAbove = "_9b-a";
export var internal = "_9b-4";
export var lightActionButton = "_9b-1";
export var link = "_9b-b";
export var merchant = "_9b-5";
export var message = "_9b-c";
export var profilePicture = "_9b-e";
export var quillToolbarContainer = "_9b-f";
export var senderDate = "_9b-g";
export var senderDateCustomer = "_9b-h";
export var senderName = "_9b-d";
export var time = "_9b-i";
export var uploadedImage = "_9b-j";
export var username = "_9b-k";
export var xsmall = "_9b-l";