import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, ReactNode } from "react";
import * as customerActivityCardCss from "../../../common/activity-card.module.css";

export const BaseConversationActivityCard = memo(
  function BaseConversationActivityCard({
    topLeftElement,
    timestamp,
    extraDetailsElement,
  }: {
    topLeftElement: ReactNode;
    timestamp: Date;
    extraDetailsElement?: ReactNode | ReactNode[] | undefined;
  }) {
    return (
      <Flex
        className={`${customerActivityCardCss.baseCustomerActivityCardContainer}`}
        dir="column"
        gap="none"
        p="sm"
      >
        <Flex align="center" dir="row" justify="space-between" pb="md">
          {topLeftElement}
          {extraDetailsElement}
        </Flex>
        <Text fontSize="xs" textColor="tertiary">
          {formatTimeAgo(timestamp.toISOString())}
        </Text>
      </Flex>
    );
  },
);
