import { InputMask } from "@redotech/react-util/input-mask";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  JSXElementConstructor,
  memo,
  ReactNode,
} from "react";
import { Flex } from "../../flex";
import { BaseRedoInputContainer } from "./base-redo-input-container";
import * as redoTextInputCss from "./base-redo-input.module.css";
import {
  BaseRedoInput,
  RedoInputSize,
  RedoInputState,
  sizeStyles,
} from "./base-redo-text-input";
import { BaseRedoInputInfoTooltip } from "./base-redo-text-input-info-tooltip";

/**
 * @param inputTypeHint -- if you have one of these types of input attributes,
 * browser autofill and other helpful features will work better if you define it.
 *
 * @param required -- when used in a form, will prevent the form from submitting if the input is empty.
 *
 * @param dangerousStyleThatShouldOnlyBeUsedForMerchantBranding -- this prop should only be defined
 * when we want the input to follow a merchant's branding. Do not use it to style this component for a Redo use case.
 */
export interface RedoTextInputProps {
  value: string;
  setValue(value: string): void;
  placeholder?: string;
  size?: RedoInputSize;
  state?: RedoInputState;
  label?: string;
  description?: ReactNode;
  IconLeading?: JSXElementConstructor<any>;
  infoTooltip?: string;
  inputTypeHint?: HTMLInputTypeAttribute;
  required?: boolean;
  maxLength?: number;
  className?: string;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  name?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  mask?: InputMask;
  autoCompleteDisabled?: boolean;
  trailingActions?: ReactNode;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const RedoTextInput = memo(
  forwardRef(function RedoTextInput(
    {
      size = RedoInputSize.SMALL,
      value,
      placeholder,
      setValue,
      IconLeading,
      infoTooltip,
      inputTypeHint,
      label,
      description,
      state = RedoInputState.DEFAULT,
      className,
      required,
      maxLength,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
      name,
      onFocus,
      onBlur,
      mask,
      autoCompleteDisabled,
      trailingActions,
      onKeyDown,
    }: RedoTextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const readonly = state === RedoInputState.READONLY;

    return (
      <BaseRedoInputContainer
        className={className}
        dangerousStyleThatShouldOnlyBeUsedForMerchantBranding={
          dangerousStyleThatShouldOnlyBeUsedForMerchantBranding
        }
        description={description}
        label={label}
        size={size}
        state={state}
      >
        {IconLeading && !readonly && (
          <Flex
            className={classNames(
              sizeStyles[size],
              redoTextInputCss.leadingIconWrapper,
            )}
          >
            <IconLeading />
          </Flex>
        )}
        <BaseRedoInput
          autoCompleteDisabled={autoCompleteDisabled}
          inputTypeHint={inputTypeHint}
          mask={mask}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          ref={ref}
          required={required}
          setValue={setValue}
          size={size}
          state={state}
          value={value}
        />
        {trailingActions && <>{trailingActions}</>}
        {infoTooltip && (
          <BaseRedoInputInfoTooltip
            infoTooltip={infoTooltip}
            size={size}
            state={state}
          />
        )}
      </BaseRedoInputContainer>
    );
  }),
);
