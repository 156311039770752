// extracted by mini-css-extract-plugin
export var defaultWrapper = "_89-3";
export var downloadButton = "_89-4";
export var fileContainer = "_89-7";
export var fileInfo = "_89-5";
export var fileInfoWidthRestricted = "_89-6";
export var fileType = "_89-1";
export var fileTypeName = "_89-2";
export var image = "_89-0";
export var link = "_89-9";
export var overflowEllipsis = "_89-8";