import { CarrierAccount } from "@redotech/redo-model/carrier-account/carrier-account";
import { z } from "zod";

export const deleteCarrierAccount = {
  input: z.object({
    carrierAccount: CarrierAccount,
  }),
  output: z.object({
    carrierAccounts: z.array(CarrierAccount),
  }),
};
