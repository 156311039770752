import { compressToBase64, decompressFromBase64 } from "lz-string";
import { z } from "zod";
import { CompensationMethodFilterSchema } from "./compensation-method-filter";
import { CompensationMethodsFilterSchema } from "./compensation-methods-filter";
import { ProductNameFilterSchema } from "./product-name-filter";
import { ProductProcessedDateFilterSchema } from "./product-processed-date-filter";
import { ProductStatusFilterSchema } from "./product-status-filter";
import { ReturnCreatedDateFilterSchema } from "./return-created-date-filter";
import { ReturnProcessedDateFilterSchema } from "./return-processed-date-filter";
import { ReturnStatusFilterSchema } from "./return-status-filter";
import { ReturnTypeFilterSchema } from "./return-type-filter";

export const ReportFilterSchema = z.union([
  ReturnTypeFilterSchema,
  // OrderCreatedDateFilterSchema,
  // OrderDeliveryDateFilterSchema,
  ReturnCreatedDateFilterSchema,
  // ReturnDeliveryDateFilterSchema,
  // ApprovalDateFilterSchema,
  ReturnProcessedDateFilterSchema,
  // PurchasedRedoFilterSchema,
  ReturnStatusFilterSchema,
  CompensationMethodsFilterSchema,

  ProductProcessedDateFilterSchema,
  ProductStatusFilterSchema,
  CompensationMethodFilterSchema,
  ProductNameFilterSchema,
  // VariantNameFilterSchema,
]);

export type ReportFilter = z.infer<typeof ReportFilterSchema>;

export function reportFiltersToQueryString(filters: ReportFilter[]): string {
  const str = JSON.stringify(filters);
  const compressed = compressToBase64(str);
  return compressed;
}

/** @throws If the string does not represent a valid filters array */
export function queryStringToReportFilters(
  queryString: string,
): ReportFilter[] {
  const decompressed = decompressFromBase64(queryString);
  const obj = JSON.parse(decompressed);
  return ReportFilterSchema.array().parse(obj);
}
