import { SlideInPanelAnchorLocation } from "@redotech/redo-model/slide-in-panel/slide-in-panel-anchor-location";
import { z } from "zod";

export const updateSaveForLaterStorefrontDisplaySettings = {
  input: z.object({
    teamId: z.string(),
    enabled: z.boolean(),
    title: z.string(),
    message: z.string(),
    numViewedProductsUntilPanelOpen: z.number(),
    anchorLocation: z.nativeEnum(SlideInPanelAnchorLocation),
  }),
  output: z.null(),
};
