import { z } from "zod";

/**
 * Based on Shopify's "Inventory Policy"
 * https://shopify.dev/docs/api/admin-graphql/2024-10/enums/ProductVariantInventoryPolicy
 *
 * This enum is intentionally separate from Shopify's enum to keep the Product
 * collection platform agnostic.
 */
export enum InventoryPolicy {
  /**
   * Customers can buy this product after it's out of stock.
   */
  CONTINUE = "continue",
  /**
   * Customers can't buy this product after it's out of stock.
   */
  DENY = "deny",
}

/**
 * Shopify inventory policies are uppercase
 */
export function shopifyInventoryPolicyToInventoryPolicy(
  shopifyInventoryPolicy: string,
): InventoryPolicy {
  switch (shopifyInventoryPolicy) {
    case "CONTINUE":
      return InventoryPolicy.CONTINUE;
    case "DENY":
      return InventoryPolicy.DENY;
    default:
      throw new Error(`Unknown inventory policy: ${shopifyInventoryPolicy}`);
  }
}

export const ProductImage = z.object({
  url: z.string(),
  alt: z.string(),
});

export const VariantInfo = z.object({
  id: z.string(),
  price: z.string(),
  inventoryPolicy: z.nativeEnum(InventoryPolicy),
  inventoryQuantity: z.number(),
  inventoryTracked: z.boolean(),
  sku: z.string(),
  name: z.string(),
  quantityAvailable: z.number(),
  image: ProductImage,
  productId: z.string(),
  productName: z.string(),
});

export type VariantInfo = z.infer<typeof VariantInfo>;

export const ProductInfo = z.object({
  id: z.string(),
  name: z.string(),
  image: ProductImage,
  description: z.string(),
  tags: z.array(z.string()),
  variants: z.array(VariantInfo),
});

export type ProductInfo = z.infer<typeof ProductInfo>;

export enum ECommercePlatform {
  SHOPIFY = "shopify",
  COMMERCE_CLOUD = "commerceCloud",
  COMMENT_SOLD = "commentsold",
}

/**
 * Converts `123` to `gid://shopify/Product/123`
 */
export function productIdToShopifyGlobalProductId(productId: string): string {
  return `gid://shopify/Product/${productId}`;
}

/**
 * Converts `gid://shopify/Product/123` to `123`
 */
export function shopifyGlobalProductIdToProductId(
  globalProductId: string,
): string {
  const productId = globalProductId.split("/").pop();
  if (!productId) {
    throw new Error(`Invalid global product ID: ${globalProductId}`);
  }
  return productId;
}

export const getShopifyProductDetailPageUrl = (
  storeUrl: string,
  productHandle: string,
): string => {
  return `https://${storeUrl}/products/${productHandle}`;
};
