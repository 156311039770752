// extracted by mini-css-extract-plugin
export var alignCenter = "_5j-b";
export var buttonContent = "_5j-0";
export var cardList = "_5j-1";
export var centerContent = "_5j-2";
export var chevron = "_5j-3";
export var closePanelButton = "_5j-i";
export var conversationDetailContainer = "_5j-4";
export var detailWrapper = "_5j-5";
export var dropdownButton = "_5j-c";
export var dropdownTitle = "_5j-7";
export var hidden = "_5j-8";
export var icon = "_5j-9";
export var panel = "_5j-g";
export var portalButtonsWrapper = "_5j-a";
export var right = "_5j-h";
export var tableSummaryContainer = "_5j-6";
export var tableSummaryHeader = "_5j-e";
export var threeDotsButton = "_5j-d";
export var wide = "_5j-f";