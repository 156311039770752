import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import {
  Campaign,
  CampaignWithCustomerGroups,
} from "@redotech/redo-model/campaigns/campaign-definition";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoBadge,
  RedoBadgeColor,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButtonHierarchy } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import DotIcon from "@redotech/redo-web/arbiter-icon/dot-01.svg";
import { Flex } from "@redotech/redo-web/flex";
import {
  FormBuilderHeader,
  FormBuilderHeaderButton,
} from "@redotech/redo-web/form-builder/form-builder-header";
import { FormBuilderPageWrapper } from "@redotech/redo-web/form-builder/form-builder-page-wrapper";
import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RedoMerchantRpcClientContext } from "../../../app/redo-merchant-rpc-client-provider";

export interface CampaignWizardPageProps {
  unmodifiedCampaign: CampaignWithCustomerGroups;
  campaign: CampaignWithCustomerGroups;
  setCampaign: (campaign: CampaignWithCustomerGroups) => void;
  save: () => Promise<void>;
  saveAndContinue: (currentStep: CampaignWizardStep) => Promise<void>;
  reload: () => void;
  pending: boolean;
}

enum CampaignStatus {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  FINISHED = "finished",
}

const statusDisplay: Record<CampaignStatus, string> = {
  [CampaignStatus.DRAFT]: "Draft",
  [CampaignStatus.SCHEDULED]: "Scheduled",
  [CampaignStatus.FINISHED]: "Finished",
};

export enum CampaignWizardStep {
  GROUPS = "groups",
  TEMPLATE = "template",
  PREVIEW = "preview",
}

const nextStep: Record<CampaignWizardStep, CampaignWizardStep | undefined> = {
  [CampaignWizardStep.GROUPS]: CampaignWizardStep.TEMPLATE,
  [CampaignWizardStep.TEMPLATE]: CampaignWizardStep.PREVIEW,
  [CampaignWizardStep.PREVIEW]: undefined,
};

export const CampaignWizardPage = memo(function CampaignWizardPage({
  actionButton,
  children,
}: {
  actionButton: (props: CampaignWizardPageProps) => FormBuilderHeaderButton;
  children: (props: CampaignWizardPageProps) => React.ReactNode;
}) {
  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );
  const navigate = useNavigate();
  const params = useParams();
  const [saving, setSaving] = useState(false);
  const [campaignInProgress, setCampaignInProgress] = useState<
    Campaign | undefined
  >(undefined);

  const campaignId = params.campaignId;

  const [campaignReloadKey, setCampaignReloadKey] = useState(0);
  const campaignLoad = useLoad(async () => {
    const campaign = campaignId
      ? await redoMerchantRpcClient.getCampaignById({
          id: campaignId,
          includeCustomerGroups: true,
        })
      : undefined;
    if (campaign?.smsTemplate) {
      campaign.smsTemplate.content = reverseSanitizeSmsBuilderContent(
        campaign.smsTemplate.content,
      );
    }
    return campaign;
  }, [campaignId, campaignReloadKey]);
  const reloadCampaign = () => setCampaignReloadKey((key) => key + 1);

  const campaign = campaignLoad?.value;

  useEffect(() => {
    if (campaign) {
      setCampaignInProgress(campaign);
    }
  }, [campaign]);

  const saveChanges = async (successToast?: boolean) => {
    if (!campaignInProgress) {
      return;
    }
    setSaving(true);

    const fieldsToSet = {
      ...campaignInProgress,
    };
    if (fieldsToSet.smsTemplate) {
      fieldsToSet.smsTemplate.content = sanitizeSmsBuilderContent(
        fieldsToSet.smsTemplate.content,
      );
    }
    try {
      await redoMerchantRpcClient.updateCampaign({
        id: campaignInProgress._id.toString(),
        fields: fieldsToSet,
      });
      if (successToast) {
        toast("Campaign saved", { variant: "success" });
      }
    } catch (error) {
      toast(`Failed to save campaign: ${error}`, { variant: "error" });
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const setCampaign = (newCampaign: Campaign) => {
    setCampaignInProgress({
      ...campaign,
      ...newCampaign,
    });
  };

  const childProps: CampaignWizardPageProps | undefined =
    campaign && campaignInProgress
      ? {
          unmodifiedCampaign: campaign,
          campaign: campaignInProgress,
          setCampaign,
          pending: saving,
          reload: reloadCampaign,
          save: saveChanges,
          saveAndContinue: async (currentStep: CampaignWizardStep) => {
            const next = nextStep[currentStep];
            await saveChanges();
            if (next) {
              navigate(`../${campaignInProgress._id}/edit/${next}`);
            } else {
              navigate("..");
            }
          },
        }
      : undefined;

  const status = campaign?.scheduledAt
    ? CampaignStatus.SCHEDULED
    : campaign?.finishedAt
      ? CampaignStatus.FINISHED
      : CampaignStatus.DRAFT;

  return (
    <FormBuilderPageWrapper>
      <FormBuilderHeader
        buttons={[
          {
            text: "Cancel",
            onClick: () => {
              navigate("..");
            },
            hierarchy: RedoButtonHierarchy.TERTIARY,
          },
          {
            text: "Save as draft",
            onClick: () => saveChanges(true),
            hierarchy: RedoButtonHierarchy.SECONDARY,
            pending: saving,
          },
          ...(childProps ? [actionButton(childProps)] : []),
        ]}
        descriptionRender={
          <RedoBadge
            color={RedoBadgeColor.GRAY}
            iconColor={
              campaign?.finishedAt
                ? RedoBadgeColor.SUCCESS
                : RedoBadgeColor.WARNING
            }
            segmentLeading={{
              type: "icon",
              Icon: DotIcon,
            }}
            text={statusDisplay[status]}
          />
        }
        title={campaign?.name ?? ""}
      />
      <Flex
        dir="row"
        flex={1}
        gap="none"
        justify="space-between"
        overflow="hidden"
        w="full"
      >
        {childProps && children(childProps)}
      </Flex>
    </FormBuilderPageWrapper>
  );
});

function sanitizeSmsBuilderContent(content: string) {
  const spanRegex = /<span[^>]*>(.*?)<\/span>/gi;

  return content.replace(spanRegex, (match, p1) => {
    return `[](${p1})`;
  });
}

function reverseSanitizeSmsBuilderContent(content: string) {
  const spanRegex = /\[(.*?)\]\((.*?)\)/gi;
  return content.replace(spanRegex, (match, p1, p2) => {
    return `<span style="text-decoration: underline;">${p2}</span>`;
  });
}
