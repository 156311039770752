import { Return } from "@redotech/redo-model/return";
import { PickupPayer } from "@redotech/redo-model/return-flow";
import { ReturnTotals } from "@redotech/redo-model/return-totals-calculator";
import { Card, SubtotalCollapseSection } from "@redotech/redo-web/card";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { getDateString } from "@redotech/redo-web/date-utils";
import { Divider } from "@redotech/redo-web/divider";
import { memo, useContext, useMemo } from "react";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";

interface SubtotalBreakdown {
  title: string;
  total: string;
}

const getNewOrderValueBreakdown = (
  returnTotals: ReturnTotals,
  advancedExchangeItems: any[],
  products: any[],
  formatCurrency: (value: number) => string,
) => {
  const newOrderValues: SubtotalBreakdown[] = [];
  const groupedItems = new Map<string, { count: number; price: number }>();

  // Group advanced exchange and variant exchange items
  for (const item of advancedExchangeItems) {
    const key = `${item.title}:${item.itemValue}`;
    const existing = groupedItems.get(key);
    if (existing) {
      existing.count++;
    } else {
      groupedItems.set(key, {
        count: 1,
        price: Math.min(parseFloat(item.itemValue), parseFloat(item.price)),
      });
    }
  }

  // Group same item exchanges
  for (const product of products) {
    if (product.exchange_for) {
      const price = product.price || product.exchange_for.price || 0;
      const key = `${product.exchange_for?.product_title}:${price}`;
      const existing = groupedItems.get(key);
      if (existing) {
        existing.count++;
      } else {
        groupedItems.set(key, { count: 1, price });
      }
    } else if (product.exchangeGroupItem) {
      const price = product.price || product.exchangeGroupItem.price || 0;
      const key = `${product.exchangeGroupItem.title}:${price}`;
      const existing = groupedItems.get(key);
      if (existing) {
        existing.count++;
      } else {
        groupedItems.set(key, { count: 1, price });
      }
    }
  }

  // Aggregate the grouped items
  for (const [key, value] of groupedItems) {
    const [title] = key.split(":");
    newOrderValues.push({
      title: value.count > 1 ? `${title} (x${value.count})` : title,
      total: formatCurrency(value.price * value.count),
    });
  }

  const newOrderTaxes =
    returnTotals.totalNewOrderTaxes || returnTotals.newOrderTaxes || 0;

  if (newOrderTaxes > 0) {
    newOrderValues.push({
      title: "Taxes",
      total: formatCurrency(newOrderTaxes),
    });
  }

  return newOrderValues;
};

export const SummaryCard = memo(function SummaryCard({
  return: return_,
  returnTotals,
  nonZeroValueExchange,
}: {
  return?: Return;
  returnTotals?: ReturnTotals;
  nonZeroValueExchange?: boolean;
}) {
  const { formatCurrency } = useContext(CurrencyContext);
  const deductedPickupFee =
    return_?.pickupMetrics?.rate &&
    return_?.pickup?.pickupPayer === PickupPayer.CUSTOMER_DEDUCT
      ? return_?.pickupMetrics?.rate
      : 0;

  // Prevent the page from exploding when there's an improperly formatted pickup date
  const pickupDateString = useMemo(() => {
    if (!return_?.pickup) {
      return undefined;
    }
    try {
      return getDateString(Temporal.PlainDate.from(return_.pickup.pickupDate));
    } catch {
      console.warn(`Could not parse pickup date: ${return_.pickup.pickupDate}`);
      return return_.pickup.pickupDate;
    }
  }, [return_?.pickup]);

  const isAdvancedExchange =
    return_?.advancedExchangeItems && return_?.advancedExchangeItems.length > 0;

  return (
    <Card title="Summary">
      <table className={returnCss.table}>
        <tbody>
          <tr>
            <th className={returnCss.tableHeader}>
              {returnTypeName(return_?.type, true)} value
            </th>
            <td className={returnCss.tableCell}>
              {return_ && formatCurrency(returnTotals?.totalReturnValue || 0)}
            </td>
          </tr>
          {!!returnTotals?.newOrderValue && (
            <tr>
              <td colSpan={2}>
                <SubtotalCollapseSection
                  breakdownEntryTextClassName={returnCss.summaryText}
                  header={{
                    title: "New order value",
                    total: formatCurrency(returnTotals?.newOrderValue),
                  }}
                  subtitleTextClassName={returnCss.summaryTextLight}
                  subtotals={getNewOrderValueBreakdown(
                    returnTotals,
                    return_?.advancedExchangeItems ?? [],
                    return_?.products ?? [],
                    formatCurrency,
                  )}
                  titleTextClassName={returnCss.summaryText}
                />
              </td>
            </tr>
          )}
          {isAdvancedExchange && !!returnTotals?.nonAccruableCredit && (
            <tr>
              <th className={returnCss.tableHeader}>Non-accruable credit</th>
              <td className={returnCss.tableCell}>
                -{return_ && formatCurrency(returnTotals?.nonAccruableCredit)}
              </td>
            </tr>
          )}
          {return_?.labelDeductedFromCredit &&
            !!(return_.totals.fee - deductedPickupFee) && (
              <tr>
                <th className={returnCss.tableHeader}>Return shipping label</th>
                <td className={returnCss.tableCell}>
                  {return_ && formatCurrency(return_.totals.fee)}
                </td>
              </tr>
            )}
          {return_?.pickup && deductedPickupFee > 0 && (
            <tr>
              <th className={returnCss.tableHeader}>
                Package pickup on {pickupDateString}
              </th>
              <td className={returnCss.tableCell}>
                {return_ && formatCurrency(deductedPickupFee)}
              </td>
            </tr>
          )}
          {/* FIXME Does not include line for Settlement discount, so the refund amount won't add up with the return value */}
        </tbody>
      </table>
      <Divider />
      <table className={returnCss.table}>
        <tbody>
          {!!returnTotals?.displayCharge && (
            <tr>
              <th className={returnCss.tableHeader}>{`Outstanding Balance${
                return_?.options?.waiveDraftFee || nonZeroValueExchange
                  ? " (waived)"
                  : return_?.paymentIntents?.some((pi) => pi.type === "upsell")
                    ? " (paid)"
                    : ""
              }`}</th>
              <td className={returnCss.tableCell}>
                {return_ && formatCurrency(returnTotals?.displayCharge)}
              </td>
            </tr>
          )}
          {!!returnTotals?.totalStoreCredit && (
            <tr>
              <th className={returnCss.tableHeader}>Store credit</th>
              <td className={returnCss.tableCell}>
                {return_ && formatCurrency(returnTotals?.totalStoreCredit)}
              </td>
            </tr>
          )}
          {!!returnTotals?.totalRefund && (
            <tr>
              <th className={returnCss.tableHeader}>Refund</th>
              <td className={returnCss.tableCell}>
                {return_ && formatCurrency(returnTotals?.totalRefund)}
              </td>
            </tr>
          )}
          {!!return_?.totals.fee &&
            (!return_?.pickup ||
              return_?.totals.fee - (return_?.pickupMetrics?.rate || 0) >
                0) && (
              <tr>
                <th className={returnCss.tableHeaderGroup}>
                  <div className={returnCss.tableHeader}>Shipping label</div>
                  <div className={returnCss.tableDescription}>
                    Paid for by customer
                  </div>
                </th>
                <td className={returnCss.tableCell}>
                  {return_ &&
                    formatCurrency(
                      return_?.pickup
                        ? return_.totals.fee -
                            (return_?.pickupMetrics?.rate || 0)
                        : return_.totals.fee,
                    )}
                </td>
              </tr>
            )}
          {return_?.pickup && !deductedPickupFee && (
            <tr>
              <th className={returnCss.tableHeaderGroup}>
                <div className={returnCss.tableHeader}>
                  Package pickup on {pickupDateString}
                </div>
                {return_.pickup.pickupPayer !== PickupPayer.CUSTOMER_DEDUCT ? (
                  <div className={returnCss.tableDescription}>
                    Paid for by{" "}
                    {return_.pickup.pickupPayer === PickupPayer.MERCHANT
                      ? "merchant"
                      : "customer"}
                  </div>
                ) : undefined}
              </th>
              <td className={returnCss.tableCell}>
                {return_?.pickupMetrics?.rate &&
                  formatCurrency(return_.pickupMetrics.rate)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
});
