import { z } from "zod";
import { upsellPageDataSchema } from "./upsell-page-data";

export const getActiveUpsellPageForTeam = {
  input: z.null(),
  output: z
    .object({
      upsellPageId: z.string(),
      upsellPageData: upsellPageDataSchema,
    })
    .nullable(),
};
