import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  CustomerEvent,
  CustomerEventType,
  OutreachClickEvent,
  OutreachOpenEvent,
} from "../customer-event/customer-event-definition";
import { MarketingSubscriptionType } from "../marketing";

export enum CampaignRecipientStatus {
  PENDING = "pending",
  PROCESSED = "processed",
  FAILED = "failed",
}

const campaignRecipientMetricsSchema = z.object({
  opens: z.number(),
  clicks: z.number(),
  upsellRevenue: z.number(),
  upsellCount: z.number(),
});

export const campaignRecipientSchema = z.object({
  _id: zExt.objectId(),
  campaignId: zExt.objectId(),
  customerId: zExt.objectId(),
  channelType: z.nativeEnum(MarketingSubscriptionType),
  channelId: z.string(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  metrics: campaignRecipientMetricsSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  status: z.nativeEnum(CampaignRecipientStatus),
});

export type CampaignRecipientMetrics = z.infer<
  typeof campaignRecipientMetricsSchema
>;

export type CampaignRecipient = z.infer<typeof campaignRecipientSchema>;

export type CampaignRecipientMetricEvent =
  | OutreachOpenEvent
  | OutreachClickEvent;

export function isCampaignRecipientMetricEvent(
  event: CustomerEvent,
): event is CampaignRecipientMetricEvent {
  return [
    CustomerEventType.OUTREACH_OPEN,
    CustomerEventType.OUTREACH_CLICK,
  ].includes(event.eventType);
}
