import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { OverflowDirection } from "@redotech/react-util/overflow";
import { useSearch } from "@redotech/react-util/search";
import { getUserAvatarUrl } from "@redotech/redo-merchant-app/common/get-avatar-url";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import {
  Permission,
  permitted,
  GetUser as User,
} from "@redotech/redo-model/user";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoList } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { Divider } from "@redotech/redo-web/divider";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import ChatBotIcon from "@redotech/redo-web/icon-old/chat-bot.svg";
import { Text } from "@redotech/redo-web/text";
import { OverflowTooltip } from "@redotech/redo-web/tooltip/overflow-tooltip";
import { UserImage, UserImageSize } from "@redotech/redo-web/user-image";
import Fuse from "fuse.js";
import { memo, useContext, useEffect, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import * as assigneeDetailsCss from "./assignee-details.module.css";

const searcher = new Fuse<User>([], {
  keys: ["name", "email"],
  threshold: 0.3,
});

export const AssigneeDetails = memo(function AssigneeDetails({
  updateConversationDetails,
  conversation,
}: {
  updateConversationDetails: ({ assignee }: { assignee: User | null }) => void;
  conversation: ExpandedConversation;
}) {
  const team = useRequiredContext(TeamContext);
  const currentUser = useContext(UserContext);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  useEffect(() => {
    setAllUsers(team.users.map((user) => user.user as User));
  }, [team.users]);
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );
  const canEditAssignee =
    !!currentUser &&
    permitted(currentUser.permissions, Permission.EDIT_ASSIGNEE);

  const isChatbot = (assignee: User | null) => {
    return assignee?._id === process.env.CHATBOT_USER_ID;
  };

  const updateAssignee = (user: User | null) => {
    updateConversationDetails({ assignee: user });
  };
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  const [searchText, setSearchText] = useState<string>("");
  const filteredUsers = useSearch(searcher, allUsers, searchText);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const assigneeInput = (
    <input
      autoFocus
      className={assigneeDetailsCss.input}
      onChange={(event) => setSearchText(event.target.value)}
      placeholder="Search for an option..."
      ref={setInputRef}
      value={searchText}
    />
  );

  const closeDropdown = () => {
    setEditDropdownOpen(false);
    setSearchText("");
  };

  const [overflowRef, setOverflowRef] = useState<HTMLElement | null>(null);

  return (
    <div className={assigneeDetailsCss.assigneeDetails}>
      <ClickAwayListener
        onClickAway={() => {
          if (editDropdownOpen) {
            closeDropdown();
          }
        }}
      >
        <Flex dir="column" gap="xs">
          <Flex align="center" justify="space-between">
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Assignee
            </Text>
          </Flex>

          <div ref={setDropdownAnchor}>
            <OverflowTooltip
              direction={OverflowDirection.Horizontal}
              overflowRef={overflowRef}
              tooltipProps={{
                placement: "top",
                title: `${conversation.assignee?.name || "none"}`,
              }}
            >
              <div
                className={assigneeDetailsCss.assigneeBadge}
                onClick={() => {
                  if (!canEditAssignee) {
                    return;
                  }
                  amplitude.logEvent("view-updateAssigneeModal", {
                    conversationId: conversation._id,
                    channel: conversation.platform,
                  });
                  setEditDropdownOpen((prev) => !prev);
                }}
              >
                <RedoBadge
                  avatar={
                    !isChatbot(conversation.assignee)
                      ? {
                          alt: "Assignee profile picture",
                          imageUrl: getUserAvatarUrl({
                            email: conversation.assignee?.email || "",
                            userId: conversation.assignee?._id,
                          }),
                          name: conversation.assignee?.name,
                        }
                      : undefined
                  }
                  color={RedoBadgeColor.GRAY}
                  segmentLeading={
                    isChatbot(conversation.assignee)
                      ? { type: "icon", Icon: ChatBotIcon }
                      : undefined
                  }
                  setRef={setOverflowRef}
                  size={RedoBadgeSize.SMALL}
                  text={conversation.assignee?.name || "none"}
                />
              </div>
            </OverflowTooltip>
            <div>
              <Dropdown
                anchor={dropdownAnchor}
                fitToAnchor={false}
                open={editDropdownOpen}
              >
                <Flex
                  className={assigneeDetailsCss.dropdownContainer}
                  dir="column"
                  gap="sm"
                >
                  {assigneeInput}
                  <Divider />
                  <RedoList
                    focusedIndex={focusedIndex}
                    isItemSelected={(item) => {
                      if (!conversation.assignee && !item.value) {
                        return true;
                      }
                      return item.value?._id === conversation.assignee?._id;
                    }}
                    items={[
                      { value: null },
                      ...filteredUsers.map((user) => ({
                        value: user,
                      })),
                    ]}
                    itemSelected={(item) => {
                      closeDropdown();
                      updateAssignee(item.value);
                    }}
                    refToListenTo={inputRef}
                    setFocusedIndex={setFocusedIndex}
                    size={RedoListItemSize.SMALL}
                  >
                    {(option) => {
                      let name = "";
                      if (option.value === null) {
                        name = "Unassigned";
                      } else if (
                        option.value._id === process.env.CHATBOT_USER_ID
                      ) {
                        name = "Chatbot";
                      } else if (option.value) {
                        name = option.value?.name;
                      }
                      return (
                        <ListItem
                          email={option.value?.email || ""}
                          name={name}
                        />
                      );
                    }}
                  </RedoList>
                </Flex>
              </Dropdown>
            </div>
          </div>
        </Flex>
      </ClickAwayListener>
    </div>
  );
});

export const ListItem = memo(function ListItem({
  name,
  email,
}: {
  name: string;
  email: string;
}) {
  const [optionOverflowRef, setOptionOverflowRef] =
    useState<HTMLElement | null>(null);

  return (
    <OverflowTooltip
      direction={OverflowDirection.Horizontal}
      overflowRef={optionOverflowRef}
      tooltipProps={{
        placement: "top",
        title: name,
      }}
    >
      <Flex className={assigneeDetailsCss.dropdownImage}>
        <UserImage
          alt="User profile picture"
          imageUrl={getUserAvatarUrl({ email })}
          name={name}
          size={UserImageSize.TINY}
        />
        <Text
          fontSize="xs"
          overflow="hidden"
          ref={setOptionOverflowRef}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {name}
        </Text>
      </Flex>
    </OverflowTooltip>
  );
});
