import { useLazyContext } from "@redotech/react-util/context";
import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { MultiSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { useEffect, useState } from "react";
import { EmailIntegrationsContext } from "../../services/support/email-integrations";
import { ConditionType } from "../return-flow/condition";
import * as selectCss from "../return-flow/select.module.css";

export const RECEIVED_AT_EMAIL: ConditionType<
  string[],
  ModelCondition.ReceivedAtEmail
> = {
  name: "Email Received At",
  description() {
    return "Which address the email arrived at";
  },
  Details({ state, setState, disabled }) {
    const [availableEmails, setAvailableEmails] = useState<string[]>([]);

    const [emailIntegrationsLoad] = useLazyContext(EmailIntegrationsContext);

    useEffect(() => {
      if (!emailIntegrationsLoad.value) {
        return;
      }
      appendEmails(
        [
          ...(emailIntegrationsLoad.value.customIntegrations || []),
          ...(emailIntegrationsLoad.value.gmailIntegrations || []),
          ...(emailIntegrationsLoad.value.outlookIntegrations || []),
        ].map((integration) => integration.email),
      );
    }, [emailIntegrationsLoad.value]);

    const appendEmails = (emails: string[]) => {
      setAvailableEmails((oldAvailableEmails: string[]) => {
        const updatedAvailableEmails = oldAvailableEmails;
        emails.forEach((email: string) => {
          if (!updatedAvailableEmails.includes(email)) {
            updatedAvailableEmails.push(email);
          }
        });
        return [...updatedAvailableEmails];
      });
    };

    return (
      <LabeledInput label="Email Address">
        <MultiSelectDropdown
          disabled={disabled}
          options={
            availableEmails.length
              ? availableEmails
              : ["No email addresses configured"]
          }
          value={state}
          valueChange={(e) => {
            if (e) {
              setState([...e]);
            }
          }}
        >
          {(email: string) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>{email}</div>
            </div>
          )}
        </MultiSelectDropdown>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model) {
    return model.emails;
  },
  toModel(state) {
    return { type: ModelCondition.RECEIVED_AT_EMAIL, emails: state };
  },
  valid(state) {
    return !state.includes("No email addresses configured");
  },
};
