import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import {
  fetchAttachmentMetadata,
  sendTyping,
  uploadFile,
} from "@redotech/redo-api-client/conversations";
import { MacroModal } from "@redotech/redo-merchant-app/support/macros/macro-modal";
import {
  clearFormattingFromMacroAutomationsText,
  formatWithInserts,
} from "@redotech/redo-merchant-app/support/macros/quill-macro-utils";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Attachment } from "@redotech/redo-model/create-conversation-body";
import {
  isAtLeastOneMacroAutomationActive,
  Macro,
} from "@redotech/redo-model/macro";
import ThreeDotsIcon from "@redotech/redo-web/arbiter-icon/dots-horizontal_filled.svg";
import { RedoClientContext } from "@redotech/redo-web/client";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill/quill-editor.module.css";
import Quill from "quill";
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UserContext } from "../../app/user";
import {
  changeDraftStatus,
  deleteMessageDraft,
  getConversation,
  sendMessageDraft,
  updateConversation,
  upsertMessageDraft,
} from "../../client/conversations";
import { REDO_API_URL } from "../../config";
import {
  canSendMetaMessage,
  closeTicket,
  doFileDrop,
  getLastCustomerDirectMessage,
  listen,
} from "../utils";
import * as messageInputCss from "./message-input.module.css";
const Delta = Quill.import("delta");
// Styles for quill editor
import * as amplitude from "@amplitude/analytics-browser";
import { JsonObject } from "@redotech/json/json";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { MacroAutomationsData } from "@redotech/redo-model/macro";
import { shouldAllowSMSActions } from "@redotech/redo-model/support/billing/text-message-billing";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import {
  EmailReplyType,
  hasAtLeastOneRecipientEmail,
} from "@redotech/redo-model/support/conversations/email-info";
import { SendType } from "@redotech/redo-model/team";
import {
  canFileBeSentViaMMS,
  MAX_TOTAL_ATTACHMENT_SIZE_BYTES,
  MMSRejectReason,
} from "@redotech/redo-model/text-messaging/mms-attachments";
import { GetUser, Permission, permitted } from "@redotech/redo-model/user";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import StickerSquareSvg from "@redotech/redo-web/arbiter-icon/sticker-square.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { QuillAttachmentCarousel } from "@redotech/redo-web/quill/quill-attachment-carousel";
import { Switch } from "@redotech/redo-web/switch";
import { unique } from "@redotech/util/array";
import { assertNever } from "@redotech/util/type";
import { AxiosError } from "axios";
import * as classNames from "classnames";
import * as capitalize from "lodash/capitalize";
import { Delta as DeltaType, EmitterSource } from "quill/core";
import "quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { stringSimilarity } from "string-similarity-js";
import { useDebounce } from "usehooks-ts";
import { AlertContext } from "../../app/alert";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { BillingTab, getBillingPageLink } from "../../routing/links";
import { MacrosContext } from "../../services/support/macros-service";
import { ActiveUsersTypingText } from "../active-users-typing-text";
import { ActiveConversationContext } from "../context/active-conversation-context";
import {
  EmailDraftState,
  InDraftingEmailState,
} from "../conversation-email-view/email-draft-state";
import {
  ConversationSendStateContext,
  SetConversationSendStateContext,
} from "../conversation-send-states";
import { ShopifyProduct } from "../create-order";
import { materializeMacroIntoPendingAutomations } from "../macros/macro-automation-utils";
import { MacroAutomationsList } from "../macros/macro-automations-list";
import {
  cancelMacroAutomations,
  performMacroAutomationsAfterSendingMessage,
} from "../macros/perform-macro-automations";
import {
  filesToAttachments,
  getInternalDraftFromConversation,
  getPublicDraftReplyingToMessage,
  getSavedRecipientsInfo,
  numRecipients,
  sameAttachments,
  sameRecipients,
} from "../message-input-utils";
import {
  ACTIVE_CONVERSATION_QUERY_PARAMETER,
  getQueryParameter,
} from "../query-parameters";
import {
  AutocompleteType,
  AutocompletionAction,
  SupportMessageAutocomplete,
} from "../support-message-autocomplete";
import { QuillOperation } from "../writing-ai-assistant-menu";
import { MessageInputEmailHeader } from "./message-input-email-header";
import { MessageInputFooter } from "./message-input-footer";
import { MessageInputHeader } from "./message-input-header";
import { MessageInputToolbarHeader } from "./message-input-toolbar-header";
const getTypingStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_API_URL}/conversations/${conversationId}/typing`;
  return fetch(url, { signal, headers: client.authorization() });
};

export const getSentTypeDisplayText = (sendType: SendType) => {
  switch (sendType) {
    case SendType.SEND:
      return "Send";
    case SendType.CLOSE:
      return "Send and close";
    case SendType.IN_PROGRESS:
      return "Send and mark in progress";
    default:
      assertNever(sendType);
      return "";
  }
};

export interface EmailDraftProps {
  draftInfo: InDraftingEmailState;
  handleSetReplyDraft: Dispatch<SetStateAction<EmailDraftState>>;
  setTriggerReinitalizeDraft: Dispatch<SetStateAction<boolean>>;
  shouldPopupRecipientsModalBecauseForwardButtonClicked: boolean;
  setShouldPopupRecipientsModalBecauseForwardButtonClicked: Dispatch<
    SetStateAction<boolean>
  >;
}

/** Guard setting visibility based on user permissions */
function trySetVisibility(
  visibility: MessageVisibility,
  user: GetUser | undefined,
  setVisibility: Dispatch<SetStateAction<MessageVisibility>>,
) {
  const replyingPermitted =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  if (replyingPermitted || visibility === MessageVisibility.INTERNAL) {
    setVisibility(visibility);
  }
}

export const MessageInput = memo(function MessageInput({
  conversation,
  setActiveConversation,
  setErrorMessage,
  setShowErrorMessage,
  typing,
  setTyping,
  rightPanelOpen = true,
  showFullCommentThread,
  setShowFullCommentThread,
  emailDraftProps = undefined,
  messageIdForKey = undefined,
  nextConversationInList,
  prevConversationInList,
  isFirstMessageAfterPlatformConversion = false,
}: {
  conversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  typing: Record<string, Date>;
  setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
  rightPanelOpen?: boolean;
  showFullCommentThread: boolean;
  setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;
  emailDraftProps?: EmailDraftProps;
  messageIdForKey?: string;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
  isFirstMessageAfterPlatformConversion?: boolean;
}) {
  const navigate = useNavigate();
  const team = useRequiredContext(TeamContext);
  const { addNewAlert } = useContext(AlertContext);
  const {
    div: conversationDetailRef,
    ticketActions,
    conversationClosing,
    setConversationClosing,
  } = useContext(ActiveConversationContext);
  const [aiHotkeyHandler, setAiHotkeyHandler] = useState<
    ((event: KeyboardEvent) => void) | null
  >(null);

  const [visibility, setVisibility] = useState<MessageVisibility>(
    MessageVisibility.PUBLIC,
  );

  const [replyPending, setReplyPending] = useState(false);
  const [replyAndClosePending, setReplyAndClosePending] = useState(false);
  const [sendTypingCooldown, setSendTypingCooldown] = useState(false);
  const apiClient = useRequiredContext(RedoClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useContext(UserContext);
  const [typingRetryCount, setTypingRetryCount] = useState(0);
  const [macroModalOpen, setMacroModalOpen] = useState(false);
  const [draftAttachments, setDraftAttachments] = useState<Attachment[]>([]);
  const [discountCodesInMessage, setDiscountCodesInMessage] = useState<
    string[]
  >([]);
  const [productsInMessage, setProductsInMessage] = useState<ShopifyProduct[]>(
    [],
  );

  const [macrosLoad, refreshMacros] = useLazyContext(MacrosContext);

  const conversationSendStateContext = useRequiredContext(
    ConversationSendStateContext,
  );
  const setConversationSendStateContext = useRequiredContext(
    SetConversationSendStateContext,
  );

  const shouldBlockDraftCreationWhileSendingEmail =
    conversationSendStateContext.has(conversation._id);

  const setShouldBlockDraftCreationWhileSendingEmail = (sending: boolean) => {
    setConversationSendStateContext(conversation._id, sending);
  };

  const canSendAsMetaMessage = canSendMetaMessage(conversation);

  const metaSendType = useMemo(() => {
    if (
      [ConversationPlatform.FACEBOOK, ConversationPlatform.INSTAGRAM].includes(
        conversation.platform,
      )
    ) {
      if (canSendAsMetaMessage) {
        return "message";
      } else {
        return "forbidden";
      }
    } else {
      return "none";
    }
  }, [conversation, canSendAsMetaMessage]);

  const [formatWithInsertsTrigger, setFormatWithInsertsTrigger] =
    useState(Symbol());

  const [deleteLoading, setDeleteLoading] = useState(false);

  const disabledBecauseOfSMSOptOut =
    conversation.platform === ConversationPlatform.SMS &&
    !!conversation.customer?.supportCommunicationConsent?.textMessages
      .optOutDate;

  const disabledBecauseOfNoOptIn =
    conversation.platform === ConversationPlatform.SMS &&
    !conversation.customer?.supportCommunicationConsent?.textMessages
      .optInDate &&
    !conversation.messages.some((m) => m.type === "call");

  const disabledBecauseOfSMSBilling =
    conversation.platform === ConversationPlatform.SMS &&
    !shouldAllowSMSActions(team);

  const attachmentDataLoad: LoadState<MMSRejectReason | undefined> =
    useLoad(async () => {
      if (conversation.platform !== ConversationPlatform.SMS) {
        return undefined;
      }

      let totalAttachmentSize: number = 0;

      for (const attachment of draftAttachments) {
        const { contentType, contentLength } = await fetchAttachmentMetadata(
          apiClient,
          attachment,
        );

        if (!canFileBeSentViaMMS(contentType)) {
          return MMSRejectReason.INVALID_TYPE;
        }

        totalAttachmentSize += contentLength;
      }

      if (totalAttachmentSize > MAX_TOTAL_ATTACHMENT_SIZE_BYTES) {
        return MMSRejectReason.SIZE_EXCEEDED;
      }

      return undefined;
    }, [draftAttachments]);

  const sendMessageDisabled: boolean = useMemo(() => {
    const actionsPending =
      replyPending ||
      replyAndClosePending ||
      conversationClosing ||
      shouldBlockDraftCreationWhileSendingEmail ||
      deleteLoading;

    if (actionsPending) {
      return true;
    }

    if (visibility === MessageVisibility.PUBLIC) {
      if (disabledBecauseOfSMSOptOut) {
        return true;
      }

      if (disabledBecauseOfNoOptIn) {
        return true;
      }

      if (disabledBecauseOfSMSBilling) {
        return true;
      }

      if (attachmentDataLoad.value || attachmentDataLoad.pending) {
        return true;
      }

      if (metaSendType === "forbidden") {
        return true;
      }

      const disabledBecauseOfNoEmailRecipients =
        conversation.platform === ConversationPlatform.EMAIL &&
        !hasAtLeastOneRecipientEmail(
          emailDraftProps?.draftInfo?.draft?.recipientsInfo,
        );

      if (disabledBecauseOfNoEmailRecipients) {
        return true;
      }
    }

    return false;
  }, [
    conversation,
    metaSendType,
    replyPending,
    replyAndClosePending,
    conversationClosing,
    shouldBlockDraftCreationWhileSendingEmail,
    deleteLoading,
    emailDraftProps,
    visibility,
    attachmentDataLoad,
    disabledBecauseOfSMSBilling,
    disabledBecauseOfSMSOptOut,
    disabledBecauseOfNoOptIn,
  ]);

  const setEmojiPickerOpenRef = useRef<Dispatch<SetStateAction<boolean>>>();
  const [quill, setQuill] = useState<Quill | null>(null);

  const cursorIndexRef = useRef<number | undefined>(undefined);
  const [showSignature, setShowSignature] = useState(false);

  const signatureMarker = "\u200B";
  const [htmlToPasteSignature, setHtmlToPasteSignature] = useState<
    string | undefined
  >(undefined);
  const signatureExists = () => {
    if (!quill) return false;
    const text = quill.getText();
    const html = quill.root.innerHTML;
    return (
      text.includes(signatureMarker) ||
      (htmlToPasteSignature && html.includes(htmlToPasteSignature))
    );
  };
  const [macroAutomations, setMacroAutomations] =
    useState<MacroAutomationsData>({});

  const [macroToInsert, setMacroToInsert] = useState<Macro | undefined>();

  const lastMerchantMessage = conversation.messages
    .filter((message) => message.type === "merchant")
    .reverse()[0];
  const privateReplyLimitation =
    metaSendType === "forbidden" &&
    !!lastMerchantMessage?.instagram?.privateRepliedComment;
  const lastCustomerDirectMessage = getLastCustomerDirectMessage(conversation);

  const [visibilitySetByDraftOnce, setVisibilitySetByDraftOnce] =
    useState(false);

  const [
    hasLoadedDraftOrAiResponseIntoUi,
    setHasLoadedDraftOrAiResponseIntoUi,
  ] = useState(false);

  const shouldUseEmailConversationLayout = !!emailDraftProps;

  const shouldShowInternalNoteButton =
    conversation.platform === ConversationPlatform.EMAIL &&
    !shouldUseEmailConversationLayout &&
    visibility !== MessageVisibility.INTERNAL;

  // If we have a draft, we want to show that
  // This hook checks if we have internal or public drafts, and sets the visibility accordingly, giving priority to public drafts to be shown over internal drafts
  useEffect(
    function setupVisibilityOnConversationChange() {
      const hasInternalDraft = !!getInternalDraftFromConversation(conversation);
      const hasPublicDraft = !!getPublicDraftReplyingToMessage(
        conversation,
        messageReplyingTo,
        isFirstMessageAfterPlatformConversion,
      );

      let nextVisibility: MessageVisibility = visibility;

      /*
      Only set the visibility when the parent component is conversation-content.tsx (emailDraftProps is undefined when the parent is conversation-content.tsx)
      This is because we don't want to affect all of the email mesage drafts, but still want to affect the internal note draft on an email conversation
      Only do this once per page load
      */
      if (
        !emailDraftProps &&
        hasInternalDraft &&
        !hasPublicDraft &&
        !deleteLoading &&
        !visibilitySetByDraftOnce
      ) {
        setVisibilitySetByDraftOnce(true);
        nextVisibility = MessageVisibility.INTERNAL;
      } else if (shouldShowInternalNoteButton) {
        /** If there's no draft, hide internal note button if we switched email tickets */
        nextVisibility = MessageVisibility.PUBLIC;
      }

      if (nextVisibility !== visibility) {
        trySetVisibility(nextVisibility, user, setVisibility);
      }
    },
    [conversation],
  );

  const messageReplyingTo = useMemo(() => {
    return (
      conversation.messages.find(
        (message) => message._id === messageIdForKey,
      ) || conversation.messages.at(-1)! // Conversations must have at least one message
    );
  }, [conversation.messages, messageIdForKey]);

  useEffect(() => {
    const replyingPermitted =
      !!user && permitted(user.permissions, Permission.CREATE_REPLY);
    if (!replyingPermitted) {
      setVisibility(MessageVisibility.INTERNAL);
    }
  }, [user]);

  useEffect(() => {
    const signatureToUse =
      user?.emailSignature && user?.usePersonalSignature
        ? user.emailSignature
        : team.settings.support?.emailSignature;
    if (
      conversation.platform === ConversationPlatform.EMAIL &&
      signatureToUse &&
      visibility === MessageVisibility.PUBLIC
    ) {
      // We use unicode character \u200B (zero-width space) to mark the start of the signature.
      setHtmlToPasteSignature(`<br><br><p>&#x200B;</p>${signatureToUse}`);
    } else {
      setHtmlToPasteSignature(undefined);
    }
  }, [team, conversation.platform, visibility]);

  useEffect(() => {
    // If it was just set to true, send that we're typing
    if (user && sendTypingCooldown) {
      setTimeout(() => {
        setSendTypingCooldown(false);
      }, 2000);
      void sendTyping(apiClient, {
        conversationId: conversation._id,
        id: user._id,
        visibility,
      });
    }
  }, [sendTypingCooldown]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const typingEvent of listen({
          query: async () => {
            return await getTypingStream(apiClient, {
              conversationId: conversation._id,
              signal: abortController.signal,
            });
          },
          loopCondition: !!conversation?._id,
          setErrorMessage,
          setShowErrorMessage,
        })) {
          const typingEventJson = typingEvent as unknown as JsonObject;
          if (typingEventJson?.name) {
            const name: string = typingEventJson.name as string;
            const expire = new Date();
            expire.setSeconds(expire.getSeconds() + 4);
            setTyping((oldTyping) => {
              setTimeout(() => {
                clearTyping();
              }, 4000);
              return {
                ...oldTyping,
                [name]: expire,
              };
            });
          }
        }
      } catch (e) {
        // Wait 5 seconds before trying again
        setTimeout(() => {
          // Continue polling for changes
          setTypingRetryCount(typingRetryCount + 1);
        }, 5000);
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () => abortController.abort();
  }, [conversation._id, typingRetryCount]);

  useEffect(() => {
    setHasLoadedDraftOrAiResponseIntoUi(false);
  }, [conversation._id]);

  useEffect(() => {
    if (hasLoadedDraftOrAiResponseIntoUi) {
      return;
    }
    void tryLoadDraftOrAiResponseIntoUI();
  }, [conversation._id, quill, hasLoadedDraftOrAiResponseIntoUi]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    setShowSignature(false);
    const textChangeCallback = (_: any, __: any, source: string) => {
      // We only want to trigger the text state update if the text change is from the user
      // Otherwise, the text change is from ai generation, and we don't want to save that as the message draft
      if (source === Quill.sources.USER) {
        requestSaveDraft();
      }
    };
    quill.on(Quill.events.TEXT_CHANGE, textChangeCallback);
    return () => {
      quill.off(Quill.events.TEXT_CHANGE, textChangeCallback);
    };
  }, [conversation._id, quill]);

  useEffect(function setupVisibilityFromUrl() {
    // If visibility was specified in the url, set it accordingly.
    const searchParams = new URLSearchParams(location.search);
    const visibilityParam = searchParams.get("visibility");
    if (["public", "internal"].includes(visibilityParam || "")) {
      setVisibility(visibilityParam as MessageVisibility);
      searchParams.delete("visibility");
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, []);

  useEffect(() => {
    if (!quill) {
      return;
    }
    formatWithInserts({
      quill,
    });
  }, [visibility]);

  useEffect(() => {
    if (
      metaSendType === "forbidden" &&
      visibility === MessageVisibility.PUBLIC
    ) {
      conversationDetailRef?.current?.focus();
    } else {
      quill?.focus();
    }
  }, [quill, metaSendType]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    const quillPosition = quill.getSelection()?.index;
    if (visibility === MessageVisibility.INTERNAL) {
      const signatureIndex = quill.getText().indexOf(signatureMarker);
      if (signatureIndex !== -1 && htmlToPasteSignature) {
        let text = quill.getText();
        text = quill.getText().substring(0, signatureIndex) + "\n";
        // Remove up to two newlines from the end of the text, leaving at least one.
        let i = 2;
        while (text.endsWith("\n\n") && i > 0) {
          text = text.substring(0, text.length - 1);
          i--;
        }
        quill.setText(text, Quill.sources.SILENT);
      }
    }
    if (quillPosition !== undefined) {
      quill.setSelection(quillPosition, 0);
    }
  }, [team, visibility, htmlToPasteSignature]);

  useEffect(() => {
    if (!quill) {
      return;
    }
    if (showSignature && !signatureExists() && htmlToPasteSignature) {
      quill.clipboard.dangerouslyPasteHTML(
        quill.getLength() - 1,
        htmlToPasteSignature,
      );
    } else if (!showSignature && signatureExists()) {
      let text = quill.getText();
      const signatureIndex = quill.getText().indexOf(signatureMarker);
      text = quill.getText().substring(0, signatureIndex) + "\n";
      // Remove up to two newlines from the end of the text, leaving at least one.
      let i = 2;
      while (text.endsWith("\n\n") && i > 0) {
        text = text.substring(0, text.length - 1);
        i--;
      }
      quill.setText(text, Quill.sources.SILENT);
    }
  }, [showSignature]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    const formatInsertsCallback = (
      delta: DeltaType,
      oldDelta: DeltaType,
      source: EmitterSource,
    ) => {
      if (!delta.ops.some((op) => !!op.insert || !!op.delete)) {
        return;
      }

      formatWithInserts({
        quill,
      });
    };

    quill.on(Quill.events.TEXT_CHANGE, formatInsertsCallback);

    return () => {
      quill.off(Quill.events.TEXT_CHANGE, formatInsertsCallback);
    };
  }, [quill, visibility, productsInMessage, discountCodesInMessage]);

  useEffect(() => {
    if (!quill) {
      return;
    }
    formatWithInserts({
      quill,
    });
  }, [formatWithInsertsTrigger]);

  /**
   * Autocomplete management that doesn't live in SupportMessageAutocomplete
   *
   * TODO make as much of this as possible live in SupportMessageAutocomplete.
   */
  const [triggerOpenAutocompleteMenu, setTriggerOpenAutocompleteMenu] =
    useState<AutocompleteType | undefined>(undefined);
  const [autocompleteVisible, setAutocompleteVisible] = useState(false);

  const [autocompleteActionToPerform, setAutocompleteActionToPerform] =
    useState<AutocompletionAction | null>(null);

  const [usersMentionedInMessage, setUsersMentionedInMessage] = useState<
    { name: string; id: string }[]
  >([]);

  useEffect(() => {
    if (macroToInsert) {
      const doSetMacro = async () => {
        await setMacro(macroToInsert);
        setMacroToInsert(undefined);
      };
      doSetMacro().catch((e) => {
        setErrorMessage("Failed to insert template.");
        setShowErrorMessage(true);
      });
    }
  }, [macroToInsert]);

  /**
   * Different from @see {maybePerformCharacterHotkeyAction} because
   * the maybePerformCharacterHotkeyAction actually pastes a character into the editor in addition to performing
   * the side effect. This function only performs the side effect.
   */
  function maybePerformHotkeyAction(event: KeyboardEvent) {
    if (aiHotkeyHandler) {
      aiHotkeyHandler(event);
    }
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    if (event.key === "2") {
      setTriggerOpenAutocompleteMenu(AutocompleteType.MENTION);
      event.preventDefault();
    }
    if (event.key === "5") {
      setTriggerOpenAutocompleteMenu(AutocompleteType.DISCOUNT_CODE);
      event.preventDefault();
    }
    if (event.key === "6") {
      setMacroModalOpen(true);
      event.preventDefault();
    }
    if (event.key === "7") {
      setTriggerOpenAutocompleteMenu(AutocompleteType.PRODUCT);
      event.preventDefault();
    }
  }

  const handleHotkeyFunctionReady = useCallback(
    (fn: (event: KeyboardEvent) => void) => {
      setAiHotkeyHandler(() => fn);
    },
    [],
  );

  const clearTyping = () => {
    const now = new Date();
    setTyping((oldTyping) => {
      if (oldTyping) {
        return Object.keys(oldTyping).reduce((accumulator, key) => {
          if (oldTyping[key] > now) {
            return {
              ...accumulator,
              [key]: oldTyping[key],
            };
          } else {
            return accumulator;
          }
        }, {});
      } else {
        return {};
      }
    });
  };

  const closeEmailDraft = () => {
    if (emailDraftProps) {
      emailDraftProps.handleSetReplyDraft({ status: "noDraft" });
    }
  };

  const savedDraft = useMemo(() => {
    if (visibility === MessageVisibility.INTERNAL) {
      return getInternalDraftFromConversation(conversation);
    } else {
      return getPublicDraftReplyingToMessage(
        conversation,
        messageReplyingTo,
        isFirstMessageAfterPlatformConversion,
      );
    }
  }, [conversation, messageReplyingTo, visibility]);

  // Toggling the visibility only applies to non email conversations
  // We want to update the draft when we toggle the visibility
  useEffect(() => {
    if (conversation.platform !== ConversationPlatform.EMAIL) {
      void tryLoadDraftOrAiResponseIntoUI();
    }
  }, [visibility]);

  const setActiveConversationIfStillOnSameConversation = (
    nextConversation: ExpandedConversation,
    preventUpdateActiveConversation?: boolean,
  ) => {
    const locationActiveConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );

    if (
      !preventUpdateActiveConversation &&
      locationActiveConversationId === conversation._id
    ) {
      setActiveConversation(nextConversation);
    }
  };

  function closeInternalNoteIfStillOnSameConversation(
    nextConversation: ExpandedConversation,
  ) {
    if (
      visibility === MessageVisibility.INTERNAL &&
      conversation._id === nextConversation._id
    ) {
      trySetVisibility(MessageVisibility.PUBLIC, user, setVisibility);
    }
  }

  // Given an updated redo message, replace the current message in the conversation or add the new one
  const addOrReplaceMessage = (
    updatedMessage: ExpandedConversationMessage,
    updatedAt: string,
  ) => {
    const newMessages = conversation.messages;
    const currentDraftMessage = conversation.messages.find(
      (m) => m._id === updatedMessage._id,
    );
    if (currentDraftMessage) {
      newMessages.splice(
        newMessages.indexOf(currentDraftMessage),
        1,
        updatedMessage,
      );
    } else {
      newMessages.push(updatedMessage);
    }
    setActiveConversationIfStillOnSameConversation({
      ...conversation,
      messages: newMessages,
      updatedAt,
    });
  };

  const tryLoadDraftOrAiResponseIntoUI = async () => {
    const attachments: Attachment[] = filesToAttachments(savedDraft);

    if (!quill) {
      return;
    }

    if (conversation.platform === ConversationPlatform.EMAIL) {
      const draftHtmlBody =
        savedDraft?.draftInfo?.emailDraftInfo?.draftHtmlBody || "";
      quill?.clipboard.dangerouslyPasteHTML(0, draftHtmlBody);
    } else {
      quill?.setText(savedDraft?.content || "", Quill.sources.SILENT);
    }

    addAttachments(attachments);
    setHasLoadedDraftOrAiResponseIntoUi(true);

    // if the quill is empty at this point, there was no draft. Let's try
    // to add in the AI generation.
    // (empty quill has a newline character)
    if (quill.getText().length > 1) {
      return;
    }

    if (conversation.currentAiResponse) {
      quill.setText(conversation.currentAiResponse, Quill.sources.API);
    } else if (
      messageReplyingTo.aiGenerations &&
      messageReplyingTo.aiGenerations.length > 0
    ) {
      quill.setText(
        messageReplyingTo.aiGenerations.at(-1)!.text,
        Quill.sources.API,
      );
    } else {
      quill.setText("", Quill.sources.SILENT);
    }
  };

  // We want to keep track of this so that when we want to perform another operation on the draft, we wait for the previous operation to complete
  // Not a bulletproof strategy if multiple people are editing the draft at once, but it's a good solution for now
  const saveDraftPromise = useRef<
    Promise<ExpandedConversationMessage | null> | undefined
  >(undefined);

  // This is slightly different than the normal saveDraft function
  // Before sending, we want to replace variables with formatted HTML and add the signature
  const handleSaveDraftRightBeforeSending = async (): Promise<{
    updatedMessage: ExpandedConversationMessage;
    conversationUpdatedAt: string;
  } | null> => {
    // These checks are a bit redundant given the checks in handleMessageSend, but it's better to be safe
    // Note that we leave out the check for shouldBlockDraftCreationWhileSendingEmail because that is set in handleMessageSend
    if (
      !quill ||
      (visibility === MessageVisibility.PUBLIC &&
        metaSendType === "forbidden") ||
      replyPending ||
      replyAndClosePending ||
      conversationClosing
    ) {
      return null;
    }

    let text = quill.getText();
    let html = quill.getSemanticHTML();

    // Get rid of trailing newline
    text = text.endsWith("\n") ? text.slice(0, -1) : text;

    // Only include the signature when saving the draft immediately before sending
    if (htmlToPasteSignature && !signatureExists() && !showSignature) {
      html += htmlToPasteSignature;
    }

    try {
      const { draftMessage } = await upsertMessageDraft(client, {
        conversationId: conversation._id,
        message: text,
        usersMentioned: usersMentionedInMessage.map((u) => u.id),
        htmlBody: html,
        visibility,
        attachments: draftAttachments,
        emailEnvelopeInfo: emailDraftProps?.draftInfo.draft.recipientsInfo,
        replyType: emailDraftProps?.draftInfo?.mode || EmailReplyType.REPLY,
      });

      // Tell the server that this message is no longer a draft
      const { updatedMessage, conversationUpdatedAt } = await changeDraftStatus(
        client,
        {
          conversationId: conversation._id,
          messageId: draftMessage._id,
          setAsDraft: false,
        },
      );

      return { updatedMessage, conversationUpdatedAt };
    } catch (e) {
      console.error(e);
      setErrorMessage("Failed to save message draft");
      setShowErrorMessage(true);
      return null;
    }
  };

  const [saveDraftLoading, setSaveDraftLoading] = useState(false);

  const handleSaveDraftDuringNormalEditing =
    async (): Promise<ExpandedConversationMessage | null> => {
      if (
        !quill ||
        (visibility === MessageVisibility.PUBLIC &&
          metaSendType === "forbidden") ||
        replyPending ||
        replyAndClosePending ||
        conversationClosing ||
        shouldBlockDraftCreationWhileSendingEmail ||
        deleteLoading
      ) {
        return null;
      }

      setSaveDraftLoading(true);

      const text = quill.getText();

      const textWithoutTrailingNewline = text.endsWith("\n")
        ? text.slice(0, -1)
        : text;

      const html = quill.getSemanticHTML();

      try {
        const { draftMessage, conversationUpdatedAt } =
          await upsertMessageDraft(client, {
            conversationId: conversation._id,
            message: textWithoutTrailingNewline,
            usersMentioned: usersMentionedInMessage.map((u) => u.id),
            htmlBody: html,
            visibility,
            attachments: draftAttachments,
            emailEnvelopeInfo: emailDraftProps?.draftInfo.draft.recipientsInfo,
            replyType: emailDraftProps?.draftInfo?.mode || EmailReplyType.REPLY,
          });

        // Optimistically update the draft message in the UI
        addOrReplaceMessage(draftMessage, conversationUpdatedAt);

        setSaveDraftLoading(false);
        return draftMessage;
      } catch (e) {
        console.error(e);
        setErrorMessage("Failed to save message draft");
        setShowErrorMessage(true);
        setSaveDraftLoading(false);
        return null;
      }
    };

  const handleMessageSend: (
    event: React.ChangeEvent<{ value?: unknown }>,
    alertTitle?: string,
    preventUpdateActiveConversation?: boolean,
    markInProgress?: boolean,
  ) => Promise<boolean> = useHandler(
    async (
      e,
      alertMessage = "Message sent",
      preventUpdateActiveConversation = false,
      markInProgress = false,
    ) => {
      if (
        !quill ||
        (visibility === MessageVisibility.PUBLIC &&
          metaSendType === "forbidden") ||
        replyPending ||
        replyAndClosePending ||
        conversationClosing ||
        shouldBlockDraftCreationWhileSendingEmail
      ) {
        return false;
      }

      // Define what we should do no matter what when this function finishes
      // Some paths will do more (like close the draft, update the active conversation, etc.)
      // But all paths should do this
      const cleanupSaveDraft = () => {
        setReplyPending(false);
        setShouldBlockDraftCreationWhileSendingEmail(false);
        setShowSignature(false);
        quill.enable();
      };

      // Set the state needed for sending a message
      // Don't allow users to create new drafts while this is in progress
      setShouldBlockDraftCreationWhileSendingEmail(true);
      setReplyPending(true);
      quill.disable();

      const originalHtml = quill.getSemanticHTML();
      const text = quill.getText();

      e.preventDefault();
      try {
        if (markInProgress) {
          await updateConversation(client, conversation, {
            status: "in_progress",
          });
        }
        // If we are currently saving the draft, wait for it to complete before proceeding
        // Keep a reference to the old draft message in case they cancel the send
        let oldDraftMessage = await saveDraftPromise.current;

        if (!oldDraftMessage) {
          oldDraftMessage = savedDraft;
        }

        // Save the draft one last time - unlike normal draft saves,replace variables and add the signature
        const saveDraftResult = await handleSaveDraftRightBeforeSending();

        closeEmailDraft();

        if (!saveDraftResult) {
          cleanupSaveDraft();
          return false;
        }

        // Optimistically update the message so that it's no longer a draft
        const { updatedMessage, conversationUpdatedAt } = saveDraftResult;
        addOrReplaceMessage(updatedMessage, conversationUpdatedAt);

        let noteMessage: ExpandedConversationMessage | undefined;

        let forwardMessageMacroAutomationDraft:
          | ExpandedConversationMessage
          | undefined;

        // Optimistically update the macro automation draft so that it's no longer a draft

        const handleSendCallback = async () => {
          try {
            const abortController = new AbortController();

            const conversationAfterSending = await sendMessageDraft(client, {
              conversationId: conversation._id,
              redoDraftMessageId: updatedMessage._id,
              signal: abortController.signal,
            });
            const { conversationAfterMacros, noteMessage: updatedNoteMessage } =
              await performMacroAutomationsAfterSendingMessage({
                client,
                automations: macroAutomations,
                conversation,
                team,
                addOrReplaceMessage,
                messageToForwardThatsAlreadySent:
                  conversationAfterSending.messages.filter(
                    (message) => message._id === updatedMessage._id,
                  )[0],
                forwardDraftSentCallback: (draftMessage) => {
                  forwardMessageMacroAutomationDraft = draftMessage;
                },
              });
            noteMessage = updatedNoteMessage;
            if (conversationAfterMacros) {
              setMacroAutomations({});

              closeInternalNoteIfStillOnSameConversation(
                conversationAfterMacros,
              );

              setActiveConversationIfStillOnSameConversation(
                conversationAfterMacros,
                preventUpdateActiveConversation,
              );
            }

            if (evaluateResponseSimilarityToAiResponse(text)) {
              amplitude.logEvent("sent-ai-response", {
                conversationId: conversation._id,
                channel: conversation.platform,
                visibility,
              });
            }

            amplitude.logEvent("create-conversationMessage", {
              conversationId: conversation._id,
              channel: conversation.platform,
              visibility,
            });

            // Reset the message input
            setDraftAttachments([]);
            quill.setText("", Quill.sources.SILENT);

            closeInternalNoteIfStillOnSameConversation(
              conversationAfterSending,
            );

            setActiveConversationIfStillOnSameConversation(
              conversationAfterSending,
              preventUpdateActiveConversation,
            );
          } catch (e) {
            setErrorMessage("Failed to send message");
            setShowErrorMessage(true);
          } finally {
            cleanupSaveDraft();
          }
        };

        const shouldDelay =
          visibility === MessageVisibility.PUBLIC &&
          conversation.platform === ConversationPlatform.EMAIL;

        if (shouldDelay) {
          addNewAlert({
            title: alertMessage,
            type: "info",
            primaryButtonText: "Undo",
            primaryButtonAction: async () => {
              try {
                // Revert the draft message to the old one
                if (oldDraftMessage) {
                  await upsertMessageDraft(client, {
                    conversationId: conversation._id,
                    message: oldDraftMessage.content,
                    usersMentioned: usersMentionedInMessage.map((u) => u.id),
                    htmlBody:
                      oldDraftMessage.draftInfo?.emailDraftInfo
                        ?.draftHtmlBody || "",
                    visibility: oldDraftMessage.visibility,
                    attachments: draftAttachments,
                    emailEnvelopeInfo:
                      oldDraftMessage.draftInfo?.emailDraftInfo,
                    replyType:
                      oldDraftMessage.draftInfo?.emailDraftInfo?.replyType,
                  });
                }

                await cancelMacroAutomations({
                  client,
                  automations: macroAutomations,
                  conversation,
                  addInternalNoteMacroAutomationDraft: noteMessage,
                  forwardMessageMacroAutomationDraft,
                });

                await changeDraftStatus(client, {
                  conversationId: conversation._id,
                  messageId: updatedMessage._id,
                  setAsDraft: true,
                });

                const newConversation = await getConversation(client, {
                  conversationId: conversation._id,
                });

                emailDraftProps?.setTriggerReinitalizeDraft(true);

                setActiveConversationIfStillOnSameConversation(
                  newConversation,
                  preventUpdateActiveConversation,
                );
              } catch (e) {
                console.error(e);
              } finally {
                cleanupSaveDraft();
              }
            },
            primaryButtonCancelsAction: true,
            canceledDescription: "Sending undone",
            secondsToLive: 5,
            actionCallback: handleSendCallback,
          });
        } else {
          await handleSendCallback();
        }
      } catch (e) {
        console.error(e);

        if (
          e instanceof AxiosError &&
          e.response?.status === 403 &&
          conversation.platform === ConversationPlatform.POSTSCRIPT
        ) {
          setErrorMessage(
            "Failed to send message. Customer is no longer a Postscript subscriber",
          );
        } else {
          setErrorMessage("Failed to send message");
        }
        setShowErrorMessage(true);
        setReplyPending(false);

        quill.clipboard.dangerouslyPasteHTML(originalHtml);
        quill.enable();
        return false;
      }
      return true;
    },
  );

  const handleSendAndClose: (
    event: React.ChangeEvent<{ value?: unknown }>,
  ) => Promise<void> = useHandler(async (e) => {
    setConversationClosing(true);
    setReplyAndClosePending(true);
    if (await handleMessageSend(e, "Message sent and ticket closed", true)) {
      // Close the conversation directly
      await closeTicket(
        client,
        conversation,
        setActiveConversation,
        team,
        true,
        nextConversationInList || prevConversationInList,
      );
      amplitude.logEvent("reply-and-close-conversation", {
        mode: "single",
        conversationIds: [conversation._id],
        channels: [conversation.platform],
      });

      closeEmailDraft();
    }
    setReplyAndClosePending(false);
    setConversationClosing(false);
  });

  function addAttachments(attachments: Attachment[]) {
    setDraftAttachments((oldDraftAttachments) => {
      const uniqueAttachments = unique(
        [...attachments, ...oldDraftAttachments],
        (item) => item.url,
      );
      return uniqueAttachments;
    });
  }

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: ChangeEvent<HTMLInputElement>;
    file?: File;
  }) => {
    const fileToUpload = file || event?.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      amplitude.logEvent("create-attachment", {
        channel: conversation.platform,
        file: fileToUpload.name,
      });
      const newAttachment = { ...response.body };
      addAttachments([newAttachment]);
    } else {
      toast(conversationFileUploadErrorMessages[response.error], {
        variant: "error",
      });
    }
  };

  const removeFileFromDrafts = (url: string) => {
    setDraftAttachments((oldDraftAttachments) => {
      return oldDraftAttachments.filter((attachment) => attachment.url !== url);
    });
  };

  const conversationCustomerEmail = getPrimaryCustomerEmail(
    conversation.customer,
  );

  async function setMacro(macro: Macro) {
    const [contentWithVariablesReplaced, htmlContentWithVariablesReplaced] =
      await materializeMacroIntoPendingAutomations({
        client,
        team,
        existingPendingAutomations: macroAutomations,
        newMacroToInclude: macro,
        email: conversationCustomerEmail || "",
        firstName: conversation.customer.firstName || "",
        lastName: conversation.customer.lastName || "",
        fullName: conversation.customer.name || "",
        agentFirstName: user?.firstName || "",
        agentLastName: user?.lastName || "",
        agentFullName: user?.name || "",
        addAttachments,
        setErrorMessage,
        setShowErrorMessage,
        setMacroAutomations,
        conversationPlatform: conversation.platform,
        visibilityOfMessage: visibility,
      });

    if (quill) {
      const indexToInsert = cursorIndexRef.current ?? 0;
      if (
        conversation.platform === ConversationPlatform.EMAIL &&
        htmlContentWithVariablesReplaced
      ) {
        quill.clipboard.dangerouslyPasteHTML(
          indexToInsert,
          htmlContentWithVariablesReplaced,
        );
      } else if (contentWithVariablesReplaced) {
        quill.insertText(indexToInsert, contentWithVariablesReplaced);
      }

      /* Macro variables have a unique purple-on-purple color scheme. This resets the color and background color of the macro variables to the default. */
      clearFormattingFromMacroAutomationsText(quill);
    }
  }

  const handleTextChange = () => {
    // If a mention was removed don't keep it in the list of users mentioned
    const text = quill?.getText();
    for (const user of usersMentionedInMessage) {
      if (!text?.includes(`@${user.name}`)) {
        setUsersMentionedInMessage(
          usersMentionedInMessage.filter((u) => u.id !== user.id),
        );
      }
    }
  };

  const [customerRequestedAiResponse, setCustomerRequestedAiResponse] =
    useState<string>("");

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (conversationClosing) return;
    if (
      !sendMessageDisabled &&
      e.shiftKey &&
      e.key === "Enter" &&
      e.altKey &&
      team.settings.support?.useInProgressStatus
    ) {
      // Shift+Alt+Enter to send and put in progress
      void handleMessageSend(e, "Message sent", false, true);
      e.preventDefault();
    } else if (
      (e.metaKey || e.ctrlKey) &&
      e.key === "Enter" &&
      !sendMessageDisabled
    ) {
      if (e.altKey) {
        // Ctrl+Alt+Enter to reply and snooze
        ticketActions?.doSnoozeTicketAction();
        void handleMessageSend(e);
        e.preventDefault();
      } else if (e.shiftKey) {
        // Ctrl+Shift+Enter to reply and close
        void handleSendAndClose(e);
        e.preventDefault();
      } else {
        // Ctrl+Enter to reply
        e.preventDefault();
        void handleMessageSend(e);
      }
    } else if (!sendTypingCooldown) {
      setSendTypingCooldown(true);
    }
  };

  const evaluateResponseSimilarityToAiResponse = (currentText: string) => {
    // Checks if the current response is similar to our ai generated response, if it is return true indicating they are using an ai generated response, otherwise false
    if (!quill) {
      return false;
    }
    if (
      customerRequestedAiResponse ||
      conversation.currentAiResponse ||
      messageReplyingTo.aiGenerations
    ) {
      const scores = [];
      if (conversation.currentAiResponse) {
        scores.push(
          stringSimilarity(currentText, conversation.currentAiResponse),
        );
      }
      if (customerRequestedAiResponse) {
        scores.push(stringSimilarity(currentText, customerRequestedAiResponse));
      }
      if (messageReplyingTo.aiGenerations) {
        scores.push(
          ...messageReplyingTo.aiGenerations.map((generation) =>
            stringSimilarity(currentText, generation.text),
          ),
        );
      }
      if (scores.some((score) => score > 0.8)) {
        return true;
      }
    }
    return false;
  };

  const requestSaveDraft = () => {
    setSaveDraftDebounce(Symbol());
  };

  // A lot of things can request a draft to save, so we debounce it and only save if it's been more than 500ms since the last request
  const [saveDraftDebounce, setSaveDraftDebounce] = useState(Symbol());
  const debouncedSaveDraft = useDebounce(saveDraftDebounce, 500);

  // Make sure to wait for the previous save to complete before starting a new save
  useEffect(() => {
    if (saveDraftPromise.current) {
      saveDraftPromise.current
        .then(() => {
          saveDraftPromise.current = handleSaveDraftDuringNormalEditing();
        })
        .catch((e) => {
          console.error("Error saving draft", e);
        });
    } else {
      saveDraftPromise.current = handleSaveDraftDuringNormalEditing();
    }
  }, [debouncedSaveDraft]);

  // We don't want to save the draft the first time we load the attachments from the backend

  useEffect(() => {
    const savedUrls = savedDraft?.files?.map((file) => file.url) || [];
    const draftUrls = draftAttachments?.map((file) => file.url) || [];
    if (
      hasLoadedDraftOrAiResponseIntoUi &&
      !sameAttachments(savedUrls, draftUrls)
    ) {
      requestSaveDraft();
    }
  }, [draftAttachments]);

  useEffect(() => {
    // Don't save the draft just because the reply type changed
    // We only create the draft when the attachments or text changes
    // So make sure we have a draft saved already
    if (!savedDraft) {
      return;
    }
    const mode = emailDraftProps?.draftInfo?.mode;
    if (!!mode && mode !== savedDraft?.draftInfo?.emailDraftInfo?.replyType) {
      requestSaveDraft();
    }
  }, [emailDraftProps?.draftInfo?.mode]);

  /** If we switched to/from forwarding, we need to add/remove the attachments from the original message. */
  useEffect(() => {
    if (!hasLoadedDraftOrAiResponseIntoUi) {
      return;
    }
    if (
      emailDraftProps?.draftInfo?.draft?.requiresAttachmentForwardSync &&
      emailDraftProps?.draftInfo?.draft?.recipientsInfo.inReplyToMongoId
    ) {
      const originalMessageFiles = filesToAttachments(
        conversation.messages.find(
          (m) =>
            m._id ===
            emailDraftProps?.draftInfo?.draft?.recipientsInfo.inReplyToMongoId,
        ),
      );

      /** If we switched _to_ forwarding, add the files present on the original message to the draft. If we switched _from_ forwarding, remove them. */
      let nextDraftAttachments;
      switch (emailDraftProps?.draftInfo?.mode) {
        case EmailReplyType.FORWARD:
          nextDraftAttachments = unique(
            [...draftAttachments, ...originalMessageFiles],
            (attachment) => attachment.url,
          );
          break;
        case EmailReplyType.REPLY:
        case EmailReplyType.REPLY_ALL:
        case undefined:
          nextDraftAttachments = draftAttachments.filter(
            (attachment) =>
              !originalMessageFiles.some((file) => file.url === attachment.url),
          );
          break;
        default:
          assertNever(emailDraftProps?.draftInfo?.mode);
      }

      setDraftAttachments(nextDraftAttachments);
      emailDraftProps?.handleSetReplyDraft({
        ...emailDraftProps.draftInfo,
        draft: {
          ...emailDraftProps.draftInfo.draft,
          requiresAttachmentForwardSync: false,
        },
      });
    }
  }, [
    emailDraftProps?.draftInfo?.status,
    emailDraftProps?.draftInfo?.mode,
    emailDraftProps?.draftInfo?.draft?.requiresAttachmentForwardSync,
    hasLoadedDraftOrAiResponseIntoUi,
  ]);

  useEffect(() => {
    // Don't save the draft just because the recipients info changed
    // We only create the draft when the attachments or text changes
    if (!savedDraft) {
      return;
    }

    // If the recipient info is not yet defined or there are no recipients, we don't need to update the draft
    const recipientInfo = emailDraftProps?.draftInfo?.draft?.recipientsInfo;
    if (!recipientInfo || numRecipients(recipientInfo) === 0) {
      return;
    }

    const savedRecipientsInfo = getSavedRecipientsInfo(savedDraft);
    const hasChanged = !sameRecipients(recipientInfo, savedRecipientsInfo);

    if (hasChanged) {
      requestSaveDraft();
    }
  }, [
    emailDraftProps?.draftInfo?.draft?.recipientsInfo.bcc,
    emailDraftProps?.draftInfo?.draft?.recipientsInfo.cc,
    emailDraftProps?.draftInfo?.draft?.recipientsInfo.to,
  ]);

  const handleDeleteDraft = async () => {
    // If there isn't actually a draft, then we should just clear the editor and return
    // This is only really relevant if we fill the editor with an AI generated response
    // We don't save drafts for AI generated responses until the user makes an edit - this is a requirement of the feature
    if (!savedDraft && !saveDraftPromise.current) {
      setDraftAttachments([]);
      quill?.setText("", Quill.sources.SILENT);
      closeEmailDraft();
      return;
    }

    quill?.disable();
    setDeleteLoading(true);
    try {
      if (savedDraft?._id) {
        const abortController = new AbortController();
        await saveDraftPromise.current;
        const resultConversation = await deleteMessageDraft(client, {
          conversationId: conversation._id,
          messageId: savedDraft._id,
          signal: abortController.signal,
        });
        if (resultConversation) {
          amplitude.logEvent("create-conversationMessage", {
            conversationId: conversation._id,
            channel: conversation.platform,
            visibility,
          });
          setDraftAttachments([]);
          closeInternalNoteIfStillOnSameConversation(resultConversation);
          setActiveConversationIfStillOnSameConversation(resultConversation);
          quill?.setText("", Quill.sources.SILENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setDeleteLoading(false);
    quill?.enable();
    closeEmailDraft();
  };

  const toolbarSelector =
    messageIdForKey !== undefined
      ? `redo-quill-toolbar-${messageIdForKey}`
      : "toolbar";

  const disableEditor: boolean = useMemo(() => {
    if (visibility === MessageVisibility.INTERNAL) {
      return false;
    }

    if (metaSendType === "forbidden") {
      return true;
    }

    if (disabledBecauseOfSMSOptOut) {
      return true;
    }

    if (disabledBecauseOfNoOptIn) {
      return true;
    }

    if (disabledBecauseOfSMSBilling) {
      return true;
    }

    return false;
  }, [
    metaSendType,
    visibility,
    disabledBecauseOfSMSOptOut,
    disabledBecauseOfSMSBilling,
    disabledBecauseOfNoOptIn,
  ]);

  const editorErrorMessage: { message: string; link?: string } | undefined =
    useMemo(() => {
      if (visibility === MessageVisibility.INTERNAL) {
        return undefined;
      }

      if (metaSendType === "forbidden") {
        if (privateReplyLimitation) {
          if (lastCustomerDirectMessage) {
            return {
              message: `Cannot send message. Can't send messages through ${capitalize(conversation.platform)}'s API 7 days after the most recent message, and Instagram does not allow sending more than one private reply to a comment until the customer responds.`,
            };
          } else {
            return {
              message:
                "Instagram does not allow sending more than one private reply to a comment until the customer responds.",
            };
          }
        } else {
          return {
            message: `Can't send messages through ${capitalize(conversation.platform)}'s API 7 days after the most recent customer message.`,
          };
        }
      } else if (disabledBecauseOfSMSOptOut) {
        return {
          message:
            "Messages cannot be sent to a customer who has opted out of SMS messages.",
        };
      }

      if (disabledBecauseOfNoOptIn) {
        return {
          message: "This customer has not opted in to receive SMS messages.",
        };
      }

      if (disabledBecauseOfSMSBilling) {
        return {
          message: "Update billing to send messages",
          link: getBillingPageLink(team._id, BillingTab.SMS_MMS),
        };
      }

      if (attachmentDataLoad.value) {
        switch (attachmentDataLoad.value) {
          case MMSRejectReason.INVALID_TYPE:
            return {
              message:
                "One or more of your attachments cannot be sent over MMS. Valid file types are: jpeg, png, gif, and mp4",
            };
          case MMSRejectReason.SIZE_EXCEEDED:
            return { message: "Message exceeds 1MB limit" };
          default:
            assertNever(attachmentDataLoad.value);
        }
      }

      return undefined;
    }, [
      disabledBecauseOfSMSBilling,
      metaSendType,
      visibility,
      privateReplyLimitation,
      lastCustomerDirectMessage,
      conversation,
      attachmentDataLoad,
      disabledBecauseOfNoOptIn,
    ]);

  const handleAiAction = useHandler(
    (content: string, operation: QuillOperation) => {
      if (quill) {
        const indexToInsert = cursorIndexRef.current ?? 0;
        if (operation === QuillOperation.INSERT) {
          quill.insertText(indexToInsert, content);
        } else if (operation === QuillOperation.REPLACE) {
          quill.setText(content);
        }
        setCustomerRequestedAiResponse(content);
        messageReplyingTo.aiGenerations = [
          ...(messageReplyingTo.aiGenerations || []),
          { text: content },
        ];
      }
    },
  );

  if (shouldShowInternalNoteButton) {
    return (
      <Flex justify="flex-end" p="lg">
        <RedoButton
          disabled={deleteLoading}
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconLeading={StickerSquareSvg}
          onClick={() => setVisibility(MessageVisibility.INTERNAL)}
          size={RedoButtonSize.REGULAR}
          text="Add a note"
        />
      </Flex>
    );
  }

  const shouldShowAiGeneratedResponseBadge =
    evaluateResponseSimilarityToAiResponse(quill?.getText() || "");

  const writingInternalNoteOnEmailPlatform =
    conversation.platform === ConversationPlatform.EMAIL &&
    visibility === MessageVisibility.INTERNAL &&
    !emailDraftProps;

  const shouldShowMacroAutomationsSection =
    isAtLeastOneMacroAutomationActive(macroAutomations);

  return (
    <>
      <div
        className={classNames(messageInputCss.messageInputCard, {
          [messageInputCss.writingInternalNoteTopBorder]:
            writingInternalNoteOnEmailPlatform,
        })}
      >
        <div className={messageInputCss.aboveInput}>
          {((conversation.platform ===
            ConversationPlatform.INSTAGRAM_COMMENTS &&
            !!conversation.instagramCommentThread) ||
            (conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS &&
              !!conversation.facebookCommentThread)) && (
            <div className={messageInputCss.commentThreadSwitch}>
              <Switch
                onChange={(value) => setShowFullCommentThread(value)}
                value={showFullCommentThread}
              />
              <p>Show full comment thread</p>
            </div>
          )}

          <Flex />
          <ActiveUsersTypingText
            activeConversation={conversation}
            typing={typing}
            user={user}
          />
        </div>
        <div
          className={messageInputCss.editorContainer}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (
              (e.code === "Escape" ||
                (e.code === "Backspace" && !autocompleteActionToPerform)) &&
              autocompleteVisible
            ) {
              if (!quill) {
                return;
              }
              e.preventDefault();
              setAutocompleteActionToPerform(null);
              setTriggerOpenAutocompleteMenu(undefined);
              setAutocompleteVisible(false);
            }
          }}
        >
          {
            <div style={{ position: "relative" }}>
              <form
                className={
                  visibility === MessageVisibility.INTERNAL
                    ? messageInputCss.messageInputFormInternal
                    : messageInputCss.messageInputForm
                }
                onKeyDown={onKeyDown}
              >
                {shouldUseEmailConversationLayout &&
                !(
                  isFirstMessageAfterPlatformConversion &&
                  visibility === MessageVisibility.INTERNAL
                ) ? (
                  <MessageInputEmailHeader
                    conversation={conversation}
                    emailDraftProps={emailDraftProps}
                    isFirstMessageAfterPlatformConversion={
                      isFirstMessageAfterPlatformConversion
                    }
                  />
                ) : (
                  <MessageInputHeader
                    setVisibility={setVisibility}
                    visibility={visibility}
                    writingInternalNoteOnEmailPlatform={
                      writingInternalNoteOnEmailPlatform
                    }
                  />
                )}
                {!disableEditor && (
                  <MessageInputToolbarHeader
                    conversation={conversation}
                    handleUpload={handleUpload}
                    metaSendType={metaSendType}
                    setTriggerOpenAutocompleteMenu={
                      setTriggerOpenAutocompleteMenu
                    }
                    toolbarSelector={toolbarSelector}
                    visibility={visibility}
                  />
                )}
                <Flex
                  className={messageInputCss.editor}
                  {...(!shouldUseEmailConversationLayout && { pt: "xl" })}
                >
                  <div
                    className={classNames(quillEditorCss.quillContainer)}
                    onDrop={(e) => doFileDrop(e, handleUpload)}
                    onKeyDown={maybePerformHotkeyAction}
                  >
                    <QuillEditor
                      cursorIndexRef={cursorIndexRef}
                      defaultValue={new Delta().insert("")}
                      editorClassName={quillEditorCss.quillEditor}
                      onTextChange={handleTextChange}
                      placeholder={
                        [
                          ConversationPlatform.INSTAGRAM_COMMENTS,
                          ConversationPlatform.FACEBOOK_COMMENTS,
                        ].includes(conversation.platform)
                          ? "Post a comment reply..."
                          : "Start typing..."
                      }
                      readOnly={disableEditor}
                      ref={setQuill}
                      setEmojiPickerOpenRef={setEmojiPickerOpenRef}
                      toolbar={toolbarSelector}
                    />
                  </div>
                  {htmlToPasteSignature && (
                    <Flex
                      align="center"
                      className={messageInputCss.showSignatureButton}
                      justify="flex-start"
                    >
                      <RedoButton
                        hierarchy={RedoButtonHierarchy.TERTIARY}
                        IconLeading={() => <ThreeDotsIcon />}
                        onClick={() => setShowSignature(!showSignature)}
                        size={RedoButtonSize.SMALL}
                      />
                    </Flex>
                  )}
                </Flex>
                <MessageInputFooter
                  conversation={conversation}
                  conversationClosing={conversationClosing}
                  deleteLoading={deleteLoading}
                  disableEditor={disableEditor}
                  draftId={savedDraft?._id}
                  editorErrorMessage={editorErrorMessage}
                  handleAiAction={handleAiAction}
                  handleDeleteDraft={handleDeleteDraft}
                  handleMessageSend={handleMessageSend}
                  handleSendAndClose={handleSendAndClose}
                  macrosLoad={macrosLoad}
                  messageRespondingToId={messageReplyingTo._id}
                  onHotkeyFunctionReady={handleHotkeyFunctionReady}
                  replyAndClosePending={replyAndClosePending}
                  replyPending={replyPending}
                  saveDraftLoading={saveDraftLoading}
                  sendMessageDisabled={sendMessageDisabled}
                  setMacroModalOpen={setMacroModalOpen}
                  shouldShowAiGeneratedResponseBadge={
                    shouldShowAiGeneratedResponseBadge
                  }
                />
                <div className={messageInputCss.attachmentWrapper}>
                  <QuillAttachmentCarousel
                    attachments={draftAttachments}
                    removeFileFromDrafts={removeFileFromDrafts}
                  />
                </div>
              </form>
              {/* Hide autocomplete for mentions when it's not an internal note */}
              <SupportMessageAutocomplete
                autocompleteActionToPerform={autocompleteActionToPerform}
                client={client}
                discountCodesInMessage={discountCodesInMessage}
                messageVisibility={visibility}
                platform={conversation.platform}
                productsInMessage={productsInMessage}
                quill={quill}
                setAutocompleteActionToPerform={setAutocompleteActionToPerform}
                setDiscountCodesInMessage={setDiscountCodesInMessage}
                setFormatWithInsertsTrigger={setFormatWithInsertsTrigger}
                setProductsInMessage={setProductsInMessage}
                setShouldOpenFromExternalTrigger={
                  setTriggerOpenAutocompleteMenu
                }
                setUsersMentionedInMessage={setUsersMentionedInMessage}
                setVisible={setAutocompleteVisible}
                shouldOpenFromExternalTrigger={triggerOpenAutocompleteMenu}
                team={team}
                usersMentionedInMessage={usersMentionedInMessage}
                visible={autocompleteVisible}
              />
            </div>
          }
        </div>

        {shouldShowMacroAutomationsSection && (
          <>
            <Flex align="flex-start" dir="column">
              <p>Automations</p>
              <MacroAutomationsList
                macroAutomations={macroAutomations}
                platform={conversation.platform}
                setMacroAutomations={setMacroAutomations}
              />
            </Flex>
            <Divider />
          </>
        )}
      </div>
      {conversation.originalPlatform &&
        conversation.platform !== conversation.originalPlatform &&
        isFirstMessageAfterPlatformConversion &&
        visibility !== MessageVisibility.INTERNAL && (
          <Flex justify="flex-end" p="lg">
            <RedoButton
              disabled={deleteLoading}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={StickerSquareSvg}
              onClick={() => setVisibility(MessageVisibility.INTERNAL)}
              size={RedoButtonSize.REGULAR}
              text="Add a note"
            />
          </Flex>
        )}
      <MacroModal
        key={macrosLoad?.value?.length}
        macros={macrosLoad.value}
        open={macroModalOpen}
        refreshMacros={refreshMacros}
        setMacro={setMacro}
        setOpen={setMacroModalOpen}
      />
    </>
  );
});
