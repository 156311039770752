import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";
import { PreviewCampaign } from "./campaigns/edit/preview";

// TODO: remove this once we have a better way to determine if we should be fullscreen
const fullscreen = (url: string) => {
  if (url.includes("/automations/") && !url.includes("new=true")) {
    return false; // temporary check, remove when automations builder is fully complete
  }

  return (
    url.includes("builder") ||
    url.includes("/edit/") ||
    // if last part of url is an objectId
    (url.includes("/marketing/") &&
      url
        .split("/")
        .pop()
        ?.split("?")[0]
        ?.match(/^[0-9a-fA-F]{24}$/) !== null)
  );
};

const HIDE_PADDING_ROUTES: string[] = [
  "/marketing/audience",
  "/marketing/segments",
];

function hidePadding(url: string) {
  return HIDE_PADDING_ROUTES.some((route) => url.includes(route));
}

const campaignDetailsBreadcrumb = breadcrumbSlot();

export const marketingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Marketing", breadcrumbNavigate: false },
    lazy: async () => {
      const { Page } = await import("../app/page");
      return {
        element: (
          <Page
            hideHeader={fullscreen}
            hideNavbar={fullscreen}
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      {
        path: "signups",
        handle: { breadcrumb: "Sign-ups" },
        children: [
          {
            index: true,
            async lazy() {
              const { MarketingSignups } = await import(
                "./signups/signups-page"
              );
              return { Component: MarketingSignups };
            },
          },
          {
            path: "builder/:signupFormId",
            handle: { breadcrumb: "Sign-up Builder" },
            async lazy() {
              const { MarketingSignupBuilder } = await import(
                "./signups/builder"
              );
              return { Component: MarketingSignupBuilder };
            },
          },
        ],
      },
      {
        path: "automations",
        handle: { breadcrumb: "Automations" },
        children: [
          {
            index: true,
            async lazy() {
              const { MarketingAutomations } = await import(
                "./automations/automations-page"
              );
              return { Component: MarketingAutomations };
            },
          },
          {
            path: ":automationId",
            handle: { breadcrumb: "Automation Builder" },
            async lazy() {
              const { AutomationsBuilderProxy } = await import(
                "./automations/builder"
              );
              return { Component: AutomationsBuilderProxy };
            },
          },
        ],
      },
      {
        path: "campaigns",
        handle: { breadcrumb: "Campaigns" },
        children: [
          {
            index: true,
            async lazy() {
              const { MarketingCampaigns } = await import(
                "./campaigns/campaigns-page"
              );
              return { Component: MarketingCampaigns };
            },
          },
          {
            path: ":campaignId/details",
            handle: { breadcrumb: campaignDetailsBreadcrumb },
            async lazy() {
              const { MarketingCampaignDetails } = await import(
                "./campaigns/campaign-details-page"
              );
              return {
                element: (
                  <MarketingCampaignDetails
                    breadcrumb={campaignDetailsBreadcrumb}
                  />
                ),
              };
            },
          },
          {
            path: ":campaignId/edit/groups",
            lazy: async () => {
              const { EditCampaignGroups } = await import(
                "./campaigns/edit/groups"
              );
              return { Component: EditCampaignGroups };
            },
          },
          {
            path: ":campaignId/edit/template",
            lazy: async () => {
              const { EditCampaignTemplate } = await import(
                "./campaigns/edit/template"
              );
              return { Component: EditCampaignTemplate };
            },
          },
          {
            path: ":campaignId/edit/preview", // called "review" in the mocks but I want to disambiguate from the "reviews" product
            lazy: async () => {
              return { Component: PreviewCampaign };
            },
          },
        ],
      },
      {
        path: "templates",
        handle: { breadcrumb: "Templates" },
        children: [
          {
            path: "email",
            handle: { breadcrumb: "Email" },
            children: [
              {
                index: true,
                async lazy() {
                  const { Emails } = await import(
                    "./email-builder/email-templates"
                  );
                  return { Component: Emails };
                },
              },
              {
                path: ":emailTemplateId",
                handle: { breadcrumb: "Edit" },
                lazy: async () => {
                  const { EmailBuilderForTemplateCollection } = await import(
                    "./email-builder/email-builder"
                  );
                  return { Component: EmailBuilderForTemplateCollection };
                },
              },
            ],
          },
          {
            path: "sms",
            handle: { breadcrumb: "SMS" },
            children: [
              {
                index: true,
                async lazy() {
                  const { Sms } = await import(
                    "./sms-builder/sms-builder-templates"
                  );
                  return { Component: Sms };
                },
              },
              {
                path: ":smsTemplateId",
                async lazy() {
                  const { SmsBuilderLoad } = await import(
                    "./sms-builder/sms-builder"
                  );
                  return { Component: SmsBuilderLoad };
                },
              },
            ],
          },
        ],
      },
      {
        path: "audience",
        handle: { breadcrumb: "Audience" },
        children: [
          {
            index: true,
            async lazy() {
              const { AudiencePage } = await import("./audience/audience-page");
              return { Component: AudiencePage };
            },
          },
        ],
      },
      {
        path: "segments",
        handle: { breadcrumb: "Segments" },
        children: [
          {
            index: true,
            async lazy() {
              const { SegmentsPage } = await import("./segments/segments-page");
              return { Component: SegmentsPage };
            },
          },
        ],
      },
    ],
  },
];
