import { UserCallAvailability } from "@redotech/redo-model/support/voice/voice-types";
import { z } from "zod";

export const getTeamCallAvailabilities = {
  input: z.object({}),
  output: z.object({
    callAvailabilities: z.array(
      z.object({
        userId: z.string(),
        availability: z.nativeEnum(UserCallAvailability),
      }),
    ),
  }),
};
