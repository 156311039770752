import { campaignWithCustomerGroupsSchema } from "@redotech/redo-model/campaigns/campaign-definition";
import { z } from "zod";

export const getCampaignById = {
  input: z.object({
    id: z.string(),
    includeCustomerGroups: z.boolean().optional(),
  }),
  output: campaignWithCustomerGroupsSchema,
};
