import Gravatar from "@gravatar/js";

export const getGravatarUrlFromEmail = (
  email: string,
  size: number = 32,
  defaultImage: "404" | "identicon" = "404",
) => {
  return Gravatar({
    email,
    defaultImage,
    protocol: location.protocol === "https:" ? "https" : undefined,
    size: Math.ceil(devicePixelRatio * size),
  });
};
