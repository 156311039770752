import { z } from "zod";
import { makeReportDateFilterSchema } from "./base-report-date-filter";
import { ReportFilterType } from "./report-filter-type";

export const ProductProcessedDateFilterSchema = makeReportDateFilterSchema(
  ReportFilterType.PRODUCT_PROCESSED_DATE,
);

export type ProductProcessedDateFilter = z.infer<
  typeof ProductProcessedDateFilterSchema
>;
// BaseReportDateFilter<ReportFilterType.PRODUCT_PROCESSED_DATE>
