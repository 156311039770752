import { genericMemo } from "@redotech/react-util/component";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpIcon from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import * as classNames from "classnames";
import { ReactNode, useState } from "react";
import { Flex } from "../../flex";
import { Icon } from "../../icon";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import { RedoListItem } from "../list/redo-list";
import { RedoListItemSize } from "../list/redo-list-item";
import * as redoDropdownInputCss from "./redo-dropdown-input.module.css";
import { RedoSingleSelectDropdown } from "./redo-single-select-dropdown";

export enum RedoDropdownInputSize {
  SMALL = "sm",
  REGULAR = "md",
}

export const RedoDropdownInput = genericMemo(function RedoDropdownInput<T>({
  options,
  optionSelected,
  children,
  selectedItem,
  size = RedoDropdownInputSize.REGULAR,
  disabled = false,
  placeholder,
  className,
  fitToAnchor = true,
  label,
  description,
}: {
  size?: RedoDropdownInputSize;
  options: RedoListItem<T>[];
  optionSelected(value: RedoListItem<T>): void;
  children(item: RedoListItem<T>): ReactNode;
  selectedItem?: RedoListItem<T>;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  fitToAnchor?: boolean;
  label?: string;
  description?: string;
}) {
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const descriptorFontSize = sizeToDescriptorTextProps[size];

  const sizeClass =
    size === RedoDropdownInputSize.REGULAR
      ? redoDropdownInputCss.regular
      : redoDropdownInputCss.small;

  return (
    <>
      <Flex dir="column" gap="sm">
        {label && (
          <Text
            fontSize={descriptorFontSize}
            fontWeight="medium"
            textColor="secondary"
          >
            {label}
          </Text>
        )}
        <button
          className={classNames(
            className,
            redoDropdownInputCss.input,
            sizeClass,
            dropdownOpen ? redoDropdownInputCss.open : "",
            disabled ? redoDropdownInputCss.disabled : "",
          )}
          disabled={disabled}
          ref={setDropdownButtonRef}
          type="button"
        >
          {selectedItem ? (
            children(selectedItem)
          ) : (
            <div className={redoDropdownInputCss.placeholder}>
              {placeholder}
            </div>
          )}
          <Icon
            arbiterIconSvg={dropdownOpen ? ChevronUpIcon : ChevronDownIcon}
            className={redoDropdownInputCss.chevron}
            color="ghost"
          />
        </button>
        {description && (
          <Text fontSize={descriptorFontSize} textColor="tertiary">
            {description}
          </Text>
        )}
      </Flex>
      <RedoSingleSelectDropdown
        dropdownButtonRef={dropdownButtonRef}
        fitToAnchor={fitToAnchor}
        onDropdownToggled={setDropdownOpen}
        options={options}
        optionSelected={optionSelected}
        selectedItem={options.find(
          (item) => item.value === selectedItem?.value,
        )}
        size={
          size === RedoDropdownInputSize.REGULAR
            ? RedoListItemSize.MEDIUM
            : RedoListItemSize.SMALL
        }
      >
        {(item) => children(item)}
      </RedoSingleSelectDropdown>
    </>
  );
});

const sizeToDescriptorTextProps: Record<RedoDropdownInputSize, TextSizeValue> =
  {
    [RedoDropdownInputSize.SMALL]: "xs",
    [RedoDropdownInputSize.REGULAR]: "sm",
  };
