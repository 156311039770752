// extracted by mini-css-extract-plugin
export var aboveInput = "_8s-0";
export var actionButton = "_8s-5";
export var actionButtonContainer = "_8s-1";
export var actionIcon = "_8s-6";
export var aiInfoContainer = "_8s-2";
export var attachmentWrapper = "_8s-7";
export var bold = "_8s-3";
export var checkIcon = "_8s-8";
export var clickingRecipientBar = "_8s-11";
export var commentThreadSwitch = "_8s-a";
export var draftImage = "_8s-b";
export var draftImageContainer = "_8s-c";
export var draftImages = "_8s-e";
export var editButton = "_8s-g";
export var editor = "_8s-f";
export var editorContainer = "_8s-y";
export var editorErrorTooltipWrapper = "_8s-9";
export var headerDivider = "_8s-h";
export var icon = "_8s-i";
export var info = "_8s-j";
export var internal = "_8s-n";
export var internalDivider = "_8s-k";
export var internalMessage = "_8s-l";
export var messageInputCard = "_8s-m";
export var messageInputForm = "_8s-o";
export var messageInputFormInternal = "_8s-p";
export var options = "_8s-q";
export var publicMessageCustomer = "_8s-s";
export var publicMessageMerchant = "_8s-r";
export var removeImageButton = "_8s-d";
export var replyButtons = "_8s-t";
export var showSignatureButton = "_8s-u";
export var spinner = "_8s-v";
export var verticalButtonDivider = "_8s-4";
export var visibilitySelector = "_8s-w";
export var warning = "_8s-x";
export var wrappingText = "_8s-z";
export var writingInternalNoteTopBorder = "_8s-10";