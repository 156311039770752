import type { RedoMerchantRpcClient } from "@redotech/merchant-sdk/rpc/client";
import { createRedoMerchantRpcClient } from "@redotech/merchant-sdk/rpc/client";
import { Context, createContext, ReactNode, useMemo } from "react";

export const RedoMerchantRpcClientContext: Context<
  RedoMerchantRpcClient | undefined
> = createContext<RedoMerchantRpcClient | undefined>(undefined);
RedoMerchantRpcClientContext.displayName = "RedoMerchantRpcClientContext";

export function RedoMerchantRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoMerchantRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createRedoMerchantRpcClient({
      baseURL: new URL(`${baseUrl}/rpc`),
      headers: {
        Authorization: authToken,
      },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoMerchantRpcClientContext.Provider value={redoMerchantRpcClient}>
      {children}
    </RedoMerchantRpcClientContext.Provider>
  );
}
