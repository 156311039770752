export const getEstimatedTimeAgo = (date: Date) => {
  const differenceInDays = Math.floor(
    Math.abs(new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
  );
  // if today return today
  if (differenceInDays === 0) {
    return "today";
  }
  // if yesterday return yesterday
  // if within last month return how many days ago
  if (differenceInDays < 31) {
    if (differenceInDays === 1) {
      return "yesterday";
    }
    return `${differenceInDays} days ago`;
  }
  // if within last year return how many months ago
  if (differenceInDays < 365) {
    const differenceInMonths =
      new Date().getMonth() - new Date(date).getMonth();
    return `${differenceInMonths} month${differenceInMonths === 1 ? "" : "s"} ago`;
  }
  // else return how many years ago
  const differenceInYears =
    new Date().getFullYear() - new Date(date).getFullYear();
  return `${differenceInYears} year${differenceInYears === 1 ? "" : "s"} ago`;
};
