import { campaignRecipientSchema } from "@redotech/redo-model/campaigns/campaign-recipient-definition";
import { paginatedResultSchema } from "@redotech/redo-model/pagination";
import { z } from "zod";

export const getCampaignRecipientsCount = {
  input: z.object({ campaignId: z.string() }),
  output: z.object({ count: z.number() }),
};

export const paginateCampaignRecipients = {
  input: z.object({
    campaignId: z.string(),
    paginationKey: z.string().optional(),
    limit: z.number().optional(),
  }),
  output: paginatedResultSchema(campaignRecipientSchema),
};
