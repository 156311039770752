import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState } from "@redotech/react-util/load";
import {
  ExpandedConversation,
  ExpandedConversationMessage,
} from "@redotech/redo-model/conversation";
import { RedoAudioPlayer } from "@redotech/redo-web/arbiter-components/audio/redo-audio-player";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import { RedoSearchTextInput } from "@redotech/redo-web/arbiter-components/input/redo-search-text-input";
import {
  RedoModal,
  RedoModalSize,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import DownloadCloud02 from "@redotech/redo-web/arbiter-icon/download-cloud-02.svg";
import SearchIcon from "@redotech/redo-web/arbiter-icon/search-sm_filled.svg";
import Trash03 from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import XClose from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classnames from "classnames";
import * as escapeRegExp from "lodash/escapeRegExp";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { RedoMerchantRpcClientContext } from "../app/redo-merchant-rpc-client-provider";
import * as transcriptDrawerCss from "./transcript-drawer.module.css";

type TranscriptDataLoad = {
  callRecord: {
    callStartedAt: Date;
    recordingUrl?: string;
  };
  transcript?: {
    results: {
      audio_segments: {
        speaker_label: string;
        transcript: string;
      }[];
    };
  };
};

const formatDateString = (date: Date) => {
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const TranscriptDrawer = memo(function TranscriptDrawer({
  message,
  open,
  handleDrawerState,
  transcriptData,
  conversation,
  transcriptionMessageBeingShown,
}: {
  message: ExpandedConversationMessage;
  open: boolean;
  handleDrawerState: (message: ExpandedConversationMessage) => void;
  transcriptData: LoadState<TranscriptDataLoad | undefined>;
  conversation: ExpandedConversation;
  transcriptionMessageBeingShown?: ExpandedConversationMessage;
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);
  const [searchString, setSearchString] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const segmentRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const [currentSearchIndex, setCurrentSearchIndex] = useState<
    number | undefined
  >(undefined);
  const [totalSearchResults, setTotalSearchResults] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    // scroll to the segment that is being searched for
    if (
      currentSearchIndex !== undefined &&
      segmentRefs.current[currentSearchIndex]
    ) {
      setTimeout(function () {
        segmentRefs.current[currentSearchIndex]?.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }, 0);
    }
  }, [currentSearchIndex]);

  useEffect(() => {
    setCurrentSearchIndex(0);
    if (
      searchString &&
      transcriptData.value?.transcript?.results?.audio_segments
    ) {
      const regex = new RegExp(`(${escapeRegExp(searchString)})`, "gi");
      const count =
        transcriptData.value.transcript.results.audio_segments.reduce(
          (acc, segment) => {
            const totalMatches = segment.transcript.match(regex)?.length || 0;
            return acc + totalMatches;
          },
          0,
        );
      setTotalSearchResults(count);
    } else {
      setTotalSearchResults(undefined);
    }
  }, [transcriptData.value?.transcript?.results?.audio_segments, searchString]);

  const dropdownActions: RedoCommandMenuItem[] = useMemo(() => {
    return [
      {
        Icon: DownloadCloud02,
        text: "Download recording",
        onClick: () => {
          window.open(transcriptData.value?.callRecord.recordingUrl, "_blank");
        },
        size: RedoButtonSize.SMALL,
      },
      {
        Icon: Trash03,
        text: "Delete",
        onClick: () => {
          setDeleteModalOpen(true);
        },
        size: RedoButtonSize.SMALL,
        theme: RedoButtonTheme.DESTRUCTIVE,
      },
    ];
  }, [transcriptData.value?.callRecord.recordingUrl]);

  const handleSearchPrevious = useHandler(() => {
    // existing and non 0 index, jump to previous result
    if (currentSearchIndex) {
      setCurrentSearchIndex((currentSearchIndex || 0) - 1);
    }
  });

  const handleSearchNext = useHandler(() => {
    // existing and not last index, jump to next result
    if (
      currentSearchIndex !== undefined &&
      totalSearchResults !== undefined &&
      currentSearchIndex < totalSearchResults - 1
    ) {
      setCurrentSearchIndex((currentSearchIndex || 0) + 1);
    }
  });

  if (!transcriptData.value) {
    return null;
  }

  return (
    <Flex
      className={classnames(
        transcriptDrawerCss.drawer,
        open &&
          transcriptionMessageBeingShown?._id === message._id &&
          transcriptDrawerCss.drawerOpen,
      )}
      dir="column"
    >
      <Flex dir="column" gap="xl" w="full">
        <Flex dir="column" gap="xs">
          <Flex align="center" justify="space-between" w="full">
            <Text fontSize="xl" fontWeight="semibold">
              Call transcript
            </Text>
            <RedoButton
              hierarchy={RedoButtonHierarchy.TERTIARY}
              IconLeading={XClose}
              onClick={() => handleDrawerState(message)}
              size={RedoButtonSize.LARGE}
            />
          </Flex>
          <Text fontSize="sm" textColor="tertiary">
            {formatDateString(transcriptData.value.callRecord.callStartedAt)}
          </Text>
        </Flex>
        <Flex dir="column" gap="sm">
          <Text fontSize="sm" fontWeight="regular" textColor="secondary">
            Recording
          </Text>
          <RedoAudioPlayer
            audioSrc={transcriptData.value.callRecord.recordingUrl || ""}
            customActionList={
              <RedoCommandMenu
                anchor={dropdownAnchor}
                items={dropdownActions}
                open={menuOpen}
                setOpen={setMenuOpen}
              />
            }
            customMenuOpen={menuOpen}
            setCustomMenuOpen={setMenuOpen}
            setDropdownAnchor={setDropdownAnchor}
            showCustomActions
          />
        </Flex>
        <div className={transcriptDrawerCss.divider}>
          <Divider />
        </div>
        <RedoSearchTextInput
          currentSearchIndex={currentSearchIndex || 0}
          downAction={handleSearchNext}
          searchString={searchString}
          setSearchString={setSearchString}
          textInputProps={{
            IconLeading: SearchIcon,
            placeholder: "Search",
            setValue: setSearchString,
            value: searchString,
          }}
          totalSearchResults={totalSearchResults || 0}
          upAction={handleSearchPrevious}
        />
      </Flex>
      <Flex
        className={transcriptDrawerCss.drawerFooter}
        dir="column"
        gap="lg"
        mt="4xl"
      >
        <AudioSegments
          currentSearchIndex={currentSearchIndex}
          searchString={searchString}
          segmentRefs={segmentRefs}
          totalSearchResults={totalSearchResults}
          transcriptAudioSegments={
            transcriptData.value?.transcript?.results.audio_segments || []
          }
        />
      </Flex>
      <RedoModal
        isOpen={deleteModalOpen}
        modalSize={RedoModalSize.SMALL}
        onModalCloseRequested={() => setDeleteModalOpen(false)}
        primaryButton={{
          text: "Delete",
          onClick: async () => {
            await client.deleteRecordingFromCallRecord({
              callRecordId: message.callRecord.toString(),
              conversationId: conversation._id.toString(),
            });
            handleDrawerState(message);
            setDeleteModalOpen(false);
          },
          disabled: false,
        }}
        secondaryButton={{
          text: "Cancel",
          onClick: () => {
            setDeleteModalOpen(false);
          },
          disabled: false,
        }}
        theme={RedoModalTheme.WARN}
        title="Are you sure you want to delete this recording?"
        TitleIcon={Trash03}
      />
    </Flex>
  );
});

const AudioSegments = memo(function AudioSegments({
  totalSearchResults,
  searchString,
  currentSearchIndex,
  segmentRefs,
  transcriptAudioSegments = [],
}: {
  totalSearchResults: number | undefined;
  searchString: string;
  currentSearchIndex: number | undefined;
  segmentRefs: React.MutableRefObject<(HTMLSpanElement | null)[]>;
  transcriptAudioSegments: {
    speaker_label: string;
    transcript: string;
  }[];
}) {
  const indexesOfSearchInstances = Array.from(Array(totalSearchResults).keys());

  const speakerLabelMap = new Map<string, string>();
  const getSpeakerLabel = (speakerLabel: string) => {
    const numSpeakerLabels = speakerLabelMap.size;
    if (speakerLabelMap.get(speakerLabel)) {
      return speakerLabelMap.get(speakerLabel);
    } else {
      speakerLabelMap.set(speakerLabel, `Person ${numSpeakerLabels + 1}`);
      return speakerLabelMap.get(speakerLabel);
    }
  };

  const handleHighlight = (text: string, highlight: string) => {
    if (!highlight.trim()) {
      return text;
    }
    const highlightSpan = (content: string, key: number) => {
      const instanceOfSearchString = indexesOfSearchInstances.shift();
      return (
        <span
          className={
            instanceOfSearchString === currentSearchIndex
              ? transcriptDrawerCss.highlighted
              : transcriptDrawerCss.lighterHighlight
          }
          key={key}
          ref={(el) => {
            if (instanceOfSearchString !== undefined) {
              segmentRefs.current[instanceOfSearchString] = el;
            }
          }}
        >
          {content}
        </span>
      );
    };
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");

    return text.split(regex).map((part, index) => {
      const shouldHighlightText = regex.test(part);
      return shouldHighlightText ? highlightSpan(part, index) : part;
    });
  };

  return (
    <>
      {transcriptAudioSegments.map((item, index) => (
        <Flex dir="column" gap="none" key={index}>
          <Text fontSize="sm" fontWeight="medium" textColor="secondary">
            {getSpeakerLabel(item.speaker_label)}
          </Text>
          <Text fontSize="sm">
            {handleHighlight(item.transcript, searchString)}
          </Text>
        </Flex>
      ))}
    </>
  );
});
