import { z } from "zod";

export const getMarketingSubscriberCounts = {
  input: z.null(),
  output: z.object({
    smsSubscriberCount: z.number(),
    emailSubscriberCount: z.number(),
    totalSubscriberCount: z.number(),
    emailSubscribesOverTime: z.array(
      z.object({
        date: z.date(),
        value: z.number(),
      }),
    ),
    smsSubscribesOverTime: z.array(
      z.object({
        date: z.date(),
        value: z.number(),
      }),
    ),
  }),
};
