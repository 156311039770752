import { z } from "zod";

// each repo should implement their own pagination key. If it's multiple fields, you could combine them with base64 or something.
export interface PaginatedResult<T> {
  data: T[];
  paginationKey: string | undefined;
}

export const paginatedResultSchema = <T extends z.ZodType>(itemSchema: T) =>
  z.object({
    data: z.array(itemSchema),
    paginationKey: z.string().optional(),
  });
