// extracted by mini-css-extract-plugin
export var danger = "_39-6";
export var lightPink = "_39-a";
export var medium = "_39-j";
export var nearSquare = "_39-m";
export var neutralBlue = "_39-8";
export var neutralYellow = "_39-7";
export var noCapitalize = "_39-1";
export var normal = "_39-2";
export var pill = "_39-0";
export var primary = "_39-3";
export var primaryLight = "_39-4";
export var shadow = "_39-n";
export var small = "_39-k";
export var solidBlack = "_39-i";
export var solidGreen = "_39-d";
export var solidLightBlue = "_39-f";
export var solidNavy = "_39-e";
export var solidOrange = "_39-h";
export var solidPink = "_39-g";
export var solidRed = "_39-b";
export var solidWhite = "_39-9";
export var solidYellow = "_39-c";
export var success = "_39-5";
export var xsmall = "_39-l";