import { z } from "zod";
import { CompensationMethod } from "../../../return";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum CompensationMethodsFilterQuery {
  INCLUDES_ANY = "includesAny",
  INCLUDES_ALL = "includesAll",
  INCLUDES_NONE = "includesNone",
}

export const CompensationMethodsFilterSchema = makeReportFilterSchema(
  ReportFilterType.COMPENSATION_METHODS,
  CompensationMethodsFilterQuery,
  z.array(z.nativeEnum(CompensationMethod)),
);

export type CompensationMethodsFilter = z.infer<
  typeof CompensationMethodsFilterSchema
>;
// BaseReportFilter<ReportFilterType.COMPENSATION_METHODS, CompensationMethodsFilterQuery, z.ZodArray<z.ZodEnum<CompensationMethod>>>
