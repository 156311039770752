import { memo, ReactNode } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Flex } from "../flex";

export const FormBuilderPageWrapper = memo(function FormBuilderForm({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Flex
        bgColor="secondary"
        dir="column"
        gap="none"
        grow="1"
        height="screen"
        textColor="primary"
        w="full"
      >
        {children}
      </Flex>
    </DndProvider>
  );
});
