import { RouteObject } from "react-router-dom";

export const conciergeRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Concierge", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        path: "dashboard",
        async lazy() {
          const { ConciergeMetricsDashboard } = await import(
            "./dashboards/page"
          );
          return { Component: ConciergeMetricsDashboard };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutConcierge } = await import("./about");
          return { Component: AboutConcierge };
        },
      },
    ],
  },
];
