// extracted by mini-css-extract-plugin
export var actionButton = "_9t-1";
export var actions = "_9t-0";
export var copyableField = "_9t-2";
export var icon = "_9t-3";
export var overflowVisible = "_9t-4";
export var pillWrapper = "_9t-5";
export var productDetails = "_9t-7";
export var productPillsContainer = "_9t-8";
export var productTopDetails = "_9t-9";
export var products = "_9t-6";
export var returnDetails = "_9t-a";
export var strong = "_9t-b";
export var variantQuantityRow = "_9t-c";