import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { getUserAvatarUrl } from "@redotech/redo-merchant-app/common/get-avatar-url";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import {
  getPrimaryCustomerEmail,
  getPrimaryCustomerInstagram,
  getPrimaryCustomerPhoneNumber,
  getPrimaryCustomerShopifyCustomerId,
} from "@redotech/redo-model/customer";
import { Order } from "@redotech/redo-model/order";
import { Return } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoList } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { CopyableText } from "@redotech/redo-web/copyable-text";
import { Divider } from "@redotech/redo-web/divider";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { ExternalLinkButton } from "@redotech/redo-web/external-link-button";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import ShopifyBagLogo from "@redotech/redo-web/shopify/bag-logo.svg";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { UserImage, UserImageSize } from "@redotech/redo-web/user-image";
import { filterTruthy, unique } from "@redotech/util/array";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { updateConversation } from "../../client/conversations";
import { getCustomerSearch } from "../../client/shopify";
import { ShopifyCustomer } from "../new-message-modal";
import * as conversationDetailsCustomerCss from "./conversation-details-customer.module.css";
import { CustomerTotals } from "./customer-totals";

const NAME_EMAIL_DELIMITER = " - ";

export const ConversationDetailsCustomer = memo(
  function ConversationDetailsCustomer({
    conversation,
    orders,
    returns,
    doOrdersLoad,
    doReturnsLoad,
    doCartsLoad,
    setActiveConversation,
  }: {
    conversation: ExpandedConversation;
    orders: Order[] | undefined;
    returns: Return[] | undefined;
    doOrdersLoad: () => void;
    doReturnsLoad: () => void;
    doCartsLoad: () => void;
    setActiveConversation: (conversation: ExpandedConversation) => void;
  }) {
    const customer = conversation.customer;
    const client = useRequiredContext(RedoMerchantClientContext);
    const team = useRequiredContext(TeamContext);
    const user = useContext(UserContext);

    const primaryCustomerEmail = getPrimaryCustomerEmail(customer);
    const primaryCustomerPhone = getPrimaryCustomerPhoneNumber(customer);
    const primaryCustomerInstagram = getPrimaryCustomerInstagram(customer);

    const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
      null,
    );
    const [editDropdownOpen, setEditDropdownOpen] = useState(false);
    const [firstName, setFirstName] = useState<string | null>();
    const [lastName, setLastName] = useState<string | null>();
    const canEditCustomer =
      !!user && permitted(user.permissions, Permission.EDIT_CUSTOMER);

    const [searchString, setSearchString] = useState<string | undefined>();
    const debouncedSearch = useDebounce(searchString, 500);
    const [customersLoading, setCustomersLoading] = useState(false);
    const [customers, setCustomers] = useState<Record<string, ShopifyCustomer>>(
      {},
    );

    const gravatarUrl = useMemo(() => {
      if (!primaryCustomerEmail) {
        return null;
      }
      return getUserAvatarUrl({
        email: primaryCustomerEmail || "",
        userId: user?._id,
        defaultImage: "404",
        size: Math.ceil(devicePixelRatio * 32),
      });
    }, [devicePixelRatio, primaryCustomerEmail]);

    const [customersLoad, doCustomersLoad] = useTriggerLoad(async (signal) => {
      const searchTermPieces = (debouncedSearch || "").split(
        NAME_EMAIL_DELIMITER,
      );
      const searchTerm = searchTermPieces[searchTermPieces.length - 1];
      const customers = await getCustomerSearch(client, {
        search: searchTerm,
        signal,
      });
      setCustomersLoading(false);
      return customers;
    });
    useEffect(() => {
      if (searchString !== undefined) {
        setCustomersLoading(true);
        doCustomersLoad();
      }
    }, [debouncedSearch]);

    useEffect(() => {
      if (customersLoad.value?.customers) {
        setCustomers(
          customersLoad.value.customers.reduce(
            (accumulator: Record<string, ShopifyCustomer>, customer) => {
              accumulator[customer.email!] = {
                email: customer.email!,
                firstName: customer.first_name!,
                lastName: customer.last_name!,
              };
              return accumulator;
            },
            {},
          ),
        );
      }
    }, [customersLoad.value]);

    const handleCustomerChange = async (newEmail: string) => {
      const newCustomer = {
        email: newEmail || undefined,
        firstName:
          firstName ||
          (newEmail ? customers[newEmail]?.firstName || undefined : undefined),
        lastName:
          lastName ||
          (newEmail ? customers[newEmail]?.lastName || undefined : undefined),
      };
      if (newEmail) {
        const result = await updateConversation(client, conversation, {
          customer: newCustomer,
        });
        amplitude.logEvent("update-conversationCustomer", {
          conversationId: conversation._id,
          channel: conversation.platform,
          customer: newCustomer?.email,
        });
        setActiveConversation({
          ...conversation,
          customer: result.data.customer,
        });
        doOrdersLoad();
        doReturnsLoad();
        doCartsLoad();
        setEditDropdownOpen(false);
        setFirstName(null);
        setLastName(null);
      }
    };

    const getCustomerDisplayName = (email: string) => {
      if (email === "none") {
        return "No customer";
      }
      if (email.includes(NAME_EMAIL_DELIMITER)) {
        return email;
      }

      if (email === primaryCustomerEmail) {
        return `${customer.name}${NAME_EMAIL_DELIMITER}${primaryCustomerEmail}`;
      }
      if (!customers[email]) {
        return `New customer${NAME_EMAIL_DELIMITER}${email}`;
      }
      if (
        !customers[email]?.firstName &&
        !customers[email]?.lastName &&
        !customers[email]?.email
      ) {
        return "";
      }
      if (!customers[email]?.firstName && !customers[email]?.lastName) {
        return `Missing name${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
      }
      return `${customers[email]?.firstName || ""} ${
        customers[email]?.lastName || ""
      }${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
    };

    const prependCustomCustomerResult = (customerNames: string[]) => {
      if (searchString?.includes("@")) {
        return [searchString, ...customerNames];
      } else {
        return customerNames;
      }
    };

    function handleViewCustomerOnShopify(url: string): void {
      window.open(url, "_blank");
    }

    const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

    const closeDropdown = () => {
      setEditDropdownOpen(false);
      setSearchString("");
    };

    const customerInput = (
      <input
        autoFocus
        className={conversationDetailsCustomerCss.input}
        onChange={(event) => setSearchString(event.target.value)}
        placeholder="Search by email, name, or order #..."
        ref={setInputRef}
        value={searchString}
      />
    );

    const shopifyUrl = shopifyCustomerLink(team, conversation, orders || []);

    return (
      <div className={conversationDetailsCustomerCss.details}>
        <Flex dir="column" gap="xs">
          <ClickAwayListener
            onClickAway={() => {
              if (editDropdownOpen) {
                closeDropdown();
              }
            }}
          >
            <div>
              <Flex align="center" justify="space-between">
                <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
                  Customer
                </Text>
              </Flex>
              <Flex align="center" gap="xs" ref={setDropdownAnchor}>
                <Tooltip
                  className={
                    (customer?.name?.length || 0) <= 15
                      ? conversationDetailsCustomerCss.hidden
                      : undefined
                  }
                  placement="top"
                  title={customer?.name}
                >
                  <div
                    className={conversationDetailsCustomerCss.customerBadge}
                    onClick={() => {
                      if (!canEditCustomer) {
                        return;
                      }
                      amplitude.logEvent("view-updateCustomerModal", {
                        conversationId: conversation._id,
                        channel: conversation.platform,
                      });
                      setEditDropdownOpen(true);
                    }}
                  >
                    <RedoBadge
                      avatar={{
                        alt: "Customer profile picture",
                        name: customer?.name,
                        imageUrl: gravatarUrl,
                      }}
                      color={RedoBadgeColor.GRAY}
                      size={RedoBadgeSize.SMALL}
                      text={customer?.name || "none"}
                    />
                  </div>
                </Tooltip>
                <div>
                  <Dropdown
                    anchor={dropdownAnchor}
                    fitToAnchor={false}
                    open={editDropdownOpen}
                  >
                    <Flex
                      className={
                        conversationDetailsCustomerCss.dropdownContainer
                      }
                      dir="column"
                      gap="sm"
                    >
                      {customerInput}
                      <Divider />

                      {customersLoading && (
                        <Text fontSize="xs">Loading...</Text>
                      )}
                      <RedoList
                        focusedIndex={focusedIndex}
                        isItemSelected={(item) => {
                          return item.value === primaryCustomerEmail;
                        }}
                        items={prependCustomCustomerResult(
                          Object.keys(customers),
                        ).map((customer) => ({
                          value: customer,
                        }))}
                        itemSelected={async (item) => {
                          closeDropdown();
                          await handleCustomerChange(item.value);
                        }}
                        refToListenTo={inputRef}
                        setFocusedIndex={setFocusedIndex}
                        size={RedoListItemSize.SMALL}
                      >
                        {(option) => {
                          return (
                            <Tooltip
                              className={
                                getCustomerDisplayName(option.value)?.length <=
                                35
                                  ? conversationDetailsCustomerCss.hidden
                                  : undefined
                              }
                              placement="top"
                              title={getCustomerDisplayName(option.value)}
                            >
                              <Flex
                                className={
                                  conversationDetailsCustomerCss.dropdownImage
                                }
                              >
                                <UserImage
                                  alt="User profile picture"
                                  imageUrl={getUserAvatarUrl({
                                    email: option.value,
                                  })}
                                  name={`${customers[option.value]?.firstName} ${customers[option.value]?.lastName}`}
                                  size={UserImageSize.TINY}
                                />
                                <Text
                                  fontSize="xs"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                >
                                  {getCustomerDisplayName(option.value)}
                                </Text>
                              </Flex>
                            </Tooltip>
                          );
                        }}
                      </RedoList>
                    </Flex>
                  </Dropdown>
                </div>
                {team.settings.customerAccounts?.enabled &&
                  customer?.customer &&
                  primaryCustomerEmail && (
                    <ExternalLinkButton
                      url={`${process.env.MERCHANT_APP_URL}/stores/${team._id}/customers/${customer.customer}`}
                    />
                  )}
              </Flex>
            </div>
          </ClickAwayListener>
        </Flex>
        <Flex dir="column" mt="sm">
          {primaryCustomerEmail && (
            <div>
              <div className={conversationDetailsCustomerCss.copyableField}>
                <div className={conversationDetailsCustomerCss.email}>
                  <CopyableText
                    fontSize="xs"
                    fontWeight="medium"
                    textColor="tertiary"
                    textOverflow="ellipsis"
                  >
                    {primaryCustomerEmail}
                  </CopyableText>
                </div>
              </div>
            </div>
          )}

          {primaryCustomerPhone && (
            <CopyableText
              fontSize="xs"
              fontWeight="medium"
              textColor="tertiary"
            >
              {primaryCustomerPhone}
            </CopyableText>
          )}
          {[
            ConversationPlatform.INSTAGRAM,
            ConversationPlatform.INSTAGRAM_COMMENTS,
          ].includes(conversation.platform) &&
            primaryCustomerInstagram?.username && (
              <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
                <ExternalLink
                  className={conversationDetailsCustomerCss.username}
                  showIcon={false}
                  url={`https://instagram.com/${primaryCustomerInstagram.username}`}
                >
                  @{primaryCustomerInstagram.username}
                </ExternalLink>
              </Text>
            )}
        </Flex>

        {(orders || returns) && (
          <CustomerTotals orders={orders} returns={returns} />
        )}
        {shopifyUrl && (
          <RedoButton
            hierarchy={RedoButtonHierarchy.SECONDARY}
            IconLeading={ShopifyBagLogo}
            onClick={() => handleViewCustomerOnShopify(shopifyUrl)}
            size={RedoButtonSize.EXTRA_SMALL}
            text="View on Shopify"
          />
        )}
      </div>
    );
  },
);

function shopifyCustomerId(
  conversation: ExpandedConversation,
  orders: Order[],
): string | undefined {
  const customerIdFromConversation = getPrimaryCustomerShopifyCustomerId(
    conversation.customer,
  );
  if (customerIdFromConversation) {
    if (customerIdFromConversation.includes("gid://shopify/Customer/")) {
      return customerIdFromConversation.split("gid://shopify/Customer/")[1];
    }
    return customerIdFromConversation;
  }

  const customerIdFromOrders = unique(
    filterTruthy(orders?.map((order) => order.shopify.customer?.id)),
  );
  return customerIdFromOrders[0];
}

function shopifyCustomerLink(
  team: Team,
  conversation: ExpandedConversation,
  orders: Order[],
): string | undefined {
  const id = shopifyCustomerId(conversation, orders);
  if (!id) {
    return undefined;
  }
  const regexMatch = team.storeUrl?.match(/(.*)\.myshopify.com/);
  const storeName = regexMatch ? regexMatch[1] : undefined;
  if (!storeName) {
    return;
  }
  return `https://admin.shopify.com/store/${storeName}/customers/${id}`;
}
