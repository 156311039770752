import { z } from "zod";
// import { signupFormWithAnalyticsSchema } from "./signupform-schema";

export const getSignupFormById = {
  input: z.object({
    formId: z.string(),
  }),
  output: z.object({
    // form: signupFormWithAnalyticsSchema,
    form: z.any(), // TODO @mlewis850 use signupFormSchema once I have time to fix it, it's getting a type mismatch error rn
    publishedAt: z.date().nullable(),
  }),
};
