import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/base";
import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState } from "@redotech/react-util/load";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Macro } from "@redotech/redo-model/macro";
import { allowSupportAiCopilot, SendType } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "@redotech/redo-web/arbiter-components/buttons/redo-dropdown-button";
import { RedoList } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import ArrowUpRightIcon from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";
import Send01Svg from "@redotech/redo-web/arbiter-icon/send-01.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import LightningIcon from "@redotech/redo-web/icon-old/lightning.svg";
import StarsIcon from "@redotech/redo-web/icon-old/stars-01.svg";
import { Text } from "@redotech/redo-web/text";
import { OverflowTooltip } from "@redotech/redo-web/tooltip/overflow-tooltip";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { assertNever } from "@redotech/util/type";
import * as React from "react";
import { KeyboardEvent, memo, SetStateAction, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { AutocompleteType } from "../support-message-autocomplete";
import {
  QuillOperation,
  WritingAiAssistantMenu,
} from "../writing-ai-assistant-menu";
import { hotkeyTooltip } from "./hotkeys";
import { getSentTypeDisplayText } from "./message-input";
import * as messageInputCss from "./message-input.module.css";

interface MessageInputFooterProps {
  macrosLoad: LoadState<Macro[] | undefined>;
  conversation: ExpandedConversation;
  setMacroModalOpen: (open: boolean) => void;
  handleAiAction: (content: string, operation: QuillOperation) => void;
  deleteLoading: boolean;
  saveDraftLoading: boolean;
  handleDeleteDraft: () => Promise<void>;
  replyPending: boolean;
  replyAndClosePending: boolean;
  conversationClosing: boolean | undefined;
  handleSendAndClose: (
    event: React.ChangeEvent<{ value?: unknown }>,
  ) => Promise<void>;
  handleMessageSend: (
    event: React.ChangeEvent<{ value?: unknown }>,
    alertTitle?: string,
    preventUpdateActiveConversation?: boolean,
    markInProgress?: boolean,
  ) => Promise<boolean>;
  sendMessageDisabled: boolean;
  draftId?: string;
  onHotkeyFunctionReady: (
    hotkeyFunction: (event: KeyboardEvent) => void,
  ) => void;
  disableEditor: boolean;
  editorErrorMessage: { message: string; link?: string } | undefined;
  messageRespondingToId: string;
  shouldShowAiGeneratedResponseBadge: boolean;
}

export const MessageInputFooter = memo(function MessageInputFooter({
  macrosLoad,
  conversation,
  setMacroModalOpen,
  handleAiAction,
  deleteLoading,
  saveDraftLoading,
  handleDeleteDraft,
  replyPending,
  replyAndClosePending,
  conversationClosing,
  handleSendAndClose,
  handleMessageSend,
  sendMessageDisabled,
  draftId,
  onHotkeyFunctionReady,
  disableEditor,
  editorErrorMessage,
  messageRespondingToId,
  shouldShowAiGeneratedResponseBadge,
}: MessageInputFooterProps) {
  const user = useRequiredContext(UserContext);
  const team = useRequiredContext(TeamContext);
  const canUseMacros = permitted(user.permissions, Permission.USE_MACRO);

  const isMac = isUserOnMac();

  const [emailSendButtonRef, setEmailSendButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  const [pickingSendType, setPickingSendType] = useState(false);

  const [sendType, setSendType] = useState<SendType>(
    team.settings.support?.defaultSendAction && conversation.status === "open"
      ? team.settings.support?.defaultSendAction
      : SendType.SEND,
  );

  const handleSetSendType = (state: SetStateAction<SendType>) => {
    setPickingSendType((t) => false);
    setSendType(state);
  };

  async function handleMessageSubmit(
    event: React.ChangeEvent<{ value?: unknown }>,
  ) {
    setPickingSendType(false);
    if (sendType === SendType.CLOSE) {
      await handleSendAndClose(event);
    } else {
      await handleMessageSend(
        event,
        "Message sent",
        false,
        sendType === SendType.IN_PROGRESS,
      );
    }
  }

  const getSendDropdownOptions = () => {
    const items = [{ value: SendType.SEND }, { value: SendType.CLOSE }];
    if (team.settings.support?.useInProgressStatus) {
      items.push({ value: SendType.IN_PROGRESS });
    }
    return items;
  };

  const [editorErrorRef, setEditorErrorRef] = useState<HTMLElement | null>(
    null,
  );

  return (
    <Flex
      align="center"
      className={messageInputCss.wrappingText}
      flex="1 1 0%"
      justify="space-between"
      p="sm"
      pb="lg"
      px="lg"
    >
      <Flex
        align="center"
        className={messageInputCss.wrappingtext}
        flex="1 1 0%"
        gap="xs"
      >
        {!disableEditor && (
          <>
            {allowSupportAiCopilot(team) && (
              <WritingAiAssistantMenu
                conversationId={conversation._id}
                draftId={draftId}
                messageRespondingToId={messageRespondingToId}
                onAiOperationComplete={handleAiAction}
                onHotkeyFunctionReady={onHotkeyFunctionReady}
              />
            )}
            {allowSupportAiCopilot(team) && canUseMacros && (
              <Flex className={messageInputCss.verticalButtonDivider} />
            )}
            {canUseMacros && (
              <Tooltip
                arrow
                title={getHotkeyTooltip(AutocompleteType.MACRO, isMac)}
              >
                {/** Wrap with span for tooltip events to work https://v4.mui.com/components/tooltips/#disabled-elements */}
                <span>
                  <RedoButton
                    disabled={macrosLoad.pending}
                    hierarchy={RedoButtonHierarchy.TERTIARY}
                    IconLeading={LightningIcon}
                    onClick={() => {
                      amplitude.logEvent("view-macroModal", {
                        conversationId: conversation._id,
                        channel: conversation.platform,
                      });
                      setMacroModalOpen(true);
                    }}
                    size={RedoButtonSize.REGULAR}
                  />
                </span>
              </Tooltip>
            )}
          </>
        )}
      </Flex>
      <Flex align="flex-end" overflow="hidden">
        {shouldShowAiGeneratedResponseBadge && (
          <Flex align="center" gap="xs" pb="md">
            <RedoBadge
              color={RedoBadgeColor.PURPLE}
              segmentLeading={{ type: "icon", Icon: () => <StarsIcon /> }}
              size={RedoBadgeSize.SMALL}
              text="AI generated response"
            />
          </Flex>
        )}
        <Flex align="center" overflow="hidden">
          {editorErrorMessage && (
            <>
              {editorErrorMessage.link ? (
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconTrailing={ArrowUpRightIcon}
                  onClick={() =>
                    editorErrorMessage.link &&
                    window.open(editorErrorMessage.link, "_blank")
                  }
                  ref={setEditorErrorRef}
                  text={editorErrorMessage.message}
                  theme={RedoButtonTheme.DESTRUCTIVE}
                />
              ) : (
                <OverflowTooltip
                  overflowRef={editorErrorRef}
                  tooltipProps={{ title: editorErrorMessage.message }}
                  wrapperClass={messageInputCss.editorErrorTooltipWrapper}
                >
                  <Text
                    overflow="hidden"
                    ref={setEditorErrorRef}
                    textColor="error"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {editorErrorMessage.message}
                  </Text>
                </OverflowTooltip>
              )}
            </>
          )}
          {!disableEditor && (
            <DeleteDraftButton
              deleteLoading={deleteLoading}
              handleDeleteDraft={handleDeleteDraft}
              saveDraftLoading={saveDraftLoading}
            />
          )}
          {!disableEditor && (
            <ClickAwayListener onClickAway={() => setPickingSendType(false)}>
              <span>
                <Tooltip arrow title={getHotkeyTooltip(sendType, isMac)}>
                  <span>
                    <RedoButtonDropdown
                      disabled={sendMessageDisabled}
                      dropdownOpen={pickingSendType}
                      IconLeading={
                        replyPending ||
                        replyAndClosePending ||
                        conversationClosing
                          ? CircleSpinner
                          : Send01Svg
                      }
                      onClick={handleMessageSubmit}
                      ref={setEmailSendButtonRef}
                      setDropdownOpen={() => {
                        setPickingSendType(
                          (pickingEmailSendType) => !pickingEmailSendType,
                        );
                      }}
                      size={RedoButtonSize.REGULAR}
                      text={
                        replyPending || replyAndClosePending
                          ? "Sending..."
                          : conversationClosing
                            ? "Closing..."
                            : getSentTypeDisplayText(sendType)
                      }
                    >
                      <Dropdown
                        anchor={emailSendButtonRef}
                        fitToAnchor={false}
                        open={pickingSendType}
                      >
                        <RedoList
                          focusedIndex={focusedIndex}
                          items={getSendDropdownOptions()}
                          itemSelected={(item: { value: SendType }) => {
                            handleSetSendType(item.value);
                          }}
                          refToListenTo={null}
                          setFocusedIndex={setFocusedIndex}
                          size={RedoListItemSize.SMALL}
                        >
                          {(option) => {
                            return (
                              <Flex
                                align="center"
                                gap="md"
                                justify="space-between"
                                w="full"
                              >
                                <Text>
                                  {getSentTypeDisplayText(option.value)}
                                </Text>
                                <RedoBadge
                                  color={RedoBadgeColor.GRAY}
                                  text={getHotkeyTooltip(
                                    option.value,
                                    isMac,
                                    true,
                                  )}
                                />
                              </Flex>
                            );
                          }}
                        </RedoList>
                      </Dropdown>
                    </RedoButtonDropdown>
                  </span>
                </Tooltip>
              </span>
            </ClickAwayListener>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
});

const DeleteDraftButton = memo(function DeleteDraftButton({
  deleteLoading,
  saveDraftLoading,
  handleDeleteDraft,
}: {
  deleteLoading: boolean;
  saveDraftLoading: boolean;
  handleDeleteDraft: () => Promise<void>;
}) {
  return (
    <Tooltip arrow title="Discard draft">
      <span>
        <RedoButton
          disabled={deleteLoading || saveDraftLoading}
          hierarchy={RedoButtonHierarchy.TERTIARY}
          IconLeading={deleteLoading ? CircleSpinner : TrashIcon}
          onClick={() => {
            void handleDeleteDraft();
          }}
          size={RedoButtonSize.REGULAR}
        />
      </span>
    </Tooltip>
  );
});

export function getHotkeyTooltip(
  type: AutocompleteType | SendType,
  isMac: boolean,
  trimmed?: boolean,
): string {
  switch (type) {
    case AutocompleteType.DISCOUNT_CODE:
      return hotkeyTooltip({
        action: "Insert",
        code: "5",
        name: "discount code",
        isMac,
        trimmed,
      });
    case AutocompleteType.MACRO:
      return hotkeyTooltip({
        action: "Insert",
        code: "6",
        name: "template",
        isMac,
        trimmed,
      });
    case AutocompleteType.PRODUCT:
      return hotkeyTooltip({
        action: "Insert",
        code: "7",
        name: "product",
        isMac,
        trimmed,
      });
    case AutocompleteType.MENTION:
      return "Insert mention (@)";
    case SendType.SEND:
      return hotkeyTooltip({
        action: undefined,
        code: "Enter",
        name: "Send reply",
        isMac,
        trimmed,
      });
    case SendType.CLOSE:
      return hotkeyTooltip({
        action: undefined,
        code: "⇧+Enter",
        name: "Send reply and close",
        isMac,
        trimmed,
      });
    case SendType.IN_PROGRESS:
      return hotkeyTooltip({
        action: undefined,
        code: `${isMac ? "⌥" : "Alt"}+⇧+Enter`,
        name: "Send reply and mark in progress",
        isMac,
        trimmed,
        useModifierKey: false,
      });
    default:
      return assertNever(type);
  }
}
