import { faqArticleBodySchema } from "@redotech/redo-model/faq/faq-body-schema";
import { faqElementSchema } from "@redotech/redo-model/faq/faq-db-parser";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const createArticle = {
  input: z.object({
    elements: z.array(faqElementSchema),
    title: z.string(),
    faqCollection: z.string(),
    published: z.boolean(),
  }),
  output: zExt.coercedResult(
    z.object({
      // oldArticle is only here so this output can match the updateArticle output
      oldArticle: z.optional(faqArticleBodySchema),
      newArticle: faqArticleBodySchema,
    }),
  ),
};
