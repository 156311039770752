import { z } from "zod";
// import { signupFormWithAnalyticsSchema } from "./signupform-schema";

export const getAdvancedFlowById = {
  input: z.object({
    flowId: z.string(),
  }),
  output: z.object({
    advancedFlow: z.any(), // TODO @mlewis850 fill this out with actual types
  }),
};
