// extracted by mini-css-extract-plugin
export var active = "_1e-d";
export var blink = "_1e-e";
export var container = "_1e-5";
export var controls = "_1e-0";
export var controlsRight = "_1e-1";
export var fakeCursor = "_1e-c";
export var hidden = "_1e-b";
export var quillContainerSmall = "_1e-7";
export var quillEditor = "_1e-6";
export var quillEditorSmall = "_1e-8";
export var quillFormatButtons = "_1e-a";
export var quillToolbar = "_1e-9";
export var removePadding = "_1e-2";
export var state = "_1e-3";
export var stateTitle = "_1e-4";