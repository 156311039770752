import { smsTemplateSchema } from "@redotech/redo-model/sms-template";
import { z } from "zod";

export const getSmsTemplateById = {
  input: z.object({
    id: z.string(),
  }),
  output: z.object({
    template: smsTemplateSchema,
  }),
};
