// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_81-f";
export var baseCustomerActivityCardArrowRightContainer = "_81-e";
export var baseCustomerActivityCardContainer = "_81-c";
export var baseCustomerActivityCardContainerHovered = "_81-d";
export var conversationActivityDataConversationIconContainer = "_81-5";
export var customerActivityDataClaimIcon = "_81-8";
export var customerActivityDataClaimIconContainer = "_81-7";
export var customerActivityDataConversationIcon = "_81-6";
export var customerActivityDataConversationIconContainer = "_81-4";
export var customerActivityDataOrderIcon = "_81-1";
export var customerActivityDataOrderIconContainer = "_81-0";
export var customerActivityDataReturnIcon = "_81-3";
export var customerActivityDataReturnIconContainer = "_81-2";
export var customerActivityShoppingIconContainer = "_81-9";
export var messageBubble = "_81-a";
export var messageBubbleText = "_81-b";