import { z } from "zod";

export const getRepairInvoiceDetails = {
  input: z.object({
    returnId: z.string(),
  }),
  output: z.object({
    name: z.string(),
    total: z.number(),
    paid: z.boolean(),
  }),
};
