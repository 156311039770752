import { boolean, object, string, infer as zInfer } from "zod";
import { zExt } from "../common/zod-util";

// https://docs.easypost.com/docs/endshippers#create-an-endshipper
export const endShipperZodSchema = object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  endShipperId: string(),
  name: string(),
  company: string(),
  street1: string(),
  street2: string().optional(),
  city: string(),
  state: string(),
  zip: string(),
  country: string(),
  email: string(),
  phone: string(),
  active: boolean(),
});

export type IEndShipper = zInfer<typeof endShipperZodSchema>;

export const createEndShipperZodSchema = endShipperZodSchema.omit({
  _id: true,
  endShipperId: true,
  team: true,
});

export type ICreateEndShipper = zInfer<typeof createEndShipperZodSchema>;

export const createMongoEndShipperZodSchema = endShipperZodSchema.omit({
  _id: true,
});

export type ICreateMongoEndShipper = zInfer<
  typeof createMongoEndShipperZodSchema
>;
