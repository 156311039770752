import { DiscountValueType } from "../order-discount";

export enum UpsellPageLayoutType {
  ONE_PRODUCT_UPSELL = "one-product-upsell",
  TWO_PRODUCT_UPSELL = "two-product-upsell",
  THREE_PRODUCT_UPSELL = "three-product-upsell",
  TWO_PRODUCT_UPSELL_VERTICAL = "two-product-upsell-vertical",
  FOUR_PRODUCT_UPSELL_VERTICAL = "four-product-upsell-vertical",
}

export const StaticLayoutType = [
  UpsellPageLayoutType.ONE_PRODUCT_UPSELL,
  UpsellPageLayoutType.TWO_PRODUCT_UPSELL,
  UpsellPageLayoutType.THREE_PRODUCT_UPSELL,
  UpsellPageLayoutType.TWO_PRODUCT_UPSELL_VERTICAL,
  UpsellPageLayoutType.FOUR_PRODUCT_UPSELL_VERTICAL,
] as const;
export type StaticLayoutType = (typeof StaticLayoutType)[number];

export enum TextSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
}

export enum TextAppearance {
  CRITICAL = "critical",
  WARNING = "warning",
  SUCCESS = "success",
}

export enum BannerStatus {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  CRITICAL = "critical",
}

export enum UpsellPageElementType {
  BANNER = "banner",
  TIMER = "timer",
  UPSELL_PRODUCT = "upsell-product",
}

export enum UpsellProductType {
  MANUAL_SELECTION = "manual-selection",
  COLLECTION = "collection",
}

export type UpsellPageElement<T extends UpsellPageElementType> = {
  type: T;
};

export enum UpsellPageProductSource {
  MANUAL = "Manual selection",
  COLLECTION = "Collection",
  SHOPIFY_RECOMMENDATION = "Shopify recommendation",
}

export interface BannerElement
  extends UpsellPageElement<UpsellPageElementType.BANNER> {
  showBanner: boolean;
  title: string;
  titleSize: TextSize;
  supportingText: string;
  supportingTextSize: TextSize;
  backgroundColor: string;
}

export interface TimerElement
  extends UpsellPageElement<UpsellPageElementType.TIMER> {
  showTimer: boolean;
  backgroundColor: string;
  totalTime: string;
  timerBannerStatus: BannerStatus;
  timerTextSize: TextSize;
  timerTextColor: TextAppearance;
  supportingTextSize: TextSize;
  supportingText: string;
}

export interface UpsellProductElement
  extends UpsellPageElement<UpsellPageElementType.UPSELL_PRODUCT> {
  id?: string;
  upsellProductSource?: UpsellPageProductSource;
  collectionId?: string;
  productId?: string;
  variantIds?: string[];
  discountAmount?: string;
  discountValueType?: DiscountValueType;
  showDescription?: boolean;
}
